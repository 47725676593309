import { WidgetIssueListDatum } from "@/types/dashboardTypes";
import { formatPercent } from "@/utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Space, Tooltip, Typography } from "antd";
import React from "react";

export const IssueList: React.FC<{
  issueList: WidgetIssueListDatum[];
  numIssuesToShow?: number;
  isSortedByImpact?: boolean;
}> = ({ issueList, numIssuesToShow, isSortedByImpact = false }) => {
  let issuesToShow;
  if (numIssuesToShow) {
    issuesToShow = issueList.slice(0, numIssuesToShow);
  } else {
    issuesToShow = issueList;
  }

  return (
    <Space direction="vertical" className="issue-list">
      {issuesToShow.map((issue, i) => (
        <div key={`issue-item-${i}`} className={`issue-list-item ${issue.sentiment.toLowerCase()}`}>
          <Typography.Text strong className="issue-list-item-title selectable">
            {issue.parentIssueTitle.trim()}&nbsp;-&nbsp;{issue.issueTitle.trim()}
          </Typography.Text>
          <span
            className={`issue-list-item-change ${(() => {
              const isPositiveChange = issue.percentChange > 0;
              const isNegativeSentiment = issue.sentiment === "negative";

              if (isPositiveChange) {
                if (isNegativeSentiment) {
                  return "text-red";
                }
                return "text-green";
              }

              if (isNegativeSentiment) {
                return "text-green";
              }
              return "text-red";
            })()}`}
            style={{ marginLeft: isSortedByImpact ? "15px" : "25px" }}
          >
            {issue.percentChange > 0 ? "+" : ""}
            {formatPercent(issue.percentChange, 2)}
          </span>
          {isSortedByImpact && (
            <Tooltip
              title={
                "Percent change is an impact calculation that factors in issue volume in addition to the metric displayed"
              }
            >
              <InfoCircleOutlined
                style={{
                  float: "right",
                  verticalAlign: "center",
                  marginLeft: "5px",
                }}
              />
            </Tooltip>
          )}
        </div>
      ))}
    </Space>
  );
};
