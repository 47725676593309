import { CopyOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AmplifyProvider, Authenticator, Theme, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Alert, Button, Divider, Layout, Menu, Space, Spin, Typography } from "antd";
import { Amplify, Auth } from "aws-amplify";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.less";
import oktaLogo from "./Okta_Aura_Black_S.png";
import awsconfig from "./aws-exports";
import "./body.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { BaseFooter, Footer } from "./components/Footer";
import { BaseHeader, CustomerImage } from "./components/Header";
import { OktaAuthModal } from "./components/OktaAuthModal";
import { SpiralMenu } from "./components/SpiralMenu";
import {
  CustomerUIModel,
  customerContext,
  isAdmin,
  useCurrentUser,
  useCustomer,
  useCustomerGroups,
} from "./hooks";
import { ReportAPIResp } from "./indexTypes";
import logo from "./logo_blue.svg";
import { ChooseCustomer } from "./pages/ChooseCustomer";
import { Dashboard } from "./pages/Dashboard";
import EarlyWarningSystemPage from "./pages/EarlyWarningSystemPage";
import ExplorePage from "./pages/ExplorePage";
import { MainPage } from "./pages/MainPage";
import { MetricsView } from "./pages/MetricsView";
import { ReportingPage } from "./pages/ReportingPage";
import { UnauthorizedUser } from "./pages/UnauthorizedUser";
import { ClusterFilterPage } from "./pages/admin/ClusterFilterPage";
import { ConversationDebugPage } from "./pages/admin/ConversationDebugPage";
import { CustomerManagerPage } from "./pages/admin/CustomerManagerPage";
import { DatasetPage } from "./pages/admin/DatasetPage";
import { DatasetsPage } from "./pages/admin/DatasetsPage";
import { DemoManagerPage } from "./pages/admin/DemoManagerPage";
import { UserPage } from "./pages/admin/UserPage";
import { UsersPage } from "./pages/admin/UsersPage";
import { WeeklyReportsPage } from "./pages/admin/WeeklyReportsPage";
import { getReports } from "./reportApi";
import { store } from "./store";
import { truncate } from "./utils";
import {AgentChat} from "./pages/AgentChat";

moment.tz.setDefault("America/Los_Angeles");
moment.locale("en-us", {
  week: {
    dow: 1,
  },
});

Amplify.configure(awsconfig);

const clarity =
  // eslint-disable-next-line @typescript-eslint/ban-types
  process.env.NODE_ENV != "test" && "clarity" in window ? (window.clarity as Function) : () => {};

ReactGA.initialize("G-BBN2SJRR71", {
  testMode: process.env.NODE_ENV !== "production" && process.env.DEBUG_GA !== "true",
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

if (process.env.NODE_ENV !== "production" && process.env.DEBUG_CLARITY !== "true") {
  console.log("Debug mode - disabling Clairty");
  clarity("stop");
}

const AppLayout = ({
  reports,
  children,
}: {
  reports?: ReportAPIResp;
  children?: React.ReactNode;
}) => {
  const { customer, unsetCustomer } = useCustomer();
  const user = useCurrentUser();
  const [jwt, setJwt] = useState<string>();

  useEffect(() => {
    const f = async () => {
      setJwt((await Auth.currentSession()).getAccessToken().getJwtToken());
    };
    if (process.env.NODE_ENV !== "production") {
      f();
    }
  }, []);

  return (
    <Layout
      style={{
        height: "100%",
      }}
    >
      {/* <Header /> */}
      <Layout.Sider theme="light">
        <a href="/">
          <Space direction="vertical" className="logowrapper">
            <img src={logo} alt="" height="80px" id="logo" />
            <Divider className="logodivider" />
            <CustomerImage customer={customer} />
          </Space>
        </a>
        <ErrorBoundary>
          <SpiralMenu
            customer={customer}
            unsetCustomer={unsetCustomer}
            admin={isAdmin(user)}
            reports={reports}
          />
        </ErrorBoundary>
        <Menu key="logout-menu" id="logout" mode="vertical">
          <Menu.SubMenu
            key="logout-submenu"
            title={
              <span>
                <UserOutlined />
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                {truncate(user ? (user as any).attributes.email : "Spiral User", 20)}
              </span>
            }
            popupOffset={[0, process.env.NODE_ENV !== "production" ? -50 : 0]}
          >
            <Menu.Item key="logout-item">
              <Button type="link" icon={<LogoutOutlined />} onClick={() => Auth.signOut()} danger>
                Log out
              </Button>
            </Menu.Item>
            {process.env.NODE_ENV !== "production" && (
              <Menu.Item key="logout-jwt">
                <Button
                  type="link"
                  icon={<CopyOutlined />}
                  onClick={() => jwt && navigator.clipboard.writeText(jwt)}
                >
                  Copy JWT
                </Button>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        </Menu>
      </Layout.Sider>
      <Layout style={{ marginLeft: 227 }}>
        <Layout.Content
          style={{
            minHeight: "90vh",
          }}
        >
          {children}
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

const RequireAdmin = () => {
  const user = useCurrentUser();
  if (!isAdmin(user)) {
    return <div>Unauthorized</div>;
  }
  return <Outlet />;
};

const ProvideCustomer = ({
  children,
  value,
}: {
  value: { customer: CustomerUIModel; customers: CustomerUIModel[]; unsetCustomer: () => void };
  children?: React.ReactNode;
}) => <customerContext.Provider value={value}>{children}</customerContext.Provider>;

const AppWithRoutes = ({
  customer,
  customers,
  unsetCustomer,
}: {
  customer: CustomerUIModel;
  customers: CustomerUIModel[];
  unsetCustomer: () => void;
}) => {
  const location = useLocation();
  const [reports, setReports] = useState<ReportAPIResp>();

  // TODO: Remove this once all customers are on v2
  const landingPath = customer.index.v2Enabled ? "views" : "dashboards";

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  useEffect(() => {
    const getReportsAsync = async () => {
      const resp = await getReports(customer.id);
      setReports(resp);
    };
    getReportsAsync();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProvideCustomer value={{ customer, customers, unsetCustomer }}>
      <AppLayout reports={reports}>
        <Routes>
          <Route path="/admin" element={<RequireAdmin />}>
            <Route path="demos" element={<DemoManagerPage customers={customers} />} />
            <Route path="titles">
              <Route
                index
                element={<Navigate to={`${customer.index.defaultReportSet}`} replace />}
              />
            </Route>
            <Route path="customers">
              <Route index element={<CustomerManagerPage unsetCustomer={unsetCustomer} />} />
              <Route
                path=":customerId/customer"
                element={<CustomerManagerPage unsetCustomer={unsetCustomer} />}
              />
              <Route
                path=":customerId/reports"
                element={
                  <CustomerManagerPage tabDefault={"report-editor"} unsetCustomer={unsetCustomer} />
                }
              />
              <Route
                path=":customerId/titles"
                element={
                  <CustomerManagerPage tabDefault={"titles-editor"} unsetCustomer={unsetCustomer} />
                }
              />
              <Route
                path=":customerId/clusters"
                element={
                  <CustomerManagerPage
                    tabDefault={"clusters-editor"}
                    unsetCustomer={unsetCustomer}
                  />
                }
              />
              <Route
                path=":customerId/conversation-queue"
                element={
                  <CustomerManagerPage
                    tabDefault={"conversation-queue"}
                    unsetCustomer={unsetCustomer}
                  />
                }
              />
              <Route
                path=":customerId/cluster-proposal"
                element={
                  <CustomerManagerPage
                    tabDefault={"cluster-proposal-editor"}
                    unsetCustomer={unsetCustomer}
                  />
                }
              />
              <Route
                path=":customerId/cluster-training-data-search"
                element={
                  <CustomerManagerPage
                    tabDefault={"cluster-training-data-search"}
                    unsetCustomer={unsetCustomer}
                  />
                }
              />
              <Route
                path=":customerId/cluster-training-data-explorer"
                element={
                  <CustomerManagerPage
                    tabDefault={"cluster-training-data-explorer"}
                    unsetCustomer={unsetCustomer}
                  />
                }
              />
              <Route
                path=":customerId/integrations"
                element={
                  <CustomerManagerPage tabDefault={"integrations"} unsetCustomer={unsetCustomer} />
                }
              />
            </Route>
            <Route path="users">
              <Route index element={<UsersPage />} />
              <Route path=":userId" element={<UserPage />} />
            </Route>
            <Route path="datasets">
              <Route index element={<DatasetsPage />} />
              <Route path=":datasetUuid" element={<DatasetPage />} />
            </Route>
            <Route path="cluster-filterer" element={<ClusterFilterPage />} />
            <Route path="conversations">
              <Route path=":conversationId" element={<ConversationDebugPage />} />
            </Route>
            <Route path="weekly-reports" element={<WeeklyReportsPage />}>
              <Route path=":endDate" element={<WeeklyReportsPage />} />
            </Route>
          </Route>
          <Route path={landingPath}>
            <Route
              index
              element={
                <Navigate to={`/${landingPath}/${customer.index.defaultReportSet}`} replace />
              }
            />
            {customer.index.v2Enabled ? (
              <Route path=":viewId" element={<Dashboard />} />
            ) : (
              <Route
                path=":reportId"
                element={
                  <MainPage reports={reports} defaultReportSet={customer.index.defaultReportSet} />
                }
              />
            )}
          </Route>
          <Route path="metrics/:metricsViewId" element={<MetricsView />} />
          <Route path="agent-chat" element={<AgentChat reportSetHierarchy={customer.index.reportSetHierarchy}/>} />
          <Route path="explore" element={<ExplorePage />} />
          <Route path="early-warning-system" element={<EarlyWarningSystemPage />} />
          <Route path="reporting/:reportId" element={<ReportingPage reports={reports} />} />
          <Route key="index" path="*" element={<Navigate to={`/${landingPath}`} replace />} />
        </Routes>
      </AppLayout>
    </ProvideCustomer>
  );
};

const theme: Theme = {
  name: "my-theme",
  tokens: {
    components: {
      button: {
        primary: {
          borderColor: "#89949f",
          color: "#0d1a26",
        },
      }
    },
    colors: {
      background: {
        primary: { value: "#FBFBFB" },
      },
      brand: {
        primary: {
          "10": { value: "#465FC31A" },
          "80": { value: "#465FC3" },
          "90": { value: "#465FC3CC" },
          "100": { value: "#465FC3" },
        },
      },
      font: {
        primary: {
          value: "#363F4A",
        },
      },
    },
    shadows: {
      medium: {
        value: {
          offsetX: "0",
          offsetY: "0",
          blurRadius: "29px",
          color: "rgba(12, 15, 50, 0.09)",
        },
      },
    },
  },
};

export const App = () => {
  const currentUser = useCurrentUser();

  if (currentUser) {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
    const email = (currentUser as any).attributes.email;
    ReactGA.set({
      userId: email,
      dimension1: email.substring(email.lastIndexOf("@") + 1), // domain dimension
    });

    clarity("identify", email, undefined, undefined, email);
    clarity("set", "Customer Email Domain", email.substring(email.lastIndexOf("@") + 1));
  }
  const { customer, customers, setCustomer, unsetCustomer, isLoading } = useCustomerGroups();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const socialAuthFooter = (
    <div>
      <Divider style={{ color: "grey" }}>OR</Divider>
      <div className="social-auth-wrapper">
        <Button
          className="auth-button"
          onClick={showModal}
          disabled={showAlert}
          type="default"
          size="large"
          block
        >
          <img
            src={oktaLogo}
            height="25px"
            width="25px"
            className={`idp-logo-${showAlert ? "disabled" : ""}`}
          />
          Sign in with Okta
        </Button>
      </div>
      {showAlert && (
        <Alert
          message="This feature is not configured for your account. Please reach out to support@spiralup.co for more information."
          type="info"
          className="rounded"
        />
      )}
    </div>
  );

  return (
    <Provider store={store}>
      <AmplifyProvider theme={theme}>
        {currentUser ? null : <BaseHeader />}
        <Router>
          <OktaAuthModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            setShowAlert={setShowAlert}
          />
          <Authenticator
            components={{
              ConfirmResetPassword: {
                Header() {
                  return (
                    <>
                      <Authenticator.ResetPassword.Header />
                      <Typography.Text>A code has been sent to your email.</Typography.Text>
                    </>
                  );
                },
              },
              SignIn: {
                Footer() {
                  const { toResetPassword } = useAuthenticator();
                  return (
                    <div style={{ paddingBottom: "16px" }}>
                      <Button onClick={toResetPassword} size="small" type="link" block>
                        Forgot your password?
                      </Button>
                      {socialAuthFooter}
                    </div>
                  );
                },
              },
              SignUp: {
                Footer() {
                  return socialAuthFooter;
                },
              },
            }}
          >
            <Spin spinning={isLoading}>
              {!isLoading && customers.length === 0 && (
                <UnauthorizedUser currentUser={currentUser} />
              )}
              {!isLoading && !customer && customers.length > 1 && (
                <ChooseCustomer
                  currentUser={currentUser}
                  customers={customers}
                  setCustomer={setCustomer}
                  isLoading={isLoading}
                />
              )}
              {(customer || customers.length === 1) && (
                <AppWithRoutes
                  customer={customer ? customer : customers[0]}
                  customers={customers}
                  unsetCustomer={unsetCustomer}
                />
              )}
            </Spin>
          </Authenticator>
          {currentUser ? null : <BaseFooter />}
        </Router>
      </AmplifyProvider>
    </Provider>
  );
};
