import { Spin } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import {
  initMetricsViewThunk,
  MetricsViewPageSettings,
  selectWidgetsData,
  updateMetricsViewPageSettingsThunk,
} from "../../features/metricsView/metricsViewSlice";
import { useAppDispatch, useAppSelector, useCustomer } from "../../hooks";
import { MultiFilter } from "../../components/MultiFilter";
import { ParsedExpression } from "../../types/expressionsDslTypes";
import { Sentiment } from "../../indexTypes";

import { DateRangeSelector } from "@/components/DateRangeSelector";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import "./MetricsView.less";
import { WidgetCard } from "./components/WidgetCard";

import "./MetricsView.less";

const MetricsViewSettingsControls: React.FC<{
  pageSettings: MetricsViewPageSettings | undefined;
  onDateChange: (dates: [Moment, Moment]) => void;
  onFilterChange: (formattedFilters?: ParsedExpression, selectedSentiment?: Sentiment[]) => void;
  filterFieldNames?: string[];
}> = ({ pageSettings, onDateChange, onFilterChange, filterFieldNames }) => {
  if (!pageSettings) return null;

  return (
    <div className="metrics-view-controls">
      <DateRangeSelector
        title="Date Range"
        startDate={moment(pageSettings.startDate)}
        endDate={moment(pageSettings.endDate)}
        onDateChange={onDateChange}
      />
      {filterFieldNames && filterFieldNames.length > 0 && (
        <div style={{ marginTop: "12px" }}>
          <MultiFilter viewFilters={filterFieldNames} onFilterChange={onFilterChange} />
        </div>
      )}
    </div>
  );
};

const InnerMetricsView: React.FC<{ metricsViewId: string }> = ({ metricsViewId }) => {
  const dispatch = useAppDispatch();
  const { customer } = useCustomer();

  useEffect(() => {
    dispatch(initMetricsViewThunk({ metricsViewId, customerId: customer.id }));
  }, [customer.id, dispatch, metricsViewId]);

  const handleDateChange = ([start, end]: [Moment, Moment]) => {
    dispatch(
      updateMetricsViewPageSettingsThunk({
        newSettings: {
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD"),
        },
      })
    );
  };

  const handleFilterChange = (
    formattedFilters?: ParsedExpression,
    selectedSentiment?: Sentiment[]
  ) => {
    dispatch(
      updateMetricsViewPageSettingsThunk({
        newSettings: {
          filters: formattedFilters,
          taxonomyNodeFilters: { sentiment: selectedSentiment || [] },
        },
      })
    );
  };

  const { metricsViewState } = useAppSelector(state => state.metricsView);
  const widgets = useSelector(selectWidgetsData);
  if (!metricsViewState.data) return null;
  const { data } = metricsViewState;

  return (
    <Spin style={{ width: "100%", height: "500px" }} spinning={metricsViewState.loading}>
      <MetricsViewSettingsControls
        pageSettings={data.settings}
        onDateChange={handleDateChange}
        onFilterChange={handleFilterChange}
        filterFieldNames={data.metricsView.settings.filterFieldNames}
      />
      <div className="widget-card-container">
        {widgets.map((widget, i) => (
          <WidgetCard
            key={`widget_${i}`}
            metricsViewId={metricsViewId}
            widgetSettings={widget}
            startDate={data.settings.startDate}
            endDate={data.settings.endDate}
            filters={data.settings.filters}
          />
        ))}
      </div>
    </Spin>
  );
};

export const MetricsView: React.FC = () => {
  const { metricsViewId } = useParams<{ metricsViewId: string }>();
  if (!metricsViewId) {
    console.error(
      "In metrics view page but no metrics view ID provided, this should not be possible"
    );
    return <div>Something went wrong, try refreshing the page or contact support</div>;
  }
  return (
    <div id="metrics-view">
      <InnerMetricsView metricsViewId={metricsViewId} />
    </div>
  );
};
