import {
  AgentState,
  createAgentChat,
  getAgentChat,
  Message,
  sendAgentChatMessage,
  UserMessage,
} from "@/agentChatApi";
import { RootState } from "@/store";
import { ViewId } from "@/types/dashboardTypes";
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ChatStatus {
  ERROR = "error",
  READY = "ready",
  SENDING = "sending",
  WAITING_FOR_AGENT = "waiting",
}

interface ChatState {
  id: string;
  viewId: ViewId | null;
  latestAgentState: AgentState | null;
  pendingUserMessage: string | null;
  optimisticUserMessages: UserMessage[];
  error: string | null;
  deepResearch: boolean;
}

const initialState: ChatState = {
  id: "",
  viewId: null,
  latestAgentState: null,
  pendingUserMessage: null,
  optimisticUserMessages: [],
  error: null,
  deepResearch: false,
};

const POLL_INTERVAL = 1000;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function waitAndPoll(customerId: string, chatId: string): Promise<AgentState> {
  await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL));
  const response = await getAgentChat(customerId, chatId);
  if (!response || !Array.isArray(response.messages)) {
    throw new Error("Invalid response from server");
  }
  return response;
}

/**
 * A robust polling helper that retries on transient errors
 * @param customerId - The customer ID
 * @param chatId - The chat ID
 * @param dispatch - Optional Redux dispatch function to update error state
 * @returns Promise resolving to AgentState
 */
async function robustWaitAndPoll(
  customerId: string, 
  chatId: string, 
): Promise<AgentState> {
  let lastError: Error | null = null;
  
  for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
    try {
      return await waitAndPoll(customerId, chatId);
    } catch (error) {
      lastError = error as Error;
      
      // If this is not the last attempt, handle as a transient error
      if (attempt < MAX_RETRIES - 1) {
        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      }
    }
  }
  
  // If we've exhausted all retries, throw the last error
  throw lastError;
}

async function pollUntilMessageAcknowledged(
  customerId: string,
  chatId: string,
  userMessage: string,
  dispatch?: any,
  getState?: () => any
): Promise<AgentState> {
  try {
    let response = await robustWaitAndPoll(customerId, chatId);
    const messages = response.messages;
    const lastUserMessage = [...messages].reverse().find(m => m.role === "user");

    while (
      !lastUserMessage ||
      !(lastUserMessage.role === "user" && lastUserMessage.content === userMessage)
    ) {
      try {
        // Check if we're still polling for the current chat (if getState is provided)
        if (getState) {
          const currentState = getState() as RootState;
          const currentChatId = currentState.agentChat.id;
          
          // If the chat IDs don't match, this polling operation is for an old conversation
          if (currentChatId !== chatId) {
            console.log(`Abandoning acknowledgment polling for old chat ${chatId}`);
            return response; // Return the last response and exit polling
          }
        }
        
        response = await robustWaitAndPoll(customerId, chatId);
      } catch (error) {
        // Check if we should continue polling for this chat
        if (getState) {
          const currentState = getState() as RootState;
          if (currentState.agentChat.id !== chatId) {
            return response; // Exit polling if this is for an old chat
          }
        }
        
        // Log the error but continue polling
        if (dispatch) {
          dispatch(agentChatSlice.actions.setError(`Error while polling: ${(error as Error).message}`));
        }
        // Add a small delay before retrying to avoid overwhelming the server
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        continue;
      }
    }

    // Final check to ensure we're still on the same chat
    if (getState) {
      const finalState = getState() as RootState;
      if (finalState.agentChat.id !== chatId) {
        return response; // Don't process acknowledgment for old chats
      }
    }
    
    return response;
  } catch (error) {
    // Only dispatch errors for the current chat
    if (dispatch && (!getState || (getState && getState().agentChat.id === chatId))) {
      dispatch(agentChatSlice.actions.setError(`Failed to poll for message acknowledgement: ${(error as Error).message}`));
    }
    throw error;
  }
}

const pollForAgentResponse = createAsyncThunk(
  "agentChat/pollForAgentResponse",
  async ({ customerId, chatId }: { customerId: string; chatId: string }, { dispatch, getState }) => {
    try {
      let response = await robustWaitAndPoll(customerId, chatId);
      let length;
      const getLastMessage = () => response.messages[response.messages.length - 1];

      while (getLastMessage().role !== "agent") {
        try {
          // Check if the chat ID in state still matches the one we're polling for
          const currentState = getState() as RootState;
          const currentChatId = currentState.agentChat.id;
          
          // If the chat IDs don't match, this polling operation is for an old conversation
          // that has been replaced with a new one, so we should stop polling
          if (currentChatId !== chatId) {
            console.log(`Abandoning polling for old chat ${chatId} as current chat is now ${currentChatId}`);
            return response; // Return the last response and exit polling
          }
          
          length = response.messages.length;
          response = await robustWaitAndPoll(customerId, chatId);

          if (response.messages.length > length) {
            // Verify again that we're still working with the current chat
            const stateAfterPoll = getState() as RootState;
            if (stateAfterPoll.agentChat.id === chatId) {
              dispatch(agentChatSlice.actions.updateAgentState(response));
            } else {
              console.log(`Not updating state for old chat ${chatId}`);
              return response; // Don't update state for old chats
            }
          }
        } catch (error) {
          // Check if the chat is still current before continuing with errors
          const currentState = getState() as RootState;
          if (currentState.agentChat.id !== chatId) {
            return response; // Exit polling if this is for an old chat
          }
          
          // Log the error but continue polling for current chat
          dispatch(agentChatSlice.actions.setError(`Error while polling: ${(error as Error).message}`));
          // Add a short delay to avoid overwhelming the server
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          continue;
        }
      }

      // Verify one last time that this chat is still the active one
      const finalState = getState() as RootState;
      if (finalState.agentChat.id !== chatId) {
        return response; // Don't update state for old chats
      }
      
      // Clear any error state since we've successfully completed polling
      dispatch(agentChatSlice.actions.setError(null));
      dispatch(agentChatSlice.actions.updateAgentState(response));
      return response;
    } catch (error) {
      // Verify this is still the current chat before setting errors
      const errorState = getState() as RootState;
      if (errorState.agentChat.id === chatId) {
        // Handle the case where all retries have been exhausted
        dispatch(agentChatSlice.actions.setError(`Failed to poll for agent response: ${(error as Error).message}`));
      }
      throw error;
    }
  }
);

interface InitParams {
  customerId: string;
  viewId: ViewId;
  initialMessage: string;
}

interface SendMessageParams {
  customerId: string;
  message: string;
}

export const initializeChat = createAsyncThunk(
  "agentChat/initialize",
  async ({ customerId, viewId, initialMessage }: InitParams, { dispatch, getState }) => {
    // Use the deep research value from Redux state
    const state = getState() as RootState;
    const deepResearchFromState = state.agentChat.deepResearch;
    
    // First, optimistically update the UI with the user's message
    dispatch(agentChatSlice.actions.setChatViewId(viewId));
    // Add the initial optimistic message
    dispatch(agentChatSlice.actions.addOptimisticUserMessage(initialMessage));
    
    try {
      const response = await createAgentChat(customerId, viewId, initialMessage, deepResearchFromState);

      // Store the new chat ID to verify it doesn't change during polling
      const newChatId = response.id;
      
      dispatch(agentChatSlice.actions.setId(newChatId));
      dispatch(agentChatSlice.actions.updateInitialState(response));
      // Clear optimistic message as the server has the real data now
      dispatch(agentChatSlice.actions.clearOptimisticMessages());

      try {
        await dispatch(pollForAgentResponse({ customerId, chatId: newChatId }));
      } catch (error) {
        // Verify we're still on the same chat before updating error state
        if ((getState() as RootState).agentChat.id === newChatId) {
          // If polling for agent response fails, we still want the initial chat to be created
          // and the UI to remain functional
          dispatch(agentChatSlice.actions.setError(`Error while waiting for agent response: ${(error as Error).message}`));
        }
        // Don't rethrow here - we want the UI to remain functional
      }
    } catch (error) {
      // If there's an error, keep the optimistic message but mark the error
      dispatch(agentChatSlice.actions.setError((error as Error).message));
      throw error;
    }
  }
);

export const sendMessage = createAsyncThunk(
  "agentChat/sendMessage",
  async ({ customerId, message }: Omit<SendMessageParams, 'deepResearch'>, { dispatch, getState }) => {
    const state = getState() as RootState;
    const chatId = state.agentChat.id;
    const deepResearchFromState = state.agentChat.deepResearch;
    
    // Store the chat ID at the time of sending to verify it doesn't change
    const originalChatId = chatId;
    
    // Immediately show the message in the UI (optimistic update)
    dispatch(agentChatSlice.actions.addOptimisticUserMessage(message));

    try {
      await sendAgentChatMessage(customerId, chatId, message, deepResearchFromState);

      // Verify the chat hasn't changed before continuing with acknowledgment
      if ((getState() as RootState).agentChat.id !== originalChatId) {
        console.log(`Chat changed during message send operation, aborting polling`);
        return; // Exit early if chat has changed
      }

      const ackState = await pollUntilMessageAcknowledged(customerId, chatId, message, dispatch, getState);
      
      // Check again that we're still on the same chat before updating state
      if ((getState() as RootState).agentChat.id === originalChatId) {
        dispatch(agentChatSlice.actions.updateAgentState(ackState));
        // Clear optimistic messages once we have server confirmation
        dispatch(agentChatSlice.actions.clearOptimisticMessages());

        try {
          await dispatch(pollForAgentResponse({ customerId, chatId }));
        } catch (error) {
          // Verify we're still on the original chat
          if ((getState() as RootState).agentChat.id === originalChatId) {
            // If polling for agent response fails after acknowledgment, we still want to
            // keep the conversation going rather than crashing the UI
            dispatch(agentChatSlice.actions.setError(`Error while waiting for agent: ${(error as Error).message}`));
          }
          // Don't rethrow here - we want the UI to remain functional
        }
      } else {
        console.log(`Chat changed after message acknowledgment, not updating UI`);
      }
    } catch (error) {
      // Only update error state if we're still on the original chat
      if ((getState() as RootState).agentChat.id === originalChatId) {
        // Keep the optimistic message visible in case of error, but mark the error state
        dispatch(agentChatSlice.actions.setError((error as Error).message));
      }
      throw error;
    }
  }
);

export const agentChatSlice = createSlice({
  name: "agentChat",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setChatViewId: (state, action: PayloadAction<ViewId>) => {
      // When changing view ID, preserve the new view ID and deep research setting
      // but reset everything else in the conversation
      const deepResearchSetting = state.deepResearch;
      
      // Reset state to clear the conversation
      Object.assign(state, initialState);
      
      // Set the new view ID and preserve deep research setting
      state.viewId = action.payload;
      state.deepResearch = deepResearchSetting;
    },
    updateInitialState: (state, action: PayloadAction<AgentState>) => {
      state.latestAgentState = action.payload;
      state.error = null;
    },
    updateAgentState: (state, action: PayloadAction<AgentState>) => {
      state.latestAgentState = action.payload;
      state.error = null;
    },
    setPendingMessage: (state, action: PayloadAction<string | null>) => {
      state.pendingUserMessage = action.payload;
    },
    addOptimisticUserMessage: (state, action: PayloadAction<string>) => {
      // Add a new optimistic user message to show immediately in the UI
      const optimisticMessage: UserMessage = {
        content: action.payload,
        role: "user",
      };
      state.optimisticUserMessages.push(optimisticMessage);
    },
    clearOptimisticMessages: (state) => {
      // Clear optimistic messages once we have server confirmation
      state.optimisticUserMessages = [];
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setDeepResearch: (state, action: PayloadAction<boolean>) => {
      state.deepResearch = action.payload;
    },
    newChat: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(initializeChat.rejected, (state, action) => {
      state.error = action.error.message || "Failed to initialize chat";
    });
  },
});

// Base selector
const selectAgentChatState = (state: RootState) => state.agentChat;

// Primary selectors
export const selectMessages = createSelector([selectAgentChatState], (chat): Message[] => {
  // Start with server-provided messages if available
  const serverMessages = chat.latestAgentState?.messages || [];
  
  // Add any optimistic messages that might not be on the server yet
  const allMessages = [...serverMessages];
  
  // Add optimistic user messages if any exist
  if (chat.optimisticUserMessages.length > 0) {
    // Only add optimistic messages if they haven't shown up in server response yet
    const optimisticMessages = chat.optimisticUserMessages.filter(optMsg => {
      // Check if this message already exists in server messages
      return !serverMessages.some(srvMsg => 
        srvMsg.role === 'user' && srvMsg.content === optMsg.content
      );
    });
    
    allMessages.push(...optimisticMessages);
  }
  
  // For backward compatibility, still use the pendingUserMessage if set
  if (chat.pendingUserMessage !== null) {
    const pendingMessage: UserMessage = {
      content: chat.pendingUserMessage,
      role: "user",
    };
    
    // Only add if not already in the list
    if (!allMessages.some(msg => msg.role === 'user' && msg.content === chat.pendingUserMessage)) {
      allMessages.push(pendingMessage);
    }
  }
  
  return allMessages;
});

export const selectChatViewId = createSelector(
  [selectAgentChatState],
  (chat): ViewId | null => chat.viewId
);

export const selectError = createSelector(
  [selectAgentChatState],
  (chat): string | null => chat.error
);

export const selectDeepResearch = createSelector(
  [selectAgentChatState],
  (chat): boolean => chat.deepResearch
);

// Derived selectors
export const selectHasMessages = createSelector(
  [selectMessages],
  (messages): boolean => messages.length > 0
);

export const selectLastMessage = createSelector(
  [selectMessages],
  (messages): Message | null => messages[messages.length - 1] || null
);

export const selectChatStatus = createSelector(
  [selectAgentChatState, selectLastMessage],
  (chat, lastMessage): ChatStatus => {
    if (chat.error) {
      return ChatStatus.ERROR;
    }
    if (chat.pendingUserMessage !== null) {
      return ChatStatus.SENDING;
    }
    if (lastMessage === null || lastMessage.role === "agent") {
      return ChatStatus.READY;
    }
    return ChatStatus.WAITING_FOR_AGENT;
  }
);

export const selectInputPlaceholder = createSelector([selectChatStatus], (status): string => {
  switch (status) {
    case ChatStatus.ERROR:
      return "An error occurred";
    case ChatStatus.SENDING:
    case ChatStatus.WAITING_FOR_AGENT:
      return "Waiting for response...";
    case ChatStatus.READY:
      return "Type your message...";
  }
});

export const { newChat, setChatViewId } = agentChatSlice.actions;

export default agentChatSlice.reducer;
