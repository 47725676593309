import { CloseOutlined, ExperimentOutlined } from "@ant-design/icons";
import { Alert, Tooltip as AntTooltip, Button, Popover, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { useAppSelector, useCustomer, useStreamingDataLoader } from "../../../hooks";
import { summarizeTaxonomyNode } from "../../../reportApi";
import "../Dashboard.less";
import "./NodeConversationSummary.less";

interface NodeConversationSummaryProps {
  taxonomyNodeId: string;
}

const loadingMessages = [
  "Sampling conversations...",
  "Analyzing messages & metadata...",
  "Generating summary...",
  "Polishing things up...",
];

export const NodeConversationSummary: React.FC<NodeConversationSummaryProps> = ({
  taxonomyNodeId,
}: NodeConversationSummaryProps) => {
  const { customer } = useCustomer();
  const state = useAppSelector(state => state.dashboard);
  const [showAiSummaryTooltip, setShowAiSummaryTooltip] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const GRAY = "#A5AABF";
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[0]);

  const {
    loading: nodeSummaryLoading,
    data: nodeSummaryData,
    error: nodeSummaryError,
  } = useStreamingDataLoader(
    async controller => {
      if (!openPopover || !state.viewState.data || !state.viewState.data.taxonomy.data?.id) {
        return;
      }
      const context = {
        viewId: state.viewState.data.view.id,
        startDate: state.viewState.data.settings.startDate,
        endDate: state.viewState.data.settings.endDate,
        filters: state.viewState.data.settings.filters,
        taxonomyNodesFilter: state.viewState.data.settings.taxonomyNodeFilters,
      };
      const resp = await summarizeTaxonomyNode(
        customer.id,
        state.viewState.data.taxonomy.data.id,
        taxonomyNodeId,
        context,
        controller.signal
      );

      return resp;
    },
    [customer, openPopover, taxonomyNodeId, state.viewState.data]
  );

  useEffect(() => {
    if (nodeSummaryLoading) {
      let index = 0;
      const ROTATION_INTERVAL = 5000;
      const intervalId = setInterval(() => {
        index = index + 1;
        if (index >= loadingMessages.length) {
          index = loadingMessages.length - 1;
        }
        setLoadingMessage(loadingMessages[index]);
      }, ROTATION_INTERVAL);

      return () => clearInterval(intervalId);
    } else {
      setLoadingMessage(loadingMessages[0]);
    }
  }, [nodeSummaryLoading]);

  return (
    <AntTooltip
      title="Summarize Conversations in this Category with Spiral AI"
      mouseLeaveDelay={0}
      open={showAiSummaryTooltip}
      onOpenChange={setShowAiSummaryTooltip}
      overlayInnerStyle={{ maxWidth: "200px", textAlign: "center" }}
    >
      <Popover
        trigger="click"
        title={
          <div className="node-summary-title">
            <Space>
              <ExperimentOutlined style={{ color: "#465fc3" }} />
              Spiral AI Summary
            </Space>
            <CloseOutlined
              className="close-icon"
              onClick={e => {
                e.stopPropagation();
                setOpenPopover(false);
              }}
            />
          </div>
        }
        open={openPopover}
        onOpenChange={setOpenPopover}
        content={
          <div className="node-summary-content">
            <Spin
              spinning={nodeSummaryLoading && !nodeSummaryData}
              tip={<p className="loading-msg">{loadingMessage}</p>}
            >
              {nodeSummaryError ? (
                <Alert message="Summary could not be generated." type="error" className="rounded" />
              ) : (
                <ReactMarkdown className="list">{nodeSummaryData}</ReactMarkdown>
              )}
            </Spin>
          </div>
        }
        placement="right"
      >
        <Button
          shape="circle"
          type="text"
          icon={<ExperimentOutlined style={{ color: GRAY }} />}
          onClick={e => {
            e.stopPropagation();
            setShowAiSummaryTooltip(false);
            setOpenPopover(true);
          }}
        />
      </Popover>
    </AntTooltip>
  );
};
