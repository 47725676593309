import React from "react";

import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";

export const DateRangeSelector: React.FC<{
  title: string;
  startDate: Moment;
  endDate: Moment;
  onDateChange: (dates: [Moment, Moment]) => void;
}> = ({ title, startDate, endDate, onDateChange }) => (
  <Space>
    <label htmlFor="datePicker">{title}:</label>
    <DatePicker.RangePicker
      id="datePicker"
      ranges={{
        "Last 7 Days": [moment().subtract(7, "days"), moment()],
        "Last 30 Days": [moment().subtract(30, "days"), moment()],
        "Last 90 Days": [moment().subtract(90, "days"), moment()],
        "Last 1 Year": [moment().subtract(1, "year"), moment()],
      }}
      onChange={dates => {
        if (dates && dates[0] && dates[1]) {
          onDateChange([dates[0], dates[1]]);
        }
      }}
      defaultValue={[startDate, endDate]}
      disabledDate={current => current > moment()}
      className="rounded"
    />
  </Space>
);
