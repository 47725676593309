import { apiV2Endpoint, fetchWithReject, headers } from "@/utils";
import { CustomerId, ViewId } from "./types/dashboardTypes";

export type TaxonomyNodeId = string;

export interface MarkdownBlock {
  type: "markdown";
  markdown: string;
}

export interface TaxonomyNodeLinkBlock {
  type: "taxonomy_node_link";
  node_id: TaxonomyNodeId;
  display_text: string;
}

export interface PieChartBlock {
  type: "pie_chart";
  title: string;
  data: DataPoint[];
  query: string; // SQL query that powers the chart
}

export interface BarChartBlock {
  type: "bar_chart";
  title: string;
  data: DataPoint[];
  query: string; // SQL query that powers the chart
}

export interface DataPoint {
  x: string;
  y: number;
}

export interface ComparisonDataPoint {
  x: string;
  y1: number;
  y2: number;
}

export interface LineChartBlock {
  type: "line_chart";
  title: string;
  data: DataPoint[]; // Series of data points for the line chart
  query: string; // SQL query that powers the chart
}

export interface LineChartComparisonBlock {
  type: "line_chart_comparison";
  title: string;
  data: ComparisonDataPoint[]; // Series of data points for comparing two lines
  query: string; // SQL query that powers the chart
}

export interface ConversationMetadataBlock {
  type: "example_conversations";
  customer_id: string;
  conversation_ids: number[];
}

export type AgentResponseBlock =
  | MarkdownBlock
  | TaxonomyNodeLinkBlock
  | PieChartBlock
  | BarChartBlock
  | LineChartBlock
  | LineChartComparisonBlock
  | ConversationMetadataBlock;

export interface AgentMessage {
  role: "agent";
  content: AgentResponseBlock[];
}

export interface UserMessage {
  role: "user";
  content: string;
}

export interface ToolUseEvent {
  tool_name: string;
  reasoning: string;
}

export interface ToolUseEventMessage {
  role: "tool_use_event";
  content: ToolUseEvent;
}

export type Message = AgentMessage | UserMessage | ToolUseEventMessage;

export interface AgentState {
  id: string;
  messages: Message[];
}

export const getAgentChat = async (
  customerId: CustomerId,
  agentChatId: string,
  signal?: AbortSignal
): Promise<AgentState | null> => {
  return fetchWithReject(`${apiV2Endpoint}/customers/${customerId}/agent-chat/${agentChatId}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
    signal,
  }).then(r => r.json());
};

export const createAgentChat = async (
  customerId: CustomerId,
  viewId: ViewId,
  message: string,
  deepResearch: boolean = false,
  signal?: AbortSignal
): Promise<AgentState> => {
  console.log('API createAgentChat - deepResearch flag:', deepResearch);
  const requestBody = { message, deepResearch };
  console.log('Full request body being sent:', requestBody);
  
  return fetchWithReject(`${apiV2Endpoint}/customers/${customerId}/agent-chat/view/${viewId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify(requestBody),
    signal,
  }).then(r => r.json());
};

export const sendAgentChatMessage = async (
  customerId: CustomerId,
  agentChatId: string,
  message: string,
  deepResearch: boolean = false,
  signal?: AbortSignal
): Promise<void> => {
  console.log('API sendAgentChatMessage - deepResearch flag:', deepResearch);
  const requestBody = { message, deepResearch };
  console.log('Full request body being sent:', requestBody);
  
  return fetchWithReject(`${apiV2Endpoint}/customers/${customerId}/agent-chat/${agentChatId}`, {
    method: "POST",
    headers: await headers(),
    mode: "cors",
    body: JSON.stringify(requestBody),
    signal,
  }).then(r => r.json());
};
