import {
  Affix,
  Alert,
  Card,
  Col,
  Empty,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Statistic,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import moment, { Moment } from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useInfiniteScroll } from "../../hooks";

import { MetadataAnalysisWidget } from "../../components/MetadataAnalysisWidget";
import { TimeseriesChart } from "../../components/TimeseriesChart";
import { useAppDispatch, useAppSelector, useCustomer } from "../../hooks";
import { MetadataAnalysisOptions, TaxonomyTimeseriesMetadata } from "../../types/dashboardTypes";
import { ParsedExpression } from "../../types/expressionsDslTypes";
import { MultiFilter } from "../../components/MultiFilter";
import { getDatasetIdsFromParsedExpression } from "../../utils";

import { useSelector } from "react-redux";
import {
  initViewThunk,
  searchTaxonomyNodesThunk,
  selectVisibleTaxonomyTree,
  setError,
  updateViewSettingsThunk,
} from "../../features/dashboard/dashboardSlice";
import { RootState } from "../../store";
import "./Dashboard.less";
// import TaxonomyNodeList from "./components/TaxonomyNodeList";
import { DateRangeSelector } from "@/components/DateRangeSelector";
import { TaxonomyNodeFilters } from "../../indexTypes";
import { Loadable } from "../../types/util";
import { TaxonomyNode } from "./components/TaxonomyNode";

const { Title } = Typography;

/**
 * Safely wrap a possibly undefined Loadable<T>.
 */
function safeLoadable<T>(val?: Loadable<T>): Loadable<T> {
  return val ?? { loading: false, data: undefined, error: undefined };
}

/* ------------------------------------------------------------------
 * Standalone Components
 * ------------------------------------------------------------------ */

const GranularitySelector: React.FC<{
  granularity: string;
  onGranularityChange: (value: string) => void;
}> = ({ granularity, onGranularityChange }) => (
  <Space>
    <label htmlFor="granularity">Show by:</label>
    <Select
      id="granularity"
      value={granularity}
      onChange={onGranularityChange}
      style={{ width: 100 }}
    >
      <Select.Option value="hour">Hour</Select.Option>
      <Select.Option value="day">Day</Select.Option>
      <Select.Option value="week">Week</Select.Option>
      <Select.Option value="month">Month</Select.Option>
    </Select>
  </Space>
);

const OverviewCard: React.FC<{
  taxonomyTimeseriesMetadata: Loadable<TaxonomyTimeseriesMetadata>;
  chartTitle: string;
  viewId: string;
  startDate: string;
  endDate: string;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
  legendText: string;
  taxonomyNodeFilters?: TaxonomyNodeFilters;
}> = ({
  taxonomyTimeseriesMetadata,
  chartTitle,
  viewId,
  startDate,
  endDate,
  metadataAnalysisChoice,
  filters,
  legendText,
  taxonomyNodeFilters,
}) => (
  <Row key="reportOverviewCard">
    <Col span={24}>
      <Card className="reportOverviewCard rounded" style={{ margin: "0 24px 24px" }}>
        <Row>
          <Col span={16} style={{ textAlign: "center" }}>
            <Spin spinning={taxonomyTimeseriesMetadata.loading}>
              <Tooltip title="Total Contacts in this Report">
                <Statistic value={taxonomyTimeseriesMetadata.data?.total} />
              </Tooltip>
              <TimeseriesChart
                key={taxonomyTimeseriesMetadata.data?.id}
                metadata={taxonomyTimeseriesMetadata.data?.rawCounts}
                secondaryMetadata={taxonomyTimeseriesMetadata.data?.percentCounts}
                graphheight={300}
                xaxis
                yaxis
                grid
                animationDuration={150}
                preserve={"preserveEnd"}
                percent={false}
                xTickFormat={"MMM D"}
                legendText={legendText}
                showTooltip
              />
            </Spin>
          </Col>
          <Col span={8}>
            <MetadataAnalysisWidget
              chartTitle={chartTitle}
              type="bar"
              viewId={viewId}
              startDate={startDate}
              endDate={endDate}
              metadataAnalysisChoice={metadataAnalysisChoice}
              filters={filters}
              taxonomyNodeFilters={taxonomyNodeFilters}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
);

const OverviewCardContainer: React.FC = () => {
  const dashboardState = useAppSelector(state => state.dashboard);
  const data = dashboardState.viewState.data;

  const taxonomyTimeseriesLoadable = safeLoadable(data?.taxonomyTimeseries);
  const taxonomyNodes = useSelector(selectVisibleTaxonomyTree);

  const view = data?.view;
  const settings = data?.settings;

  if (!taxonomyNodes.loading && taxonomyNodes.data && taxonomyNodes.data.length == 0) {
    return (
      <Empty
        style={{ padding: "30px 0" }}
        description="No data available with current filters applied!"
      />
    );
  }

  const chartTitle =
    (settings?.metadataAnalysisChoice &&
      view?.settings.metadataAnalysisOptions[settings.metadataAnalysisChoice].display_name) ||
    "";

  return (
    <OverviewCard
      taxonomyTimeseriesMetadata={taxonomyTimeseriesLoadable}
      chartTitle={chartTitle}
      viewId={view?.id || ""}
      startDate={settings?.startDate || ""}
      endDate={settings?.endDate || ""}
      metadataAnalysisChoice={settings?.metadataAnalysisChoice || ""}
      filters={settings?.filters}
      legendText={view?.settings.legendText || "Contacts"}
    />
  );
};

const DummyIssue: React.FC = () => (
  <Col span={24}>
    <Card className="rounded" style={{ height: "67px", background: "white", margin: "10px 0px" }}>
      <Row>
        <Col span={24}>
          <div style={{ height: "50px" }} />
        </Col>
      </Row>
    </Card>
  </Col>
);

/** TaxonomyNodeDataContainer (grouped or ungrouped + pagination) */
const TaxonomyNodeDataContainer: React.FC = () => {
  const { viewState } = useAppSelector((state: RootState) => state.dashboard);
  const viewStateData = viewState.data;
  const taxonomyNodes = useSelector(selectVisibleTaxonomyTree);

  const { visibleCount, sentinelRef } = useInfiniteScroll({
    initialCount: 5,
    incrementBy: 5,
    isReady: !(
      !viewStateData ||
      !taxonomyNodes.data ||
      viewStateData.taxonomy.loading ||
      !viewStateData.taxonomy.data
    ),
  });

  if (
    !viewStateData ||
    !taxonomyNodes.data ||
    viewStateData.taxonomy.loading ||
    !viewStateData.taxonomy.data
  ) {
    return (
      <Spin spinning={true} tip="Loading Issues...">
        <Row style={{ margin: "0 24px 24px" }}>
          {[...Array(10)].map((_, i) => (
            <DummyIssue key={i} />
          ))}
        </Row>
      </Spin>
    );
  }

  const settings = viewStateData.settings;
  const taxonomyId = viewStateData.taxonomy.data.id;
  const visibleNodes = taxonomyNodes.data.slice(0, visibleCount);

  return (
    <Spin spinning={viewStateData.semanticMatchedIds.loading} tip="Searching issues...">
      <Row style={{ margin: "0 24px 24px" }}>
        {visibleNodes.map(node => (
          <Col span={24} key={node.id}>
            <TaxonomyNode
              taxonomyNode={node}
              granularity={settings.granularity}
              startDate={settings.startDate}
              endDate={settings.endDate}
              taxonomyId={taxonomyId}
              viewId={viewStateData.view.id}
              metadataAnalysisOptions={viewStateData.view.settings.metadataAnalysisOptions}
              metadataAnalysisChoice={settings.metadataAnalysisChoice || ""}
              filters={settings.filters}
              taxonomyNodeFilters={settings.taxonomyNodeFilters}
              exactMatchedSubstring={settings.submittedIssueSearchInput}
            />
          </Col>
        ))}
      </Row>

      {/* Sentinel element for infinite scroll */}
      <Row ref={sentinelRef} style={{ margin: "0 24px 24px" }}>
        {/* Render dummy issues for remaining items */}
        {[...Array(Math.max(0, taxonomyNodes.data.length - visibleCount))].map((_, i) => (
          <DummyIssue key={`placeholder-${i + visibleCount}`} />
        ))}
      </Row>
    </Spin>
  );
};

const SearchBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector(state => state.dashboard);
  const data = dashboardState.viewState.data;
  const loading = safeLoadable(data?.taxonomy).loading;

  const searchValue = data?.settings.submittedIssueSearchInput || "";
  const customerId = data?.view.customerId ?? "";

  const handleSearch = (val?: string) => {
    dispatch(
      searchTaxonomyNodesThunk({
        customerId,
        searchValue: val,
      })
    );
  };

  return (
    <span style={{ paddingLeft: "10px", width: "50%" }}>
      <Input.Search
        allowClear
        className="rounded search-bar"
        placeholder="Search Issues"
        onSearch={handleSearch}
        onChange={e => {
          if (e.target.value.length === 0) {
            handleSearch(undefined);
          }
        }}
        enterButton="Search"
        loading={!!searchValue && loading}
      />
    </span>
  );
};

const MetadataAnalysisSelector: React.FC<{
  metadataAnalysisChoice: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  onMetadataAnalysisChange: (value: string) => void;
}> = ({ metadataAnalysisChoice, metadataAnalysisOptions, onMetadataAnalysisChange }) => (
  <Space>
    <label htmlFor="metadataAnalysisSelector">Analyze by:</label>
    <Select
      id="metadataAnalysisSelector"
      value={metadataAnalysisChoice}
      onChange={onMetadataAnalysisChange}
      style={{ width: 150 }}
    >
      {Object.entries(metadataAnalysisOptions).map(([fieldName, configs]) => (
        <Select.Option key={fieldName} value={fieldName}>
          {configs.display_name}
        </Select.Option>
      ))}
    </Select>
  </Space>
);

const MetadataAnalysisSelectorContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector(state => state.dashboard);
  const data = dashboardState.viewState.data;

  const metadataAnalysisChoice = data?.settings.metadataAnalysisChoice || "";
  const metadataAnalysisOptions = data?.view.settings.metadataAnalysisOptions || {};
  const customerId = data?.view.customerId || "";

  const handleChange = (value: string) => {
    dispatch(
      updateViewSettingsThunk({
        newSettings: { metadataAnalysisChoice: value },
        customerId,
      })
    );
  };

  return (
    <MetadataAnalysisSelector
      metadataAnalysisChoice={metadataAnalysisChoice}
      metadataAnalysisOptions={metadataAnalysisOptions}
      onMetadataAnalysisChange={handleChange}
    />
  );
};

const IssueGroupToggle: React.FC = () => {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector(state => state.dashboard);
  const data = dashboardState.viewState.data;

  const issuesGrouped = data?.settings.issuesGrouped ?? true;
  const customerId = data?.view.customerId || "";

  const handleToggle = (checked: boolean) => {
    dispatch(
      updateViewSettingsThunk({
        newSettings: { issuesGrouped: checked },
        customerId,
      })
    );
  };

  return (
    <Switch
      checkedChildren="Grouped"
      unCheckedChildren="Ungrouped"
      onChange={handleToggle}
      defaultChecked
      checked={issuesGrouped}
    />
  );
};

const ViewSettingsControls: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(s => s.dashboard.viewState);

  if (!data?.view) return null;

  const { settings, view } = data;

  const handleDateChange = ([start, end]: [Moment, Moment]) => {
    dispatch(
      updateViewSettingsThunk({
        newSettings: {
          startDate: start.format("YYYY-MM-DD"),
          endDate: end.format("YYYY-MM-DD"),
        },
        customerId: view.customerId,
      })
    );
  };

  return (
    <>
      <Space size="small">
        <DateRangeSelector
          title={"Report Range"}
          startDate={moment(settings.startDate)}
          endDate={moment(settings.endDate)}
          onDateChange={handleDateChange}
        />
        <GranularitySelector
          granularity={settings.granularity}
          onGranularityChange={value =>
            dispatch(
              updateViewSettingsThunk({
                newSettings: { granularity: value },
                customerId: view.customerId,
              })
            )
          }
        />
        <MetadataAnalysisSelectorContainer />
        <IssueGroupToggle />
      </Space>
      <Row style={{ margin: "12px 0" }}>
        <Col span={24}>
          <MultiFilter 
            viewFilters={view.settings.filterFieldNames} 
            datasets={view.expression ? getDatasetIdsFromParsedExpression(view.expression) : []}
            onFilterChange={(formattedFilters, selectedSentiment) => {
              dispatch(
                updateViewSettingsThunk({
                  newSettings: {
                    filters: formattedFilters,
                    taxonomyNodeFilters: { sentiment: selectedSentiment || [] },
                  },
                  customerId: view.customerId,
                })
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

/* ------------------------------------------------------------------
 * InnerDashboardView + Dashboard
 * ------------------------------------------------------------------ */

/** The main dashboard logic for a single view */
const InnerDashboardView: React.FC<{ viewId: string }> = ({ viewId }) => {
  const dispatch = useAppDispatch();
  const dashboardState = useAppSelector(state => state.dashboard);
  const { customer } = useCustomer();
  const viewState = dashboardState.viewState;
  const data = viewState.data;
  const error = viewState.error;

  // On mount, set the viewId & init
  useEffect(() => {
    dispatch(initViewThunk({ viewId, customerId: customer.id }));
  }, [customer.id, dispatch, viewId]);

  const canShowSearchBar = data?.taxonomy.data;

  return (
    <div id="reportsviewer">
      <Affix className="topcontrols">
        <div>
          {error && (
            <Alert
              type="error"
              message={error}
              className="rounded"
              showIcon
              closable
              onClose={() => dispatch(setError(undefined))}
            />
          )}
          {/* Page Title + Search Bar */}
          <Row align="middle" style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}>
            <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
              <Title level={3} style={{ display: "inline", margin: 0 }}>
                {data?.view.name || "Support Conversation Analysis"}
              </Title>
              {canShowSearchBar && <SearchBar />}
            </Col>
          </Row>

          {/* All the "view settings" + MultiFilter in one container */}
          <Row style={{ margin: "0 24px 0px", padding: "12px 0 0 0" }}>
            <Col span={24}>
              <ViewSettingsControls />
            </Col>
          </Row>
        </div>
      </Affix>

      {/* The overview card */}
      <OverviewCardContainer />

      {/* The node data container (grouped or ungrouped) */}
      <TaxonomyNodeDataContainer />
    </div>
  );
};

/** The top-level Dashboard */
export const Dashboard: React.FC = () => {
  const { viewId } = useParams<{ viewId: string }>();
  if (!viewId) {
    console.error("In Dashboard page but no view ID provided, this should not be possible");
    return <div>Something went wrong, try refreshing the page or contact support</div>;
  }
  return <InnerDashboardView viewId={viewId} />;
};
