import { headers } from "./utils";

// Get environment variables based on environment
declare const process: { env: { [key: string]: string } };
declare const VITE_ENV_VARS: { [key: string]: string };

// Access environment variables with test compatibility
const getEnvVar = (key: string): string => {
  if (typeof VITE_ENV_VARS !== 'undefined') {
    return VITE_ENV_VARS[key] || '';
  }
  return process.env[key] || '';
};

const endpoint = getEnvVar('REACT_APP_API_ENDPOINT');

export type SignedS3URLData = {
  url: string;
  fields: { [k: string]: string };
};

export const getDemosV1 = async () =>
  fetch(`${endpoint}/demos/v1`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getDemosV1Meta = async (customer: string) =>
  fetch(`${endpoint}/demos/v1/${customer}/meta`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const postDemosV1Meta = async (customer: string, metadata: string) =>
  fetch(`${endpoint}/demos/v1/${customer}/meta`, {
    method: "PUT",
    body: metadata,
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const createDemoV2 = async (customer: string, iosurl?: string, androidurl?: string) =>
  fetch(`${endpoint}/demos/v2`, {
    method: "POST",
    body: JSON.stringify({ customer, iosurl, androidurl }),
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json());

export const getStaticAssetUploadURI = async (fileName: string, fileType: string) =>
  fetch(`${endpoint}/s3_upload/static?fileName=${fileName}&fileType=${fileType}`, {
    method: "GET",
    headers: await headers(),
    mode: "cors",
  }).then(r => r.json() as unknown as SignedS3URLData);

export const getDemosV1UploadURI = async (customer: string, fileName: string, fileType: string) =>
  fetch(
    `${endpoint}/demos/v1/${customer}/logo_upload?fileName=${customer}/${fileName}&fileType=${fileType}`,
    {
      method: "GET",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json() as unknown as SignedS3URLData);

 export const invalidateCloudFrontLogoURI = async (customer: string) =>
  fetch(`${endpoint}/asset_invalidation/${customer}`,
    {
      method: "POST",
      headers: await headers(),
      mode: "cors",
    }
  ).then(r => r.json());

export const uploadStaticAsset = async (file: File, dest: string) => {
  const resp = await getStaticAssetUploadURI(dest, file.type);
  if (!resp.url) {
    throw new Error(`invalid s3 signed url response: ${resp}`);
  }

  const formData = new FormData();
  for (const field in resp.fields) {
    formData.append(field, resp.fields[field]);
  }
  formData.append("file", file);
  const uploadResp = await fetch(resp.url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: formData,
    mode: "cors",
  });
  if (uploadResp.status >= 400) {
    throw new Error(`Unable to upload to s3. response: ${uploadResp}`);
  }
};

// This method can be removed once demo manager v1 is removed.
export const uploadDemoLogoToS3 = async (customer: string, file: File, filename?: string) => {
  const resp = await getDemosV1UploadURI(customer, filename ?? file.name, file.type);
  if (!resp.url) {
    throw new Error(`invalid s3 signed url response: ${resp}`);
  }
  const formData = new FormData();

  for (const field in resp.fields) {
    formData.append(field, resp.fields[field]);
  }
  formData.append("file", file);
  fetch(resp.url, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: formData,
    mode: "cors",
  }).then(r => r);
};
