import { Auth } from "aws-amplify";
import React from "react";
import {
  ConversationMetadata,
  SidebarReportItem,
  SidebarSubMenu,
  SidebarViewItem,
} from "./indexTypes";
import { ConversationMetadataField } from "./types/dashboardTypes";
import { Expression, ParsedExpression } from "./types/expressionsDslTypes";

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*(@|\sat\s)(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(\.|\sdot\s))+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const DOB = new RegExp(
  [
    "(?:\\d{1,2}[-/\\s]\\d{1,2}[-/\\s]'?\\d{2,4})",
    "(?:\\d{2,4}[-/\\s]\\d{1,2}[-/\\s]\\d{1,2})",
    "(?:(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)[\\s-/,]*?\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*[-/,]?(?:\\s)*'?\\d{2,4})",
    "(?:\\d{1,2}(?:\\s)*(?:rd|th|st)?(?:\\s)*(?:January|February|March|April|May|June|July|August|September|October|November|December|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sept|Sep|Oct|Nov|Dec)(?:\\s)*?[-/,]?(?:\\s)*'?\\d{2,4})",
  ].join("|"),
  "i"
);
const cc = /\b(?:d{4}[ -]?){3}(?=d{4}\b)/;
const ph = /\b(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\b/;

// Get environment variables based on environment
declare const process: { env: { [key: string]: string } };
declare const VITE_ENV_VARS: { [key: string]: string };

// Access environment variables with test compatibility
const getEnvVar = (key: string): string => {
  if (typeof VITE_ENV_VARS !== 'undefined') {
    return VITE_ENV_VARS[key] || '';
  }
  return process.env[key] || '';
};

export const endpoint = getEnvVar('REACT_APP_API_ENDPOINT');
export const apiV2Endpoint = getEnvVar('REACT_APP_API_V2_ENDPOINT');

export const headers = async () => {
  const session = await Auth.currentSession();
  return {
    "Content-Type": "application/json",
    "Cache-Control": "public",
    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
  };
};

export const fetchWithReject = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> =>
  fetch(input, init).then(res => {
    if (!res.ok) {
      res.json().then(console.error);
      throw Error(`Request rejected with status ${res.status}`);
    }
    return res;
  });

export const stripPii = (text: string) =>
  text
    .replace(emailRegex, "{{EMAIL}}")
    .replace(DOB, "{{DOB}}")
    .replace(cc, "{{CARD}}")
    .replace(ph, "{{PHONE}}")
    .replace("{{DOB}}", "{{DATE}}");

export const select = <T,>(arr: T[], idxs: number[]) => idxs.map(i => arr[i]).filter(z => !!z);

const acronyms = ["sms", "nps", "csat", "dsat", "fcr", "id", "url", "api", "ui", "ux", "sf", "uri"];

const titleCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const displayName = (
  str: string | undefined | null,
  displayNames?: { [name: string]: string }
) => {
  if (str === undefined || str === null) return "None";
  if (displayNames && str in displayNames) return displayNames[str];
  if (str.toString().trim() === "") return "N/A";

  return str
    .toString()
    .replaceAll("_", " ")
    .replaceAll(/(\w)-(\w)/g, "$1 $2")
    .replaceAll(/((?:(?:^|\s)[a-z]|[A-Z])[a-z0-9]+)/g, "$1 ")
    .trim()
    .replaceAll("s{2,}", " ")
    .split(" ")
    .map(word => {
      const w = word.toLowerCase();
      return acronyms.includes(w) ? w.toUpperCase() : titleCase(w);
    })
    .join(" ");
};

export const isDefined = <T,>(item: T | null | undefined): item is T => !!item;

export const sum = (a: number, b: number) => a + b;

export const truncate = (str: string, len: number, wrapInSpan = true) => {
  const shortened = str.substr(0, len - 1) + (str.length > len ? "..." : "");
  return wrapInSpan ? <span title={str}>{shortened}</span> : shortened;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarSubMenu = (obj: any): obj is SidebarSubMenu => {
  // eslint-disable-next-line id-blacklist
  return isDefined(obj.items);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarReportItem = (obj: any): obj is SidebarReportItem => {
  // eslint-disable-next-line id-blacklist
  return isDefined(obj.reportSet);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSidebarViewItem = (obj: any): obj is SidebarViewItem => {
  return isDefined(obj.viewId);
};

export const buildShareUrl = (
  reportUrlHash: string,
  clusterId = "",
  superclusterId = "",
  megaclusterId = ""
) => {
  const cIdStr = clusterId ? `cid=${clusterId}` : undefined;
  const scIdStr = superclusterId ? `sid=${superclusterId}` : undefined;
  const mcIdStr = megaclusterId ? `mid=${megaclusterId}` : undefined;
  return `${window.location.origin}/dashboards/${reportUrlHash}?${[cIdStr, scIdStr, mcIdStr]
    .filter(isDefined)
    .join("&")}`;
};

export const formatPercent = (p: number, precision = 2) =>
  `${p < 0.0001 ? "< 0.01" : (p * 100).toFixed(precision)}%`;

const lineColors = [
  "#465fc3", // Original blue
  "#ff4e4e", // Original red
  "#43b139", // Original green
  "#faa263", // Original orange
  "#ffbf00", // Original yellow
  "#8e44ad", // Purple
  "#16a085", // Teal
  "#e67e22", // Dark orange
  "#3498db", // Light blue
  "#2ecc71", // Lime green
  "#e74c3c", // Bright red
  "#1abc9c", // Turquoise
  "#f39c12", // Amber
  "#9b59b6", // Lavender
  "#27ae60", // Emerald
];

export const tagColor = (tagId: string): string => {
  // Convert the tag ID to a number
  const numericId = tagId.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Use modulo to ensure the index is within the bounds of lineColors array
  const colorIndex = numericId % lineColors.length;

  // Return the selected color
  return lineColors[colorIndex];
};

export const getContrastColor = (hexColor: string) => {
  // Convert hex to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Return black or white based on luminance
  return luminance > 0.65 ? "#363F4A" : "#FBFBFB";
};

const CRM_URL_CONVERSATION_ID_PLACEHOLDER = "<conversation_id>";
export const renderCrmUrl = (crmUrlTemplate: string, conversation: ConversationMetadata) => {
  let crmUrl = crmUrlTemplate.replace(
    CRM_URL_CONVERSATION_ID_PLACEHOLDER,
    conversation.conversation_key
  );
  [...crmUrl.matchAll(/<.*?>/g)]
    .map(match => match[0])
    .forEach(match => {
      const name = match.slice(1, -1);
      if (
        conversation.optional_metadata &&
        name in conversation.optional_metadata &&
        isDefined(crmUrl)
      ) {
        crmUrl = crmUrl.replace(match, String(conversation.optional_metadata[name]));
      }
    });
  return crmUrl;
};

export function getDatasetIdsFromParsedExpression(parsedExpression: ParsedExpression): number[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function traverse(expr: Expression | any): number[] {
    if (typeof expr !== "object" || expr === null) {
      return [];
    }

    if ("op_id" in expr) {
      if (
        expr.op_id === "EQ" &&
        expr.lhs?.expr?.field_id === "dataset_id" &&
        typeof expr.rhs?.expr === "number"
      ) {
        return [expr.rhs.expr];
      } else if (expr.op_id === "ANY" && Array.isArray(expr.expressions)) {
        return expr.expressions.flatMap(traverse);
      }
    }

    return Object.values(expr).flatMap(traverse);
  }

  return traverse(parsedExpression.parsed);
}

export function groupConversationMetadataFieldsByDisplayName(fields: ConversationMetadataField[]): {
  [key: string]: ConversationMetadataField[];
} {
  const uniqueDisplayNames = new Set(fields.map(field => field.displayName));
  return Array.from(uniqueDisplayNames).reduce(
    (acc: { [key: string]: ConversationMetadataField[] }, curr: string) => {
      const matchingFields = fields.filter(field => field.displayName === curr);
      acc[curr] = matchingFields;
      return acc;
    },
    {}
  );
}

/**
 * Calculate the rotation for a value along an arc.
 *
 * @param val the value between arcMinimum and arcMaximum to calculate a rotation position for
 * @param arcMinimum the minimum value along the arc
 * @param arcMaximum the maximum value along the arc
 * @param arcLengthInDegrees the total desired length of the arc in degrees
 * @param arcStartOffsetInDegrees the offset of the start of the arc in degrees. can be negative or positive. Negative
 * values will pull the start of the arc counterclockwise starting from "3 o'clock", positive will pull it clockwise.
 * @returns the position of val in degrees along the arc
 */
export const calculateRotationAlongArc = (
  val: number,
  arcMinimum: number,
  arcMaximum: number,
  arcLengthInDegrees: number,
  arcStartOffsetInDegrees: number
) => {
  const percentage = (val - arcMinimum) / (arcMaximum - arcMinimum);
  return arcStartOffsetInDegrees + percentage * arcLengthInDegrees;
};

/**
 * Create an SVG path string representing an arc segment to draw.
 *
 * @param start starting value of the segment to draw
 * @param end ending value of the segment to draw
 * @param arcMinimum the minimum value along the arc
 * @param arcMaximum the maximum value along the arc
 * @param arcLengthInDegrees the total desired length of the arc in degrees
 * @param arcStartOffsetInDegrees the offset of the start of the arc in degrees. can be negative or positive. Negative
 * values will pull the start of the arc counterclockwise starting from "3 o'clock", positive will pull it clockwise.
 * @param viewPortMaxX the maximum X axis value of the SVG viewport
 * @param viewPortMaxY the maximum Y axis value of the SVG viewport
 * @param meterRadius the desired radius of the arc to draw in pixels
 * @param segmentLengthOffset how much to reduce the length of the segment by in radians. Can be used to prevent overlap between segments
 * @returns an SVG path string representing the segment to draw
 */
export const drawArcSegment = (
  start: number,
  end: number,
  arcMinimum: number,
  arcMaximum: number,
  arcLengthInDegrees: number,
  arcStartOffsetInDegrees: number,
  viewPortMaxX: number,
  viewPortMaxY: number,
  meterRadius: number,
  segmentLengthOffset = 0.05
) => {
  const startAngle = calculateRotationAlongArc(
    start,
    arcMinimum,
    arcMaximum,
    arcLengthInDegrees,
    arcStartOffsetInDegrees
  );
  const endAngle = calculateRotationAlongArc(
    end,
    arcMinimum,
    arcMaximum,
    arcLengthInDegrees,
    arcStartOffsetInDegrees
  );

  const startRad = (startAngle * Math.PI) / 180;
  const endRad = (endAngle * Math.PI) / 180;

  const startPoint = {
    x: viewPortMaxX / 2 + meterRadius * Math.cos(startRad + segmentLengthOffset),
    y: viewPortMaxY / 2 + 25 + meterRadius * Math.sin(startRad + segmentLengthOffset),
  };
  const endPoint = {
    x: viewPortMaxX / 2 + meterRadius * Math.cos(endRad - segmentLengthOffset),
    y: viewPortMaxY / 2 + 25 + meterRadius * Math.sin(endRad - segmentLengthOffset),
  };

  return `M ${startPoint.x} ${startPoint.y} A ${meterRadius} ${meterRadius} 0 0 1 ${endPoint.x} ${endPoint.y}`;
};
