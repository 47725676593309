import { useAppSelector } from "@/hooks";
import { WidgetSettings } from "@/types/dashboardTypes";
import { ParsedExpression } from "@/types/expressionsDslTypes";
import moment from "moment";
import React from "react";
import MetricMeterWidget from "../../../components/MetricMeter";
import { ConversationVolumeWidget } from "./ConversationVolumeWidget";

export interface WidgetCardProps {
  metricsViewId: string;
  widgetSettings: WidgetSettings;
  startDate: string;
  endDate: string;
  filters?: ParsedExpression;
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
  metricsViewId,
  widgetSettings,
  startDate,
  endDate,
  filters,
}) => {
  const { metricsViewState } = useAppSelector(state => state.metricsView);
  if (!metricsViewState.data) return null;
  const { settings } = metricsViewState.data;
  const numDays = moment(settings.endDate).diff(moment(settings.startDate), "days");

  let widgetComponent;
  switch (widgetSettings.type) {
    case "conversation_volume": {
      widgetComponent = (
        <ConversationVolumeWidget
          metricsViewId={metricsViewId}
          startDate={startDate}
          endDate={endDate}
          widgetSettings={widgetSettings}
          filters={filters}
          numDays={numDays}
        />
      );
      break;
    }
    case "meter": {
      widgetComponent = (
        <MetricMeterWidget
          metricsViewId={metricsViewId}
          startDate={startDate}
          endDate={endDate}
          widgetSettings={widgetSettings}
          filters={filters}
          numDays={numDays}
        />
      );
      break;
    }
    default:
      console.error(`Unknown widget type`);
      widgetComponent = null;
      break;
  }

  return (
    <div className="widget-card">
      <span className="widget-card-title">{widgetSettings.title}</span>
      {widgetComponent}
    </div>
  );
};
