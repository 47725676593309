import React from "react";
import {
  Table,
  TableColumnsType,
  Typography,
  Space,
  Tooltip as AntTooltip,
} from "antd";
import { SelectOutlined } from "@ant-design/icons";
import moment from "moment";
import { ConversationMetadata } from "../../indexTypes";
import { renderCrmUrl } from "../../utils";

interface RenderTableProps {
  conversations: ConversationMetadata[];
  crmUrlTemplate?: string;
  metadataColumnsVisible?: string[];
  onRowClick?: (row: ConversationMetadata) => void;
}

export const RenderTable: React.FC<RenderTableProps> = ({
  conversations,
  crmUrlTemplate,
  metadataColumnsVisible = [],
  onRowClick,
}) => {
  const conversationMetadataFields = conversations.reduce((acc, curr) => {
    if (curr.optional_metadata) {
      Object.keys(curr.optional_metadata).forEach(f => acc.add(f));
    }
    return acc;
  }, new Set<string>());


  const defaultColumns: TableColumnsType<ConversationMetadata> = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: t => <span style={{fontSize:'1em'}}>{moment(t).format("MM-DD-YYYY HH:mm")}</span>,
      width: "150px",
    },
    {
      title: "User Story",
      dataIndex: "user_story",
      key: "user_story",
      render: us => <Typography.Text>{us}</Typography.Text>,
    },
    {
      title: "Dataset",
      dataIndex: "dataset_display_name",
      key: "dataset_name",
      render: (d, record: ConversationMetadata) => d ?? record.dataset_name,
      width: "100px",
    },
    ...(crmUrlTemplate ? [
      {
        title: "",
        dataIndex: "conversation_key",
        key: "conversation_key",
        render: (_: string, record: ConversationMetadata) =>
          (
            <div style={{ width: "20px" }}>
              <AntTooltip title={"View in CRM"}>
                <Typography.Link
                  target="_blank"
                  href={renderCrmUrl(crmUrlTemplate, record)}
                >
                  <Space>
                    <SelectOutlined rotate={90} />
                  </Space>
                </Typography.Link>
              </AntTooltip>
            </div>
          )          
      },
    ] : []),

  ];

  const metadataColumns: TableColumnsType<ConversationMetadata> = Array.from(
    conversationMetadataFields
  ).map(field => ({
    title: field,
    dataIndex: "optional_metadata",
    key: `metadata-${field}`,
    render: (m: Record<string, string | number | boolean | null>) => m[field],
  }));

  const visibleColumns = [
    ...defaultColumns,
    ...metadataColumns.filter(col => metadataColumnsVisible.includes(col.key as string)),
  ];

  return (
    <Table
      dataSource={conversations}
      rowKey={(conv: ConversationMetadata) => conv.conversation_id}
      locale={{ emptyText: "No conversations found" }}
      pagination={false}
      size="middle"
      scroll={{ x: true, y: 500 }}
      className="rounded"
      onRow={row => ({
        onClick: () => onRowClick?.(row),
      })}
      columns={visibleColumns}
    />
  );
};
