import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import React, {
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";

import { CustomerIndex, isAPIError } from "./indexTypes";
import { customerIndexes, getConversationMetadataFields } from "./reportApi";
import { ConversationMetadataField } from "./types/dashboardTypes";
import { Loadable, StreamingLoadable } from "./types/util";

export type CustomerUIModel = {
  id: string;
  index: CustomerIndex;
  conversationMetadataFields: ConversationMetadataField[];
};

export const isAdmin = (user: CognitoUser | null) => {
  const userPayload = user?.getSignInUserSession()?.getAccessToken().decodePayload();
  if (!userPayload || !userPayload["cognito:groups"]) {
    return false;
  }
  return userPayload["cognito:groups"].includes("admin") ?? false;
};

export const useCurrentUser = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    async function updateUser() {
      try {
        setUser(await Auth.currentAuthenticatedUser());
      } catch {
        setUser(null);
      }
    }
    Hub.listen("auth", updateUser);
    updateUser();
    return () => Hub.remove("auth", updateUser);
  }, []);

  return user;
};

export const useCustomerGroups = () => {
  const [customer, setCustomer] = useState<CustomerUIModel>();
  const [customers, setCustomers] = useState<CustomerUIModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentUser = useCurrentUser();
  const userId = currentUser?.getUsername();

  const unsetCustomer = useCallback(() => {
    setCustomer(undefined);
    localStorage.removeItem("chosenCustomerId");
  }, [setCustomer]);

  useEffect(() => {
    async function getJson() {
      setIsLoading(true);
      const indexes = await customerIndexes();
      const promises = indexes.map(async index => {
        const conversationMetadataFields = await getConversationMetadataFields(index.id);
        return { ...index, conversationMetadataFields };
      });
      const customers = await Promise.all(promises);

      setCustomers(customers);
      setIsLoading(false);
    }

    if (userId) {
      getJson();
    }
  }, [userId]);

  useEffect(() => {
    if (customers.length === 1) {
      setCustomer(customers[0]);
    }
  }, [customers]);

  return { customer, customers, setCustomer, unsetCustomer, isLoading };
};

type CustomerInfo = {
  customer: CustomerUIModel;
  customers: CustomerUIModel[];
  setCustomer: React.Dispatch<SetStateAction<CustomerUIModel | undefined>>;
  unsetCustomer: () => void;
};

// TODO refactor these into redux
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customerContext = createContext(undefined as any);
export const useCustomer = () => useContext(customerContext) as CustomerInfo;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface InfiniteScrollOptions {
  initialCount?: number;
  incrementBy?: number;
  rootMargin?: string;
  threshold?: number;
  isReady?: boolean;
}

export interface InfiniteScrollResult {
  visibleCount: number;
  sentinelRef: React.RefObject<HTMLDivElement>;
}

export const useInfiniteScroll = ({
  initialCount = 20,
  incrementBy = 20,
  isReady = true,
}: InfiniteScrollOptions = {}): InfiniteScrollResult => {
  const [visibleCount, setVisibleCount] = useState(initialCount);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isReady) {
      return;
    }
    if (!sentinelRef.current) {
      console.error("useInfiniteScroll: isReady is true but sentinelRef is not set");
      return;
    }

    observerRef.current = new IntersectionObserver(entries => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setVisibleCount(prev => prev + incrementBy);
      }
    });

    observerRef.current.observe(sentinelRef.current);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isReady, incrementBy]);

  return { visibleCount, sentinelRef };
};

export const useDataLoader = <T>(
  asyncFn: (abortController: AbortController) => Promise<T>,
  deps: React.DependencyList = []
): Loadable<T> => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedFn = useCallback(asyncFn, deps);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    const abortController = new AbortController();
    (async () => {
      try {
        const data = await wrappedFn(abortController);
        setData(data);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (abortController.signal.aborted) {
          return;
        }
        setError(error.message);
        setLoading(false);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [wrappedFn]);

  return { loading, data, error };
};

export const useStreamingDataLoader = <T>(
  asyncFn: (abortController: AbortController) => Promise<T | undefined>,
  deps: React.DependencyList = []
): StreamingLoadable => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string>();
  const [error, setError] = useState<string>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrappedFn = useCallback(asyncFn, deps);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    setData(undefined);
    const abortController = new AbortController();
    let reader = undefined;
    (async () => {
      try {
        const response = await wrappedFn(abortController);
        if (!response) {
          return;
        }

        if (!(response instanceof ReadableStream) && isAPIError(response)) {
          throw new Error(response.error);
        }

        if (!(response instanceof ReadableStream)) {
          throw new Error("Response is not a readable stream");
        }

        reader = response.getReader();

        let streamDone = false;
        let content = "";
        while (!streamDone) {
          const { done, value } = await reader.read();
          if (done) {
            streamDone = true;
          }
          content += new TextDecoder().decode(value);
          setData(content);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (abortController.signal.aborted) {
          return;
        }
        setError(error.message);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [wrappedFn]);

  return { loading, data, error };
};
