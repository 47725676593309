import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from '@/components/ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import {
  ChatStatus,
  initializeChat,
  selectChatStatus,
  selectChatViewId,
  selectDeepResearch,
  selectError,
  selectHasMessages,
  selectInputPlaceholder,
  selectMessages,
  sendMessage,
  setChatViewId,
} from '@/features/agentChat/agentChatSlice';
import { agentChatSlice } from '@/features/agentChat/agentChatSlice';
import { useCustomer } from '@/hooks';
import { SidebarItem, SidebarSubMenu } from '@/indexTypes';
import { AppDispatch, RootState } from '@/store';
import { ViewId } from '@/types/dashboardTypes';
import { isSidebarSubMenu, isSidebarViewItem } from '@/utils';
import { AlertCircle, Ban, BarChart3, Clock, Database, MessageCircle, Send, TrendingUp, Users } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageComponent } from './components/Message';
import TypingIndicator from './components/TypingIndicator';
import "./markdown.less";

interface ConversationStartersProps {
  onSelect: (message: string) => void;
}

const ConversationStarters: React.FC<ConversationStartersProps> = ({ onSelect }) => {
  const starters = [
    {
      icon: <BarChart3 className="h-6 w-6" />,
      title: "How are our support metrics trending?",
      description: "Get insights on ticket volume, response times, and resolution rates"
    },
    {
      icon: <Clock className="h-6 w-6" />,
      title: "Are we meeting our SLA targets?",
      description: "Check SLA compliance and identify bottlenecks in response times"
    },
    {
      icon: <Users className="h-6 w-6" />,
      title: "What do customers think about our support?",
      description: "Review CSAT scores and customer feedback trends"
    },
    {
      icon: <TrendingUp className="h-6 w-6" />,
      title: "How is the support team performing?",
      description: "Analyze agent productivity and workload distribution"
    },
    {
      icon: <MessageCircle className="h-6 w-6" />,
      title: "What are our most common support issues?",
      description: "Identify frequently reported problems and their solutions"
    },
    {
      icon: <Ban className="h-6 w-6" />,
      title: "Where are tickets getting stuck?",
      description: "Analyze bottlenecks and delays in the support process"
    }
  ];

  return (
    <div className="flex-1 flex items-center justify-center">
      <div className="max-w-4xl w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        {starters.map((starter, index) => (
          <Card 
            key={index}
            className="p-4 hover:bg-gray-50 cursor-pointer transition-colors"
            onClick={() => onSelect(starter.title)}
          >
            <div className="flex items-start gap-4">
              <div className="text-blue-500">
                {starter.icon}
              </div>
              <div>
                <h3 className="font-medium mb-2">{starter.title}</h3>
                <p className="text-sm text-gray-500">{starter.description}</p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const StatusIndicator: React.FC<{ status: ChatStatus }> = ({ status }) => {
  const error = useSelector(selectError);
  
  switch (status) {
    case ChatStatus.ERROR:
      return (
        <div className="mb-4 p-2 bg-red-100 text-red-700 rounded flex items-center gap-2">
          <AlertCircle className="h-4 w-4" />
          <span>{error}</span>
        </div>
      );
    case ChatStatus.SENDING:
    case ChatStatus.WAITING_FOR_AGENT:
      return <TypingIndicator />;
    default:
      return null;
  }
};

interface ViewOption {
  viewId: ViewId;
  displayName: string;
}

const traverseHierarchy = (hierarchy: (SidebarSubMenu | SidebarItem)[], allViewIds: ViewOption[]) => {
  hierarchy.forEach(item => {
    if (isSidebarSubMenu(item)) {
      traverseHierarchy(item.items, allViewIds);
    } else if (isSidebarViewItem(item) && item.displayName !== "All") {
      allViewIds.push({viewId: item.viewId, displayName: item.displayName});
    }
  });
};

interface AgentChatViewSelectProps {
  reportSetHierarchy: (SidebarItem | SidebarSubMenu)[];
}

const AgentChatViewSelect: React.FC<AgentChatViewSelectProps> = ({ reportSetHierarchy }) => {
  const allViewOptions: ViewOption[] = [];
  traverseHierarchy(reportSetHierarchy, allViewOptions);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="flex-1 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Select a View to Chat With</h2>
        <Select onValueChange={(value: ViewId) => dispatch(setChatViewId(value))}>
          <SelectTrigger className="w-full mb-4">
            <SelectValue placeholder="--" />
          </SelectTrigger>
          <SelectContent>
            {allViewOptions.map((viewOption) => (
              <SelectItem key={viewOption.viewId} value={viewOption.viewId}>
                {viewOption.displayName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

interface MessageListProps {
  viewId: ViewId;
}

const MessageList: React.FC<MessageListProps> = ({ viewId }) => {
  const messages = useSelector(selectMessages);
  const hasMessages = useSelector(selectHasMessages);
  const dispatch = useDispatch<AppDispatch>();
  const { customer } = useCustomer();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleStarterSelect = async (message: string) => {
    await dispatch(initializeChat({
      customerId: customer.id,
      viewId: viewId,
      initialMessage: message
    }));
  };

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const shouldScroll = 
        scrollElement.scrollHeight - scrollElement.scrollTop - scrollElement.clientHeight < 100;
      
      if (shouldScroll) {
        scrollElement.scrollTo({
          top: scrollElement.scrollHeight,
          behavior: 'smooth'
        });
      }
    }
  }, [messages]);

  if (!hasMessages) {
    return <ConversationStarters onSelect={handleStarterSelect} />;
  }

  return (
    <ScrollArea className="flex-1 pr-4" ref={scrollRef}>
      <div className="space-y-4 p-4">
        {messages.map((message, index) => (
          <MessageComponent key={index} message={message} />
        ))}
      </div>
    </ScrollArea>
  );
};

interface MessageInputProps {
  viewId: ViewId | null;
}

const MessageInput: React.FC<MessageInputProps> = ({ viewId }) => {
  const [newMessage, setNewMessage] = useState('');
  
  // Get deepResearch from Redux instead of local state
  const deepResearch = useSelector(selectDeepResearch);
  
  const handleDeepResearchToggle = () => {
    // Update the Redux state
    dispatch(agentChatSlice.actions.setDeepResearch(!deepResearch));
  };
  const dispatch = useDispatch<AppDispatch>();
  const { customer } = useCustomer();
  const status = useSelector(selectChatStatus);
  const placeholder = useSelector(selectInputPlaceholder);
  const chatId = useSelector((state: RootState) => state.agentChat.id);
  const canSend = status === ChatStatus.READY && viewId && newMessage.trim().length > 0;

  const handleSend = async () => {
    if (!canSend) return;
    
    const message = newMessage.trim();
    setNewMessage('');
    
    if (!chatId) {
      await dispatch(initializeChat({
        customerId: customer.id,
        viewId: viewId,
        initialMessage: message
      }));
    } else {
      await dispatch(sendMessage({ 
        customerId: customer.id,
        message
      }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="pt-4">
      <div className="flex gap-2 relative">
        <div className="flex-grow relative">
          <Input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder={placeholder}
            className="pl-32" // Added left padding to make room for badges
          />
          <div className="absolute left-2 top-1/2 transform -translate-y-1/2 flex items-center gap-1">
            {/* Deep Research badge with tooltip */}
            <div 
              onClick={handleDeepResearchToggle}
              className={`flex items-center gap-1 px-2 py-0.5 rounded-full text-xs cursor-pointer transition-colors group relative
                ${deepResearch 
                  ? 'bg-blue-100 text-blue-700 border border-blue-300 hover:bg-blue-200' 
                  : 'bg-gray-100 text-gray-500 border border-gray-200 hover:bg-gray-200'}
              `}
              role="button"
              aria-pressed={deepResearch}
              aria-label="Toggle deep research mode"
            >
              <TrendingUp className="h-3 w-3" />
              <span>Deep research</span>
              
              {/* Using data attribute for tooltip for simplicity */}
              <div 
                className="absolute -top-20 left-0 bg-black text-white text-xs p-2 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none z-50 whitespace-normal max-w-[240px]" 
                style={{ width: 'max-content' }}
              >
                Enable deeper, more comprehensive research for complex queries
                <div className="absolute w-0 h-0 border-l-4 border-l-transparent border-r-4 border-r-transparent border-t-4 border-t-black left-4 -bottom-1"></div>
              </div>
            </div>
          </div>
        </div>
        <Button 
          onClick={handleSend}
          disabled={!canSend}
          size="icon"
        >
          <Send className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

interface AgentChatProps {
  reportSetHierarchy: (SidebarItem | SidebarSubMenu)[];
};

export const AgentChat: React.FC<AgentChatProps> = ({ reportSetHierarchy }) => {
  const status = useSelector(selectChatStatus);
  const selectedViewId = useSelector(selectChatViewId);
  const dispatch = useDispatch<AppDispatch>();
  
  const allViewOptions: ViewOption[] = [];
  traverseHierarchy(reportSetHierarchy, allViewOptions);

  // Set a default view when component mounts if no view is selected
  useEffect(() => {
    if (!selectedViewId && allViewOptions.length > 0) {
      dispatch(setChatViewId(allViewOptions[0].viewId));
    }
  }, [dispatch, selectedViewId, allViewOptions]);

  return (
    <div className="h-full w-full flex flex-col relative">
      <div className="absolute top-4 left-4 z-10">
        <Select 
          value={selectedViewId || undefined} 
          onValueChange={(value: ViewId) => dispatch(setChatViewId(value))}
        >
          <SelectTrigger className="bg-transparent border-0 hover:bg-gray-200 dark:hover:bg-gray-600 rounded text-gray-700 dark:text-gray-300 w-auto px-3 py-2 font-semibold text-base flex items-center gap-2 focus:ring-0 focus:ring-offset-0 focus:outline-none transition-colors duration-200">
            <Database className="h-4 w-4" />
            <SelectValue placeholder="Select a view" />
          </SelectTrigger>
          <SelectContent>
            {allViewOptions.map((viewOption) => (
              <SelectItem key={viewOption.viewId} value={viewOption.viewId}>
                {viewOption.displayName}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="flex-1 flex flex-col min-h-0">
        {selectedViewId && <MessageList viewId={selectedViewId}/>}
        <div className="px-4">
          <StatusIndicator status={status} />
          <MessageInput viewId={selectedViewId} />
        </div>
      </div>
    </div>
  );
};
