import { Button, Col, Collapse, Row, Spin } from "antd";
import React from "react";

import { useCustomer, useDataLoader } from "../../../hooks";
import { TaxonomyNodeFilters } from "../../../indexTypes";
import "../Dashboard.less";
import { getTaxonomyNode, getTaxonomyNodeConversations } from "../../../reportApi";
import {
  MetadataAnalysisOptions,
  NodeVisibilityReason,
  TaxonomyNodeData,
  TaxonomyNodeItem,
  VisibleTaxonomyNodeItem,
} from "../../../types/dashboardTypes";
import { ParsedExpression } from "../../../types/expressionsDslTypes";
import { ConversationMetadataTable } from "../../../components/ConversationMetadataTable";
import { HighlightedText } from "../../../components/HighlightedText";
import { MetadataAnalysisWidget } from "../../../components/MetadataAnalysisWidget";
import { TaxonomyNodeHeader } from "./TaxonomyNodeHeader";
import { TimeseriesChart } from "../../../components/TimeseriesChart";

const MAX_EXAMPLES = 500;

interface NodeConversationsProps {
  taxonomyId: string;
  taxonomyNode: TaxonomyNodeItem;
  startDate: string;
  endDate: string;
  viewId: string;
  filters?: ParsedExpression;
}
const NodeConversations: React.FC<NodeConversationsProps> = ({
  taxonomyId,
  taxonomyNode,
  startDate,
  endDate,
  viewId,
  filters,
}: NodeConversationsProps) => {
  const [numExamples, setNumExamples] = React.useState(10);
  const { customer } = useCustomer();
  const loadable = useDataLoader(
    async abortController => {
      const context = {
        startDate,
        endDate,
        viewId,
        optionalFiltersExpression: filters,
        // TODO: Determine how and when we want to render metadata.
        includeMetadata: true,
        limit: numExamples,
      };
      const conversations = await getTaxonomyNodeConversations(
        taxonomyId,
        taxonomyNode.id,
        customer.id,
        context,
        abortController.signal
      );
      return conversations;
    },
    [taxonomyId, taxonomyNode.id, customer.id, startDate, endDate, viewId, filters, numExamples]
  );

  return (
    <Spin
      spinning={loadable.loading}
      tip="Loading examples..."
      style={{ minHeight: "80px", alignSelf: "center" }}
    >
      {loadable.data && (
        <div>
          <ConversationMetadataTable
            fetchConversations={async () => loadable.data?.conversations ?? []}
          />
          <Button
            onClick={() => setNumExamples(numExamples + 10)}
            disabled={loadable.loading || numExamples >= MAX_EXAMPLES}
            loading={loadable.loading}
            className="rounded"
            style={{ margin: "10px auto", display: "block" }}
          >
            {loadable.data.conversations.length >= MAX_EXAMPLES
              ? `Max of ${MAX_EXAMPLES} examples shown`
              : "Load more"}
          </Button>
        </div>
      )}
    </Spin>
  );
};

interface TaxonomyNodeProps {
  taxonomyNode: VisibleTaxonomyNodeItem;
  parentId?: string;
  granularity: string;
  taxonomyId: string;
  startDate: string;
  endDate: string;
  viewId: string;
  metadataAnalysisOptions: MetadataAnalysisOptions;
  metadataAnalysisChoice: string;
  filters?: ParsedExpression;
  taxonomyNodeFilters?: TaxonomyNodeFilters;
  exactMatchedSubstring: string;
}

export const TaxonomyNode: React.FC<TaxonomyNodeProps> = ({
  taxonomyNode,
  parentId = undefined,
  granularity,
  taxonomyId,
  startDate,
  endDate,
  viewId,
  metadataAnalysisOptions,
  metadataAnalysisChoice,
  filters,
  taxonomyNodeFilters,
  exactMatchedSubstring,
}: TaxonomyNodeProps) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const { customer } = useCustomer();
  const loadable = useDataLoader(
    async (abortController): Promise<TaxonomyNodeData> => {
      const taxonomyNodeData = await getTaxonomyNode(
        taxonomyId,
        taxonomyNode.id,
        viewId,
        customer.id,
        {
          startDate,
          endDate,
          granularity,
          filters,
          taxonomyNodeFilters,
        },
        abortController.signal
      );
      return taxonomyNodeData;
    },
    [
      taxonomyId,
      taxonomyNode.id,
      viewId,
      customer.id,
      startDate,
      endDate,
      granularity,
      filters,
      taxonomyNodeFilters,
    ]
  );

  const isLeafNode = taxonomyNode.children.length === 0;

  const childIds = taxonomyNode.children.map(child => child.id);
  const grandchildCount = taxonomyNode.children.reduce(
    (acc, child) => acc + child.children.length,
    0
  );
  const name = taxonomyNode.resolvedName;
  const taxonomyNodeData = loadable.data;

  return (
    <div>
      <Collapse
        bordered={false}
        destroyInactivePanel
        onChange={activeKey => {
          setIsExpanded(activeKey.length > 0);
        }}
        collapsible={loadable.loading ? "disabled" : undefined}
      >
        <Collapse.Panel
          key={taxonomyNode.id}
          header={
            <TaxonomyNodeHeader
              taxonomyNodeId={taxonomyNode.id}
              name={(() => {
                // Highlight exact matches in the node's name if one is found.
                const nodeVisibilityReason = taxonomyNode.visibilityReason;
                if (
                  exactMatchedSubstring &&
                  nodeVisibilityReason === NodeVisibilityReason.SEARCH_EXACT_MATCH
                ) {
                  return (
                    <HighlightedText text={name} highlightedSubstring={exactMatchedSubstring} />
                  );
                } else if (nodeVisibilityReason === NodeVisibilityReason.SEARCH_SEMANTIC_MATCH) {
                  return <HighlightedText text={name} tooltip="Spiral AI-powered match" />;
                }
                return name;
              })()}
              total={taxonomyNode.count}
              taxonomyNodeData={loadable}
              granularity={granularity}
              startDate={startDate}
              endDate={endDate}
              parentId={parentId}
              nodeChildren={childIds}
              nodeGrandchildrenCount={grandchildCount}
            />
          }
          className={isLeafNode ? "subissue" : "superissue"}
        >
          <Collapse bordered={false} destroyInactivePanel>
            <Row className="metadata" style={{ padding: "24px", marginLeft: "0" }}>
              {taxonomyNodeData && (
                <Row>
                  <Col span={16}>
                    <Spin spinning={loadable.loading}>
                      <TimeseriesChart
                        metadata={taxonomyNodeData.percentCounts}
                        secondaryMetadata={taxonomyNodeData.rawCounts}
                        graphheight={320}
                        xaxis
                        yaxis
                        grid
                        animationDuration={150}
                        preserve={"preserveEnd"}
                        xTickFormat={"MMM D"}
                        showTooltip
                      />
                    </Spin>
                  </Col>
                  <Col span={8}>
                    <MetadataAnalysisWidget
                      chartTitle={metadataAnalysisOptions[metadataAnalysisChoice].display_name}
                      type="bar"
                      viewId={viewId}
                      startDate={startDate}
                      endDate={endDate}
                      metadataAnalysisChoice={metadataAnalysisChoice}
                      taxonomyNodeId={taxonomyNode.id}
                      filters={filters}
                    />
                  </Col>
                </Row>
              )}
              {isLeafNode && isExpanded && (
                <NodeConversations
                  taxonomyId={taxonomyId}
                  taxonomyNode={taxonomyNode}
                  startDate={startDate}
                  endDate={endDate}
                  viewId={viewId}
                  filters={filters}
                />
              )}
            </Row>
            <div>
              {taxonomyNode.children.map(childNode => (
                <TaxonomyNode
                  key={childNode.id}
                  taxonomyNode={childNode}
                  viewId={viewId}
                  parentId={taxonomyNode.id}
                  granularity={granularity}
                  taxonomyId={taxonomyId}
                  startDate={startDate}
                  endDate={endDate}
                  metadataAnalysisOptions={metadataAnalysisOptions}
                  metadataAnalysisChoice={metadataAnalysisChoice}
                  filters={filters}
                  exactMatchedSubstring={exactMatchedSubstring}
                />
              ))}
            </div>
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};
