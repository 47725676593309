import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ConversationVolumeWidgetSettings } from "../../../types/dashboardTypes";
import { formatPercent } from "../../../utils";

import { useCustomer, useDataLoader } from "@/hooks";
import { getConversationVolumeWidgetData } from "@/reportApi";
import { ParsedExpression } from "@/types/expressionsDslTypes";
import { Spin } from "antd";
import "./ConversationVolumeWidget.less";
import { IssueList } from "./IssueList";

interface ConversationVolumeWidgetProps {
  metricsViewId: string;
  startDate: string;
  endDate: string;
  widgetSettings: ConversationVolumeWidgetSettings;
  filters?: ParsedExpression;
  numDays?: number;
}

export const ConversationVolumeWidget: React.FC<ConversationVolumeWidgetProps> = ({
  metricsViewId,
  startDate,
  endDate,
  widgetSettings,
  filters,
  numDays,
}) => {
  const { customer } = useCustomer();
  const loadable = useDataLoader(
    async abortController => {
      const widgetData = await getConversationVolumeWidgetData(
        customer.id,
        metricsViewId,
        widgetSettings.id,
        startDate,
        endDate,
        filters,
        { sentiment: ["negative"] }, // we only ever want negative sentiment issues here
        abortController.signal
      );
      return widgetData;
    },
    [metricsViewId, widgetSettings, startDate, endDate, filters]
  );

  const currentTotal = loadable.data?.overallData.currentValues.reduce(
    (sum, dataPoint) => sum + dataPoint.count,
    0
  );
  const trailingTotal = loadable.data?.overallData.trailingValues.reduce(
    (sum, dataPoint) => sum + dataPoint.count,
    0
  );
  const percentChange =
    currentTotal && trailingTotal ? (currentTotal - trailingTotal) / trailingTotal : 0.0;
  const changeTextClassSuffix = percentChange > 0 ? "positive" : "negative";

  return (
    <Spin spinning={loadable.loading} style={{ height: "100%" }}>
      <div className="conversation-volume-widget-container">
        {currentTotal && trailingTotal && numDays && (
          <div className="overall-volume-stats">
            <div className="volume-stats-container">
              <div className="overall-volume-text">
                {currentTotal && currentTotal.toLocaleString()}
              </div>
              conversations
            </div>
            <div className="volume-stats-container">
              <div className={`change-text-background-${changeTextClassSuffix}`}>
                <span className={`change-text-${changeTextClassSuffix}`}>
                  {percentChange && percentChange > 0 ? "+" : ""}
                  {formatPercent(percentChange, 1)}
                </span>
              </div>
              <span className="change-text-time-period">over {numDays} days</span>
            </div>
          </div>
        )}
        <div className="chart-area">
          <ResponsiveContainer>
            <AreaChart data={loadable.data?.overallData.currentValues}>
              <XAxis
                dataKey="day"
                axisLine={false}
                tickLine={false}
                interval={"equidistantPreserveStart"}
                allowDuplicatedCategory={false}
              >
                <Label value="Day #" position={"bottom"} />
              </XAxis>
              <YAxis axisLine={false} tickLine={false} tickCount={5} minTickGap={5} />
              <CartesianGrid stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />
              <Legend wrapperStyle={{ paddingTop: "25px" }} />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#465FC3"
                fill="#465FC3"
                fillOpacity={0.1}
                strokeWidth={2}
                dot={false}
                name="Current"
                data={loadable.data?.overallData.currentValues}
                animationDuration={300}
                // enable connectNulls because sometimes we're behind if the current window ends in the last day or two
                connectNulls={true}
              />
              <Area
                type="monotone"
                dataKey="count"
                stroke="#C2DB8A"
                fill="#C2DB8A"
                fillOpacity={0.1}
                strokeWidth={2}
                dot={false}
                name="Trailing"
                data={loadable.data?.overallData.trailingValues}
                animationDuration={300}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <IssueList issueList={loadable.data?.issueData || []} numIssuesToShow={3} />
      </div>
    </Spin>
  );
};
