import React, { useState } from "react";
import {
  Col,
  Divider,
  Row,
  Spin,
} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { ConversationMetadata } from "../../indexTypes";
import { ConversationModal } from "../ConversationModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useDataLoader } from "../../hooks";
import { RenderTable } from "./RenderTable";

interface ConversationMetadataTableProps {
  fetchConversations: () => Promise<ConversationMetadata[]>;
}

export const ConversationMetadataTable: React.FC<ConversationMetadataTableProps> = ({
  fetchConversations,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [conversationMetadata, setConversationMetadata] = useState<
    ConversationMetadata | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const state = useAppSelector(state => state.dashboard);
  const dashboardContext = { state, dispatch };
  
  const defaultSelectedMetadata =
    dashboardContext.state.viewState.data?.view.settings.defaultSelectedMetadata;
  const [metadataColumnsVisible, setMetadataColumnsVisible] = useState<string[]>(
    defaultSelectedMetadata ?? []
  );

  const loadable = useDataLoader(
    async () => {
      return await fetchConversations();
    },
    [fetchConversations]
  );

  const conversations = loadable.data;

  if (!conversations) {
    return (
      <div>
        <Spin tip="Loading conversations..." style={{ minHeight: "80px", alignSelf: "center" }} />
      </div>
    );
  }

  const crmUrlTemplate = dashboardContext.state.viewState.data?.view.settings.crmUrlTemplate;

  const conversationMetadataFields = conversations.reduce((acc, curr) => {
    if (curr.optional_metadata) {
      Object.keys(curr.optional_metadata).forEach(f => acc.add(f));
    }
    return acc;
  }, new Set<string>());

  return (
    <div>
      {conversationMetadata && (
        <ConversationModal
          visible={modalVisible}
          setVisible={setModalVisible}
          conversationMetadata={conversationMetadata}
        />
      )}
      <Row style={{ margin: "10px 0" }}>
        <Divider>Conversations</Divider>
        <Col>
          {[...conversationMetadataFields].map(field => (
            <CheckableTag
              key={`metadata-${field}`}
              checked={metadataColumnsVisible.includes(`metadata-${field}`)}
              onChange={checked => {
                if (checked) {
                  setMetadataColumnsVisible([...metadataColumnsVisible, `metadata-${field}`]);
                } else {
                  setMetadataColumnsVisible(metadataColumnsVisible.filter(col => col !== `metadata-${field}`));
                }
              }}
              className={`select-metadata-cols ${
                metadataColumnsVisible.includes(`metadata-${field}`) ? "selected" : ""
              }`}
            >
              {field}
            </CheckableTag>
          ))}
        </Col>
      </Row>
      <RenderTable
        conversations={conversations}
        crmUrlTemplate={crmUrlTemplate}
        metadataColumnsVisible={metadataColumnsVisible}
        onRowClick={row => {
          setConversationMetadata(row);
          setModalVisible(true);
        }}
      />
    </div>
  );
};
