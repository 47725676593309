import {
  ArrowRightOutlined,
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  DislikeFilled,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  DislikeOutlined,
  EditOutlined,
  LikeFilled,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  LikeOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  SaveFilled,
  SearchOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Input,
  InputRef,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  List,
  Modal,
  Popconfirm,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  Progress,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
  notification
} from "antd";
import { InputStatus } from "antd/lib/_util/statusUtils";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { invalidateCloudFrontLogoURI, uploadStaticAsset } from "../../demoApi";
import typesjson from "../../gen/generatedTypes.json";
import { useCustomer } from "../../hooks";
import {
  Customer,
  CustomerIndex,
  ReportAPIResp,
  ReportIndex,
  ReportIndexAudit,
  Tier,
  TypeDocInfo,
  isAPIError,
  tiers,
} from "../../indexTypes";
import {
  Cluster,
  ClustersResponse,
  Judgement,
  annotateEvidence,
  createCluster,
  createReport,
  deleteCluster,
  deleteCustomer,
  deleteReport,
  editClusterTrainingData,
  getCustomerClusters,
  getCustomerClustersExamples,
  getCustomers,
  getExample,
  getReportIndexAudit,
  getReports,
  getTitlesFiles,
  mergeClusters,
  renameReport,
  retrainClassifier,
  searchSimilar,
  setClusterTitle,
  setReportTitles,
  updateCustomerDomains,
  updateCustomerIndex,
  updateCustomerTier,
  updateReportIndex,
} from "../../reportApi";
import { Titles } from "../../reports";
import { isDefined, truncate } from "../../utils";
import "./CustomerManager.less";
import IntegrationsPage from "./IntegrationsPage";
import { TitlesPage } from "./TitlesPage";

const { RangePicker } = DatePicker;

const fetchExample = async (
  type: string,
  field: string,
  setExamples: React.Dispatch<React.SetStateAction<{ [key: string]: string } | undefined>>,
  examples?: { [key: string]: string }
) => {
  try {
    const resp = await getExample(type, field);
    if (isAPIError(resp)) {
      const newExamples = { ...examples, [field]: `ERROR: ${resp.description}` };
      setExamples(newExamples);
    } else {
      const newExamples = { ...examples, [field]: JSON.stringify(resp, null, 2) };
      setExamples(newExamples);
    }
  } catch (error) {
    const newExamples = { ...examples, [field]: `ERROR: ${error}` };
    setExamples(newExamples);
  }
};

const UploadableCustomerImage = ({
  customer,
  customerIndex,
  setCustomerIndexText,
}: {
  customer: Customer;
  customerIndex: CustomerIndex;
  setCustomerIndexText: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [imgsrc, setImgSrc] = useState<string>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    customer;
    setImgSrc(`https://static.spiralup.co/${customer.customerIndexJson.logo}`);
  }, [customer, customerIndex]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadCustomerLogo = async (options: any) => {
    const { onSuccess, onError, file } = options;
    setLoading(true);
    const dest = `${customer.id}/logo.png`;
    try {
      uploadStaticAsset(file, dest);
      invalidateCloudFrontLogoURI(dest);
    } catch (e) {
      notification.open({ message: `Failure saving logo!`, duration: 5 });
      onError(e);
      setLoading(false);
      return;
    }
    notification.open({ message: `logo saved to s3!`, duration: 5 });
    onSuccess("success");

    customerIndex.logo = dest;
    setCustomerIndexText(JSON.stringify(customerIndex, null, 2));
    await updateCustomerIndex(customer.id, customerIndex);

    setLoading(false);
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={uploadCustomerLogo}
    >
      <Spin spinning={loading}>
        <img src={imgsrc} alt="logo" style={{ width: 80, maxWidth: 80, maxHeight: 40 }} />
      </Spin>
    </Upload>
  );
};

function getDifference(o1: object, o2: object) {
  const added: Array<keyof object> = [];
  const removed: Array<keyof object> = [];
  const changed: Array<keyof object> = [];

  (Object.keys(o1) as Array<keyof object>).forEach(k => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (k in o1 && !(k in o2)) {
      added.push(k);
    } else if (JSON.stringify(o1[k]) !== JSON.stringify(o2[k])) {
      changed.push(k);
    }
  });
  (Object.keys(o2) as Array<keyof object>).forEach(k => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(k in o1) && k in o2) {
      removed.push(k);
    }
  });
  const result = { added: "", changed: "", removed: "" };
  if (added.length) {
    result.added = JSON.stringify(added, null, 2);
  }
  if (changed.length) {
    result.changed = JSON.stringify(changed, null, 2);
  }
  if (removed.length) {
    result.removed = JSON.stringify(removed, null, 2);
  }

  return result;
}

const criticalFields = ["datasets", "baseQueryFilters"];
const filtersFields = ["filters"];
const analyzeByFields = ["defaultAnayzeBy", "analyzeByLimit", "analyzeByOptions"];

const format = (obj: object) => JSON.stringify(obj, null, 2);

export const ReportEditor = ({
  customer,
  reports,
  setReports,
}: {
  customer?: Customer;
  reports?: ReportAPIResp;
  setReports: Dispatch<SetStateAction<ReportAPIResp | undefined>>;
}) => {
  const [selectedReportIndex, setSelectedReportIndex] = useState<string>();
  const [reportIndexText, setReportIndexText] = useState<string>();
  const [reportIndex, setReportIndex] = useState<ReportIndex>();
  const [oldreportIndex, setOldReportIndex] = useState<ReportIndex>();

  const [reportIndexTextVaild, setReportIndexTextValid] = useState(false);

  const [filtersText, setFiltersText] = useState<string>();
  const [analyzeByText, setAnalyzeByText] = useState<string>();
  const [criticalText, setCriticalText] = useState<string>();
  const [mainFieldsText, setMainFieldsText] = useState<string>();

  const [reportIndexLoading, setReportIndexLoading] = useState(false);

  const [newReportName, setNewReportName] = useState<string>();
  const [newReportStatus, setNewReportStatus] = useState<InputStatus>("");

  const [reportName, setReportName] = useState<string>();
  const [titlesId, setTitlesId] = useState<string>();

  const [titlesFiles, setTitlesFiles] = useState<{ [id: string]: TitlesFile }>();

  const [badProps, setBadProps] = useState<string[]>([]);
  const [jsonDelta, setJsonDelta] = useState<{ added: string; changed: string; removed: string }>();
  const [reportAudit, setReportAudit] = useState<ReportIndexAudit[]>();
  const [examples, setExamples] = useState<{ [key: string]: string }>();

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const f = async () => {
      if (customer) {
        setTitlesFiles(await getTitlesFiles(customer.id));
      }
    };
    if (customer) {
      setSelectedReportIndex(customer.customerIndexJson.defaultReportSet);
    }
    f();
  }, [customer]);

  useEffect(() => {
    if (reportIndex && oldreportIndex) {
      setJsonDelta(getDifference(reportIndex, oldreportIndex));
    } else {
      setJsonDelta(undefined);
    }
  }, [reportIndex, oldreportIndex]);

  const indexProps = typesjson.children.find((t: TypeDocInfo) => t.name === "ReportIndex")?.type?.declaration
    ?.children as TypeDocInfo[];

  const reloadReports = useCallback(async () => {
    if (customer && reports && selectedReportIndex && selectedReportIndex in reports) {
      const rptIdx = reports[selectedReportIndex].reportIndexJson;
      setReportName(reports[selectedReportIndex].name);
      setTitlesId(reports[selectedReportIndex].titleId.toString());
      setReportIndex(rptIdx);
      setOldReportIndex(rptIdx);
      setReportIndexText(format(rptIdx));
      setMainFieldsText(
        format(_.omit(rptIdx, ...[...criticalFields, ...analyzeByFields, ...filtersFields]))
      );
      setFiltersText(format(_.pick(rptIdx, ...filtersFields)));
      setAnalyzeByText(format(_.pick(rptIdx, ...analyzeByFields)));
      setCriticalText(format(_.pick(rptIdx, ...criticalFields)));
      setReportIndexTextValid(true);
    }
  }, [customer, reports, selectedReportIndex]);

  useEffect(() => {
    if (reportIndex) {
      const badStuff: string[] = [];
      for (const prop in reportIndex) {
        if (!indexProps.some(p => p.name == prop)) {
          badStuff.push(prop);
        }
      }
      setBadProps(badStuff);
    }
  }, [reportIndex, indexProps]);

  // Load the content of the report editor
  useEffect(() => {
    const controller = new AbortController();
    const f = async () => {
      setReportIndexLoading(true);
      try {
        reloadReports();
      } finally {
        if (!controller.signal.aborted) {
          setReportIndexLoading(false);
        }
      }
    };
    f();
    return () => controller.abort();
  }, [reports, selectedReportIndex, customer, reloadReports]);

  useEffect(() => {
    if (!selectedReportIndex || !reports || !(selectedReportIndex in reports)) {
      return;
    }
    const controller = new AbortController();
    const f = async () => {
      const resp = await getReportIndexAudit(controller.signal, reports[selectedReportIndex].id);
      if (isAPIError(resp)) {
        return;
      }
      setReportAudit(resp.auditLog);
    };

    f();
    return () => controller.abort();
  }, [reports, selectedReportIndex]);

  const saveReportIndex = async () => {
    if (!customer || !reports || !selectedReportIndex || !reportIndex || !reportIndexText) {
      return;
    }
    try {
      JSON.parse(reportIndexText);
    } catch {
      notification.error({
        message: `Invalid JSON. Not saving!`,
        duration: 5,
      });
      return;
    }
    setReportIndexLoading(true);
    try {
      const resp = await updateReportIndex(reports[selectedReportIndex].id, reportIndex);
      if (isAPIError(resp)) {
        notification.error({
          message: `Failed to save!! ${resp.description}`,
          duration: 5,
        });
        return;
      }

      reports[selectedReportIndex].reportIndexJson = resp;
      setOldReportIndex(resp);

      notification.success({
        message: `Saved!`,
        duration: 1,
      });
    } catch {
      notification.error({
        message: `Failed to save!!`,
        duration: 5,
      });
    } finally {
      setReportIndexLoading(false);
    }
  };

  const onRenameReport = async () => {
    if (selectedReportIndex && reports && reportName) {
      await renameReport(reports[selectedReportIndex].id, reportName);
      window.location.reload();
    }
  };

  const onChangeTitles = async () => {
    if (selectedReportIndex && reports && titlesId) {
      await setReportTitles(reports[selectedReportIndex].id, titlesId);
      window.location.reload();
    }
  };

  // Reflects valid (parsable) changes from the user edited text to the real ReportIndex object. Makes sure that the
  // real object doesn't contain invalid json.
  useEffect(() => {
    try {
      setReportIndex({
        ...JSON.parse(mainFieldsText ?? "{}"),
        ...JSON.parse(analyzeByText ?? "{}"),
        ...JSON.parse(filtersText ?? "{}"),
        ...JSON.parse(criticalText ?? "{}"),
      });
      setReportIndexTextValid(true);
    } catch (error) {
      console.error(error);
      setReportIndexTextValid(false);
    }
  }, [mainFieldsText, analyzeByText, filtersText, criticalText]);

  const addNewReportIndex = async () => {
    if (!customer || !reports) {
      console.error("Customer or reports not set. Cannot create a reportIndex.");
      return;
    }
    if (!newReportName) {
      setNewReportStatus("error");
      return;
    }
    setNewReportStatus("");

    const newReport: ReportIndex = {
      period: "week",
      periodLength: 12,
      latestLength: 1,
      datasets: [{ uuid: "TODO" }],
      overviewLegendText: "REPLACE ME",
      datePicker: {
        amount: 12,
        unit: "week",
        snapTo: {
          direction: "end",
          unit: "week",
        },
      },
      filters: {},
      areaCurveType: "basis",
      minTickGap: 100,
      xTickFormat: "D MMM 'YY",
      showDateButtons: false,
    };

    try {
      const resp = await createReport(customer.id, newReportName, newReport);
      if (isAPIError(resp)) {
        console.error(resp.error, resp.description);
        notification.error({
          className: "selectable",
          message: `Failure creating report! ${resp.description}`,
          duration: 10,
        });
        return;
      }
      notification.open({
        message: `Report created!`,
        duration: 5,
      });

      setReports({ ...reports, ...{ [resp.urlHash]: resp } });
      setSelectedReportIndex(resp.urlHash);
      setNewReportName(undefined);
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Report API failure! ${err}`,
        className: "selectable",
        duration: 10,
      });
    }
  };

  const deleteReportIndex = async (reportSetId: string) => {
    if (!customer) return;
    setReportIndexLoading(true);
    try {
      await deleteReport(customer.id, reportSetId);
      setSelectedReportIndex(undefined);
      const resp = await getReports(customer.id);
      setReports(resp);
      if (reportSetId !== customer.customerIndexJson.defaultReportSet) {
        setSelectedReportIndex(customer.customerIndexJson.defaultReportSet);
      } else {
        setSelectedReportIndex(Object.keys(resp).filter(k => k !== reportSetId)[0]);
      }
      notification.success({ message: "Report deleted!" });
    } catch {
      notification.error({ message: "Failed to delete report!" });
    } finally {
      setReportIndexLoading(false);
    }
  };

  return (
    <>
      <Row key="cm-editor" className="selectable" gutter={32}>
        <Col span={12}>
          <h3>Report Set Editor</h3>
          <Row style={{ margin: "0 0 12px" }} justify="space-between">
            <Col>
              <Space>
                <Input.Group compact>
                  <Input
                    style={{ width: "calc(100% - 100px)" }}
                    placeholder="report_name"
                    allowClear={true}
                    status={newReportStatus}
                    value={newReportName}
                    onChange={e => setNewReportName(e.target.value)}
                  />
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => addNewReportIndex()}
                    disabled={!newReportName || newReportName.length === 0 || !reports}
                  >
                    New
                  </Button>
                </Input.Group>
              </Space>
            </Col>
          </Row>
          <Row style={{ margin: "0 0 12px" }}>
            <Col>
              {reports && (
                <Select
                  showSearch
                  style={{ width: 350 }}
                  value={selectedReportIndex}
                  options={Object.entries(reports).map(([key, val]) => ({
                    value: key,
                    label: val.name,
                  }))}
                  onChange={value => {
                    setSelectedReportIndex(value);
                  }}
                  filterOption={(input, option) =>
                    isDefined(option) && option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              )}
            </Col>
          </Row>
          {reportIndex && (
            <>
              <Row style={{ margin: "0 0 12px" }} justify="space-between">
                <Col>
                  <Space>
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 120px)" }}
                        allowClear={true}
                        value={reportName}
                        onChange={e => setReportName(e.target.value)}
                      />
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        disabled={
                          !(
                            selectedReportIndex &&
                            reports &&
                            selectedReportIndex in reports &&
                            reportName !== reports[selectedReportIndex].name
                          )
                        }
                        onClick={() => onRenameReport()}
                      >
                        Rename
                      </Button>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }} justify="space-between">
                <Col>
                  <Space>
                    <Input.Group compact>
                      <Input
                        style={{ width: "calc(100% - 160px)" }}
                        allowClear={true}
                        value={titlesId}
                        onChange={e => setTitlesId(e.target.value)}
                      />
                      <Button
                        icon={<EditOutlined />}
                        type="primary"
                        disabled={
                          !(
                            selectedReportIndex &&
                            reports &&
                            selectedReportIndex in reports &&
                            titlesId &&
                            titlesId !== reports[selectedReportIndex].titleId.toString() &&
                            titlesFiles &&
                            titlesId in titlesFiles
                          )
                        }
                        onClick={() => onChangeTitles()}
                      >
                        Change Titles
                      </Button>
                    </Input.Group>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Space size="large">
                    <Space>
                      <Typography.Text>Report Set Id:</Typography.Text>
                      <Typography.Text code copyable>
                        {selectedReportIndex}
                      </Typography.Text>
                    </Space>
                    <Space>
                      <Typography.Text>Titles Id:</Typography.Text>
                      <Typography.Text code copyable>
                        {reports &&
                          selectedReportIndex &&
                          selectedReportIndex in reports &&
                          reports[selectedReportIndex].titleId}
                      </Typography.Text>{" "}
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Popconfirm
                    zIndex={10000}
                    title={
                      <>
                        <div>
                          Are you super duper sure you want to <b>permanently</b> delete this
                          report?
                        </div>
                        <br />
                        <div>
                          <strong>
                            {reports && selectedReportIndex
                              ? `${reports[selectedReportIndex].id} - ${reports[selectedReportIndex].name}`
                              : ""}
                          </strong>
                        </div>
                        <br />
                      </>
                    }
                    popupVisible={showDeleteConfirm}
                    onConfirm={() => {
                      if (reports && selectedReportIndex) {
                        deleteReportIndex(reports[selectedReportIndex].id);
                        setShowDeleteConfirm(false);
                      }
                    }}
                    okText="Delete"
                    okType="danger"
                    onCancel={() => setShowDeleteConfirm(false)}
                  >
                    <Button
                      danger
                      icon={<DeleteFilled />}
                      onClick={() => setShowDeleteConfirm(true)}
                      disabled={!reports || !selectedReportIndex}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Spin spinning={reportIndexLoading}>
                    <span>Main Fields:</span>
                    <Input.TextArea
                      value={mainFieldsText}
                      onChange={e => {
                        setMainFieldsText(e.target.value);
                      }}
                      autoSize={{ minRows: 10, maxRows: 40 }}
                    />
                    <span>Analyze By:</span>
                    <Input.TextArea
                      value={analyzeByText}
                      onChange={e => {
                        setAnalyzeByText(e.target.value);
                      }}
                      autoSize={{ minRows: 5, maxRows: 20 }}
                    />
                    <span>Filters:</span>
                    <Input.TextArea
                      value={filtersText}
                      onChange={e => {
                        setFiltersText(e.target.value);
                      }}
                      autoSize={{ minRows: 5, maxRows: 20 }}
                    />
                    <span>Danger Zone:</span>
                    <Input.TextArea
                      value={criticalText}
                      onChange={e => {
                        setCriticalText(e.target.value);
                      }}
                      autoSize={{ minRows: 10, maxRows: 40 }}
                      status="error"
                    />
                  </Spin>
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col span={12}>
          <div style={{ position: "sticky", top: "60px" }}>
            <h3>
              Report Validator:{" "}
              {reports &&
                selectedReportIndex &&
                selectedReportIndex in reports &&
                reports[selectedReportIndex].name}
            </h3>
            {badProps.length ? (
              <>
                <Divider orientation="left">Unknown Properties</Divider>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
                  {JSON.stringify(badProps, null, 2)}
                </pre>
              </>
            ) : null}
            {jsonDelta && (jsonDelta.added || jsonDelta.changed || jsonDelta.removed) ? (
              <>
                <Divider orientation="left">Diff</Divider>
                <h4>Added</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "green" }}>
                  {jsonDelta.added}
                </pre>
                <h4>Changed</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "orange" }}>
                  {jsonDelta.changed}
                </pre>
                <h4>Removed</h4>
                <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
                  {jsonDelta.removed}
                </pre>
              </>
            ) : null}
            <Button
              icon={<SaveFilled />}
              type="primary"
              onClick={() => saveReportIndex()}
              disabled={!reportIndexTextVaild}
            >
              Save
            </Button>
            <Divider orientation="left">Titles</Divider>
            <pre>
              {titlesFiles &&
                Object.keys(titlesFiles)
                  .sort((a, b) => titlesFiles[a].name.localeCompare(titlesFiles[b].name))
                  .map(k => "\n" + titlesFiles[k].name + ": " + k)}{" "}
            </pre>
            <Divider orientation="left">Documentation</Divider>
            <div className={"admin-text-box admin-editor-col"}>
              {indexProps.map(prop => (
                <div style={{ paddingTop: "6px" }} key={"type-" + prop.name}>
                  <h3>
                    {prop.name}{" "}
                    <Tooltip title="search">
                      <Button
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        onClick={() => fetchExample("report", prop.name!, setExamples, examples)}
                        shape="circle"
                        icon={<SearchOutlined />}
                        size="small"
                      />
                    </Tooltip>
                  </h3>
                  {prop.comment && (
                    <div
                      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", padding: "0 0.5em" }}
                    >
                      {prop.comment.summary?.map(s => s.text)}
                    </div>
                  )}
                  {examples && prop.name && prop.name in examples && (
                    <pre>{examples[prop.name]}</pre>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <Collapse>
        <Collapse.Panel key="audit-top" header="Audit Log">
          <Collapse>
            {reportAudit && reportAudit.length > 0
              ? reportAudit.map(r => {
                  return (
                    <Collapse.Panel
                      className="selectable"
                      key={`audit-${r.id}`}
                      header={
                        <>
                          <Link to={"../../users/" + r.updatedBy}>User: {r.userName}</Link>
                          <span> - updated at: {r.updatedAt} </span>
                        </>
                      }
                    >
                      <Typography.Text copyable>
                        <pre>{JSON.stringify(r.reportIndexJson)}</pre>
                      </Typography.Text>
                    </Collapse.Panel>
                  );
                })
              : null}
          </Collapse>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

const EditableTagList = ({
  customer,
  title,
}: {
  customer: Customer,
  title: string,
}) => {
  const [listItems, setListItems] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [input, setInput] = useState<string>();
  const [inputStatus, setInputStatus] = useState<"" | "error" | "warning" | undefined>();
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (customer) {
      setListItems(customer.domains);
    }
  }, [customer]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newStatus = new RegExp(/^\S{2,}\.\S{2,}$/).test(value) ? "" : "error";
    setInputStatus(newStatus)
    setInput(value);
  }

  const handleInputConfirm = async () => {
    if (inputStatus === "error") {
      notification.error({message: "Invalid domain", duration: 10});
      return;
    }
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (customer && input && !listItems.includes(input)) {
      const domains = [...listItems, input];
// eslint-disable-next-line @typescript-eslint/no-use-before-define
      await handleUpdateList(domains)
    }
    setInputVisible(false);
    setInput('');
  }

  const handleBlur = async () => {
    if (!input) {
      setInputVisible(false)
    } else {
      await handleInputConfirm()
    }
  }

  const handleRemoveItem = async (item: string) => {
    const items = listItems.filter((val) => val !== item)
// eslint-disable-next-line @typescript-eslint/no-use-before-define
    await handleUpdateList(items)
  }

  const handleUpdateList = async (domains: string[]) => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!customer) {
      return;
    }
    setListItems(domains)
    const resp = await updateCustomerDomains(customer.id, domains);
    if (isAPIError(resp)) {
      notification.error({
        message: `Failed to update domains! ${resp.description}`,
        duration: 10,
      });
      return;
    }
    setListItems(resp.domains)
  }

  const newItem = (
    inputVisible ? (
      <Input
        ref={inputRef}
        type="text"
        size="small"
        style={{ width: 150, verticalAlign: "top" }}
        value={input}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onPressEnter={handleInputConfirm}
        status={inputStatus}
      />
    ) : (
      <Tag style={{borderStyle: "dashed"}} icon={<PlusOutlined />} onClick={showInput}>
        New Item
      </Tag>
    )
  )

  const renderTagList = listItems.map((item) => (
    <Tag
      key={item}
      closable
      style={{ userSelect: 'none'}}
      onClose={() => handleRemoveItem(item)}
    >
      {item}
    </Tag>
  ))

  return (
    <Row style={{ margin: "0 0 12px" }}>
      <Col style={{ marginRight: "5px"}}>
        <Typography.Text strong>{title}</Typography.Text>
      </Col>
      <Col>
        {renderTagList}
        {newItem}
      </Col>
    </Row>
  )
}

export const CustomerEditor = ({
  customer,
  updateCustomerState,
  reports,
}: {
  customer?: Customer;
  updateCustomerState: (id: string, index: CustomerIndex) => void;
  reports?: ReportAPIResp;
}) => {
  const [customerIndexLoading, setCustomerIndexLoading] = useState(false);
  const [customerIndexText, setCustomerIndexText] = useState<string>();
  const [newCustomerTier, setNewCustomerTier] = useState<Tier>();
  const [customerIndex, setCustomerIndex] = useState<CustomerIndex>();
  const [oldCustomerIndex, setOldCustomerIndex] = useState<CustomerIndex>();
  const [badProps, setBadProps] = useState<string[]>([]);
  const [jsonDelta, setJsonDelta] = useState<{ added: string; changed: string; removed: string }>();
  const [examples, setExamples] = useState<{ [key: string]: string }>();

  useEffect(() => {
    if (customer) {
      setCustomerIndex(customer.customerIndexJson);
      setOldCustomerIndex(customer.customerIndexJson);
      setCustomerIndexText(JSON.stringify(customer.customerIndexJson, null, 2));
    }
  }, [customer]);

  // Reflects valid (parsable) changes from the user edited text to the real CustomerIndex object. Makes sure that the
  // real object doesn't contain invalid json.
  useEffect(() => {
    if (customerIndexText) {
      try {
        setCustomerIndex(JSON.parse(customerIndexText));
      } catch (error) {
        setJsonDelta(undefined);
      }
    }
  }, [customerIndexText]);

  useEffect(() => {
    if (customerIndex && oldCustomerIndex) {
      setJsonDelta(getDifference(customerIndex, oldCustomerIndex));
    } else {
      setJsonDelta(undefined);
    }
  }, [customerIndex, oldCustomerIndex]);

  const saveCustomerIndexAndTier = async () => {
    if (!customer || !customerIndex || !customerIndexText) return;
    try {
      JSON.parse(customerIndexText);
    } catch {
      notification.error({
        message: `Invalid JSON. Not saving!`,
        duration: 5,
      });
      return;
    }

    // Validate updated tier (i.e. prevent downgrades)
    if (newCustomerTier !== undefined && customer.tier != newCustomerTier) {
      if (tiers.indexOf(customer.tier) > tiers.indexOf(newCustomerTier)) {
        notification.error({
          message: `Cannot downgrade tier!`,
          duration: 5,
        });
        return;
      } else {
        try {
          const resp = await updateCustomerTier(customer.id, newCustomerTier);
          if (isAPIError(resp)) {
            notification.error({
              message: `Failed to update customer tier! ${resp.description}`,
              duration: 5,
            });
            return;
          }
        } catch (err) {
          console.error(err);
          notification.error({
            message: `Failed to update customer tier!`,
            duration: 5,
          });
          return;
        }
      }
    }

    setCustomerIndexLoading(true);
    try {
      const resp = await updateCustomerIndex(customer.id, customerIndex);
      if (isAPIError(resp)) {
        notification.error({
          message: `Failed to save!! ${resp.description}`,
          duration: 5,
        });
        return;
      }
      updateCustomerState(customer.id, resp);
      setOldCustomerIndex(resp);
      notification.success({
        message: `Saved!`,
        duration: 1,
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: `Failed to save!!`,
        duration: 5,
      });
    } finally {
      setCustomerIndexLoading(false);
    }
  };

  const indexProps = typesjson.children.find((t: TypeDocInfo) => t.name === "CustomerIndex")?.type?.declaration
    ?.children as TypeDocInfo[];

  useEffect(() => {
    if (customerIndex) {
      const badStuff: string[] = [];
      for (const prop in customerIndex) {
        if (!indexProps.some(p => p.name == prop)) {
          badStuff.push(prop);
        }
      }
      setBadProps(badStuff);
    }
  }, [customerIndex, indexProps]);

  return (
    <Row key="cm-editor" className="selectable" gutter={32}>
      <Col span={12}>
        <h3>Customer Editor</h3>
        <Spin spinning={!customer || customerIndexLoading}>
          {customer && customerIndex && (
            <>
              <Row style={{ margin: "0 0 15px" }} justify="space-between">
                <Col>
                  <Space>
                    <UploadableCustomerImage
                      customer={customer}
                      customerIndex={customerIndex}
                      setCustomerIndexText={setCustomerIndexText}
                    />
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 15px" }}>
                <Col>
                  <Space>
                    <Typography.Text strong>Customer Tier:</Typography.Text>
                    <Select
                      style={{ width: 150 }}
                      showSearch
                      options={[...tiers.map(t => ({ value: t, label: t }))]}
                      defaultValue={tiers.find(t => t == customer.tier) || undefined}
                      onChange={value => {
                        setNewCustomerTier(value);
                      }}
                    />
                  </Space>
                </Col>
              </Row>
              <Row style={{ margin: "0 0 12px" }}>
                <Col>
                  <Space>
                    <Typography.Text strong>Customer ID:</Typography.Text>
                    <Typography.Text code copyable>
                      {customer.id}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
              <EditableTagList customer={customer} title="Customer Domains:"/>
              <Spin spinning={customerIndexText === undefined}>
                {customerIndexText && (
                  <Input.TextArea
                    value={customerIndexText}
                    onChange={e => {
                      setCustomerIndexText(e.target.value);
                    }}
                    autoSize={{ minRows: 10, maxRows: 40 }}
                  />
                )}
              </Spin>
            </>
          )}
        </Spin>
      </Col>
      <Col span={12}>
        <h3>Customer Validator</h3>
        {badProps.length ? (
          <>
            <Divider orientation="left">Unknown Properties</Divider>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
              {JSON.stringify(badProps, null, 2)}
            </pre>
          </>
        ) : null}
        <Button icon={<SaveFilled />} type="primary" onClick={() => saveCustomerIndexAndTier()}>
          Save
        </Button>
        {customer && newCustomerTier && customer.tier != newCustomerTier && (
          <>
            <Row>
              <Divider orientation="left">Tier Change</Divider>
            </Row>
            <Row style={{ color: "orange" }}>
              <Space>
                {customer.tier}
                <ArrowRightOutlined />
                {newCustomerTier}
              </Space>
            </Row>
          </>
        )}
        {jsonDelta && (jsonDelta.added || jsonDelta.changed || jsonDelta.removed) ? (
          <>
            <Divider orientation="left">Diff</Divider>
            <h4>Added</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "green" }}>
              {jsonDelta.added}
            </pre>
            <h4>Changed</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "orange" }}>
              {jsonDelta.changed}
            </pre>
            <h4>Removed</h4>
            <pre className={"admin-text-box admin-editor-col"} style={{ color: "red" }}>
              {jsonDelta.removed}
            </pre>
          </>
        ) : null}
        <Divider orientation="left">Reports</Divider>
        <pre>
          {reports &&
            Object.keys(reports)
              .sort((a, b) => reports[a].name.localeCompare(reports[b].name))
              .map(k => "\n" + reports[k].name + ": " + k)}{" "}
        </pre>
        <Divider orientation="left">Documentation</Divider>
        <div className={"admin-text-box admin-editor-col"}>
          {indexProps.map(prop => (
            <div style={{ paddingTop: "6px" }} key={"type-" + prop.name}>
              <h3>
                {prop.name}{" "}
                <Tooltip title="search">
                  <Button
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => fetchExample("customer", prop.name!, setExamples, examples)}
                    shape="circle"
                    icon={<SearchOutlined />}
                    size="small"
                  />
                </Tooltip>
              </h3>
              {prop.comment && (
                <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  &ensp;{prop.comment.summary?.map(s => s.text).join("<br/>")}{" "}
                </div>
              )}
              {examples && prop.name && prop.name in examples && <pre>{examples[prop.name]}</pre>}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

interface TitlesFile {
  id: string;
  name: string;
  titles_json: Titles;
  customer_id: string;
}

const TitlesFileTab = ({ customer, reports }: { customer?: Customer; reports?: ReportAPIResp }) => {
  const [titlesFiles, setTitlesFiles] = useState<{ [id: string]: TitlesFile }>();
  const [titlesFileId, setTitlesFileId] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const loadTitlesFiles = useCallback(async () => {
    if (!customer) return;
    setIsLoading(true);
    try {
      const titles = await getTitlesFiles(customer.id);
      setTitlesFiles(titles);
    } finally {
      setIsLoading(false);
    }
  }, [customer]);

  useEffect(() => {
    if (!customer || !reports) return;
    loadTitlesFiles();
    if (customer.customerIndexJson.defaultReportSet in reports) {
      setTitlesFileId(reports[customer.customerIndexJson.defaultReportSet].titleId);
    }
  }, [customer, loadTitlesFiles, reports]);

  if (!customer || !reports) return null;

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ margin: "0 0 24px" }}>
          <Col>
            <Radio.Group
              value={titlesFileId}
              defaultValue={titlesFileId}
              size="large"
              onChange={e => setTitlesFileId(e.target.value)}
            >
              {titlesFiles &&
                Object.entries(titlesFiles).map(([key, report]) => (
                  <Radio.Button key={key} value={key}>
                    {`${report.name}:${key}`}
                  </Radio.Button>
                ))}
            </Radio.Group>
          </Col>
        </Row>
        {titlesFileId && (
          <TitlesPage
            titlesFileId={titlesFileId}
            allTitlesFiles={
              titlesFiles
                ? Object.keys(titlesFiles).map(key => ({ id: key, name: titlesFiles[key].name }))
                : []
            }
            reload={loadTitlesFiles}
          />
        )}
      </Spin>
    </>
  );
};

const ClusterDeleteButton = ({
  cluster,
  onConfirm,
}: {
  cluster: Cluster;
  onConfirm: () => Promise<void>;
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <Popconfirm
      title={
        <>
          <div>Are you sure you want to permanently delete this cluster?</div>
          <br />
          <div>
            <strong>
              {cluster.id} - {cluster.title ?? cluster.defaultTitle}
            </strong>
          </div>
          <br />
          <div>
            This should be done <b>ONLY</b> if the cluster is <b>truly unsalvageable</b> and should
            be deleted
            <br />
            from the model entirely. For clusters that are not useful, but are still valid semantic
            <br />
            intents, we should leave them in the model and simply disable them in the titles.
          </div>
          <br />
          <div>
            <small>
              Note: this may take a little while to complete. The page will update when it&apos;s
              done.
            </small>
          </div>
        </>
      }
      zIndex={10000}
      open={showConfirm}
      okText="Delete"
      onConfirm={() => {
        const f = async () => {
          setLoading(true);
          try {
            window.scrollTo(0, 0);
            await onConfirm();
          } finally {
            setLoading(false);
            setShowConfirm(false);
          }
        };
        f();
      }}
      okButtonProps={{ loading }}
      onCancel={() => setShowConfirm(false)}
    >
      <Button
        danger
        style={{ float: "right" }}
        icon={<DeleteFilled />}
        onClick={() => setShowConfirm(true)}
      >
        Delete
      </Button>
    </Popconfirm>
  );
};

const AnnotationWidget = ({
  clusteredTextId,
  clusterId,
}: {
  clusteredTextId: string;
  clusterId: string;
}) => {
  const [annotation, setAnnotation] = useState<Judgement>();
  const [annotationLoading, setAnnotationLoading] = useState(false);
  const annotationClick = (type: Judgement) => {
    setAnnotationLoading(true);
    const newAnnotation =
      type === "positive"
        ? annotation === "positive"
          ? undefined
          : "positive"
        : annotation === "negative"
        ? undefined
        : "negative";
    annotateEvidence(clusteredTextId, newAnnotation)
      .then(r => {
        setAnnotation(r.annotation.judgement);
      })
      .catch(() =>
        notification.error({
          message: "Failed to annotate evidence",
        })
      )
      .finally(() => {
        setAnnotationLoading(false);
      });
  };

  const graphBlue = "#1890ff";
  const grey = "#bfbfbf";

  return (
    <Space>
      <Tooltip title="Evidence is Correctly Classified">
        <Button
          style={
            annotation === "positive"
              ? { visibility: "visible", color: graphBlue }
              : { color: grey }
          }
          icon={<LikeFilled />}
          type="text"
          disabled={clusterId === "_is_interesting"}
          loading={annotationLoading}
          onClick={e => {
            annotationClick("positive");
            e.stopPropagation();
          }}
          ghost
        />
      </Tooltip>
      <span style={{ color: grey }}>/</span>
      <Tooltip title="Evidence is Incorrectly Classified">
        <Button
          style={
            annotation === "negative"
              ? { visibility: "visible", color: graphBlue }
              : { color: grey }
          }
          icon={<DislikeFilled />}
          loading={annotationLoading}
          type="text"
          onClick={e => {
            annotationClick("negative");
            e.stopPropagation();
          }}
        />
      </Tooltip>
    </Space>
  );
};

const AddTrainingDataWidget = ({
  setJudgement,
  annotation,
  allowThumbsUp = true,
}: {
  setJudgement: (label?: Judgement) => void;
  annotation?: Judgement;
  allowThumbsUp?: boolean;
}) => {
  const graphBlue = "#1890ff";
  const grey = "#bfbfbf";

  return (
    <Space>
      <Tooltip title="Add Evidence as Positive to Training Data">
        <Button
          style={
            annotation === "positive"
              ? { visibility: "visible", color: graphBlue }
              : { color: grey }
          }
          icon={<LikeFilled />}
          type="text"
          onClick={e => {
            if (annotation === undefined) {
              setJudgement("positive");
            } else {
              setJudgement(undefined);
            }
            e.stopPropagation();
          }}
          ghost
          disabled={!allowThumbsUp}
        />
      </Tooltip>
      <span style={{ color: grey }}>/</span>
      <Tooltip title="Add Evidence as Negative to Training Data">
        <Button
          style={
            annotation === "negative"
              ? { visibility: "visible", color: graphBlue }
              : { color: grey }
          }
          icon={<DislikeFilled />}
          type="text"
          onClick={e => {
            if (annotation === undefined) {
              setJudgement("negative");
            } else {
              setJudgement(undefined);
            }
            e.stopPropagation();
          }}
        />
      </Tooltip>
    </Space>
  );
};

const ExploreSimilarData = ({
  cluster_id,
  setJudgement,
  trainingData: maybeTrainingData,
}: {
  cluster_id: string;
  trainingData?: { positive: { text: string }[]; negative: { text: string }[] };
  setJudgement: ({ text, judgement }: { text: string; judgement?: Judgement }) => void;
}) => {
  const defaultTrainingData = { positive: [], negative: [] };
  const trainingData = maybeTrainingData || defaultTrainingData;

  type Action =
    | { type: "set"; text: string[] }
    | { type: "setLabel"; text: string; label?: Judgement };
  type DataPoint = { text: string; judgement: Judgement | undefined };
  const [dataPoints, reducer] = useReducer((state: DataPoint[], action: Action) => {
    switch (action.type) {
      case "set":
        return action.text.map(t => ({
          text: t,
          judgement: undefined,
        }));
      case "setLabel":
        return state.map(dp =>
          dp.text === action.text ? { ...dp, judgement: action.label, loading: false } : dp
        );
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [searchMode, setSearchMode] = useState<"positives" | "hard_positives" | "hard_negatives">(
    "positives"
  );

  const doSearch = async () => {
    setIsLoading(true);
    try {
      const resp = await searchSimilar(
        trainingData.positive.map(t => t.text),
        trainingData.negative.map(t => t.text),
        searchMode,
        {}
      );
      reducer({ type: "set", text: resp.results });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doSearch();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster_id, searchMode]);

  const labelPoint = (text: string) => (label?: Judgement) => {
    setJudgement({ text, judgement: label });
    reducer({ type: "setLabel", text, label });
  };

  return (
    <>
      <Row justify={"space-between"} style={{ padding: "16px 0" }}>
        <Col>
          <h3>Explore Similar Data</h3>
        </Col>
        <Col>
          <Space>
            <Select
              value={searchMode}
              onChange={setSearchMode}
              style={{ width: 200 }}
              disabled={isLoading}
            >
              <Select.Option value="positives">Top Examples</Select.Option>
              <Select.Option value="hard_positives">Hard Positives</Select.Option>
              <Select.Option value="hard_negatives">Hard Negatives</Select.Option>
            </Select>
            <Button icon={<SearchOutlined />} type="primary" loading={isLoading} onClick={doSearch}>
              Search
            </Button>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin spinning={isLoading}>
            <Table dataSource={dataPoints} pagination={false} scroll={{ y: 440 }} size="small">
              <Table.Column title="Text" dataIndex="text" key="text" />
              <Table.Column
                title="Annotate"
                dataIndex="annotate"
                key="annotate"
                width={"200px"}
                render={(_, record: DataPoint) => (
                  <AddTrainingDataWidget
                    key={record.text}
                    annotation={record.judgement}
                    setJudgement={labelPoint(record.text)}
                    allowThumbsUp={cluster_id !== "_is_interesting"}
                  />
                )}
              />
            </Table>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

const ClustersTab = ({ customer }: { customer: Customer }) => {
  const [clusters, setClusters] = useState<ClustersResponse["clusters"]>([]);
  const [training_data, setTrainingData] = useState<ClustersResponse["training_data"]>({});
  const [totalInScope, setTotalInScope] = useState<ClustersResponse["total"]>(1);
  const [counts, setCounts] = useState<ClustersResponse["counts"]>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [exploreMode, setExploreMode] = useState(true);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isRetraining, setIsRetraining] = useState(false);
  const [clustersToMerge, setClustersToMerge] = useState<{ id: string; text: string }[]>([]);
  const [editMode, setEditMode] = useState<"create" | "edit" | "">("");
  const [newTrainingDataPos, setNewTrainingDataPos] = useState<string[]>([]);
  const [newTrainingDataNeg, setNewTrainingDataNeg] = useState<string[]>([]);
  const [clusterIdToEdit, setClusterIdToEdit] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRetrainModalVisible, setIsRetrainModalVisible] = useState(false);
  const [retrainBackfillDateRange, setRetrainBackfillDateRange] = useState<string[]>([]);
  const [activeCluster, setActiveCluster] = useState<string>();
  const [activeClusterExamples, setActiveClusterExamples] = useState<
    { id: string; text: string; probability: number; clustered_text_id: string }[]
  >([]);
  const [isLoadingExamples, setIsLoadingExamples] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showHighProbability, setShowHighProbability] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const [localClusterTitle, setLocalClusterTitle] = useState<string>("");
  const [sortMode, setSortMode] = useState<"Volume" | "Date">("Date");
  const showPopconfirm = () => {
    setShowConfirm(true);
  };
  const handleCancel = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    const title = clusters.find(c => c.id === clusterIdToEdit)?.title;
    setLocalClusterTitle(title || "");
  }, [clusterIdToEdit, clusters]);

  useEffect(() => {
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (training_data[clusterIdToEdit] === undefined) {
      setTrainingData({
        ...training_data,
        [clusterIdToEdit]: {
          positive: [],
          negative: [],
        },
      });
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [training_data[clusterIdToEdit]]);

  useEffect(() => {
    const f = async () => {
      setIsLoadingExamples(true);
      try {
        setChangesMade(false);
        const resp = await getCustomerClustersExamples(
          customer.id,
          activeCluster,
          showHighProbability
        );
        setActiveClusterExamples(resp.examples);
      } finally {
        setIsLoadingExamples(false);
      }
    };

    if (activeCluster) {
      f();
    } else {
      setActiveClusterExamples([]);
    }
  }, [customer, activeCluster, showHighProbability]);

  useEffect(() => {
    const f = async () => {
      setIsLoading(true);
      const response = await getCustomerClusters(customer.id);
      setClusters(response.clusters);
      setTrainingData(response.training_data);
      setCounts(response.counts);
      setTotalInScope(response.total);
      setIsLoading(false);
    };
    f();
  }, [customer]);

  const invokeMergeClusters = async () => {
    setIsLoading(true);
    try {
      const mergeResp = await mergeClusters(
        customer.id,
        clustersToMerge.map(c => c.id)
      );
      if (isAPIError(mergeResp)) {
        console.error("Error merging clusters", mergeResp);
        throw new Error(mergeResp.description);
      }
      const getResponse = await getCustomerClusters(customer.id);
      setClusters(getResponse.clusters);
      setTrainingData(getResponse.training_data);
    } finally {
      setIsLoading(false);
      setIsMerging(false);
      setClustersToMerge([]);
    }
  };

  const invokeCreateCluster = async () => {
    setIsLoading(true);
    try {
      const createResp = await createCluster(
        customer.id,
        {
          positive: newTrainingDataPos.filter(t => t.trim() !== ""),
          negative: newTrainingDataNeg.filter(t => t.trim() !== ""),
        },
        localClusterTitle
      );
      if (isAPIError(createResp)) {
        console.error("Error creating cluster", createResp);
        throw new Error(createResp.description);
      }
      const getResponse = await getCustomerClusters(customer.id);
      setClusters(getResponse.clusters);
      setTrainingData(getResponse.training_data);
      return createResp.cluster_id;
    } finally {
      setIsLoading(false);
      setNewTrainingDataPos([]);
      setNewTrainingDataNeg([]);
    }
  };

  const invokeEditCluster = async (
    clusterIdToEdit: string,
    training_data_pos: { id: string; text: string }[],
    training_data_neg: { id: string; text: string }[]
  ) => {
    setIsLoading(true);
    try {
      await setClusterTitle(clusterIdToEdit, localClusterTitle);
      const editResp = await editClusterTrainingData(customer.id, clusterIdToEdit, {
        positive: training_data_pos.map(t => t.text).filter(t => t.trim() !== ""),
        negative: training_data_neg.map(t => t.text).filter(t => t.trim() !== ""),
      });
      if (isAPIError(editResp)) {
        console.error("Error editing cluster", editResp);
        throw new Error(editResp.description);
      }
      const getResponse = await getCustomerClusters(customer.id);
      setClusters(getResponse.clusters);
      setTrainingData(getResponse.training_data);
    } finally {
      setIsLoading(false);
    }
  };

  const invokeDeleteCluster = async (clusterIdToDelete: string) => {
    setIsLoading(true);
    try {
      const deleteResp = await deleteCluster(clusterIdToDelete);
      if (isAPIError(deleteResp)) {
        console.error("Error deleting cluster", deleteResp);
        throw new Error(deleteResp.description);
      }
      setActiveCluster(undefined);
      const getResponse = await getCustomerClusters(customer.id);
      setClusters(getResponse.clusters);
      setTrainingData(getResponse.training_data);
    } finally {
      setIsLoading(false);
    }
  };

  const invokeRetrainAndBackfill = async (
    customer_id: string,
    backfill_start_date: string,
    backfill_end_date: string
  ) => {
    setIsLoading(true);
    try {
      const retrainResp = await retrainClassifier(
        customer_id,
        backfill_start_date,
        backfill_end_date
      );
      if (isAPIError(retrainResp)) {
        console.error("Error retraining classifier", retrainResp);
        throw new Error(retrainResp.description);
      }
    } finally {
      setIsLoading(false);
    }
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getTrainingData = useCallback(() => {
    return training_data[clusterIdToEdit];
  }, [clusterIdToEdit, training_data]);

  return (
    <>
      <Spin spinning={isLoading}>
        <Row style={{ margin: "0 0 24px" }}>
          <Col span={24}>
            <Space>
              <Tooltip title="Merge two or more clusters" mouseEnterDelay={0.5} zIndex={10000}>
                <Button
                  type="primary"
                  icon={<MergeCellsOutlined />}
                  onClick={() => {
                    setIsMerging(!isMerging);
                    setClustersToMerge([]);
                  }}
                  disabled={clusterIdToEdit as unknown as boolean}
                >
                  Merge
                </Button>
              </Tooltip>
              <Tooltip
                title="Create a cluster using training data that you provide"
                mouseEnterDelay={0.5}
                zIndex={10000}
              >
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={isMerging}
                  onClick={() => {
                    setEditMode("create");
                    setIsModalVisible(true);
                  }}
                >
                  Create
                </Button>
              </Tooltip>
              <Tooltip
                title="Use this once you've made some edits to this customer's clusters! Retraining the classifier
                       and backfilling their data will take some time"
                mouseEnterDelay={0.5}
                zIndex={10000}
              >
                <Button
                  type="primary"
                  icon={<SmileOutlined />}
                  // disabled={isRetraining}
                  onClick={() => {
                    setIsRetrainModalVisible(true);
                  }}
                >
                  Retrain Classifier
                </Button>
              </Tooltip>
              <Modal
                title="Retrain Classifier and Backfill"
                width={"25%"}
                style={{ marginLeft: "43%", marginTop: "7%" }}
                footer={[
                  <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={async () => {
                      setIsRetraining(true);
                      invokeRetrainAndBackfill(
                        customer.id,
                        retrainBackfillDateRange[0],
                        retrainBackfillDateRange[1]
                      )
                        .then(() => {
                          notification.open({
                            message: "Classifier retraining successfully started",
                            duration: 5,
                          });
                        })
                        .catch(() => {
                          notification.open({
                            message: "Classifier retraining failed to start",
                            duration: 5,
                          });
                        })
                        .finally(() => {
                          setIsRetrainModalVisible(false);
                        });
                    }}
                  >
                    Start retrain and backfill
                  </Button>,
                ]}
                closable
                onCancel={() => setIsRetrainModalVisible(false)}
                open={isRetrainModalVisible}
              >
                <Typography.Text strong>Backfill Date Range:</Typography.Text>
                <br />
                <RangePicker
                  picker="date"
                  onChange={(_, dateString) => setRetrainBackfillDateRange(dateString)}
                />
              </Modal>
            </Space>
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              {isMerging && (
                <>
                  <Space>
                    <Popconfirm
                      title={
                        <>
                          <div>
                            Are you sure you want to merge these {clustersToMerge.length} clusters?
                          </div>
                          <ul>
                            {clustersToMerge.map(c => (
                              <li style={{ marginLeft: -25 }} key={c.id}>
                                {c.id} - &quot;{truncate(c.text, 50)}&quot;
                              </li>
                            ))}
                          </ul>
                        </>
                      }
                      zIndex={10000}
                      open={showConfirm}
                      okText="Merge"
                      onConfirm={() => {
                        invokeMergeClusters()
                          .then(() => {
                            notification.open({
                              message: "Clusters successfully merged",
                              duration: 5,
                            });
                          })
                          .catch(() => {
                            notification.open({ message: "Error merging clusters", duration: 5 });
                          })
                          .finally(() => {
                            setConfirmLoading(false);
                            setShowConfirm(false);
                          });
                      }}
                      okButtonProps={{ loading: confirmLoading }}
                      onCancel={handleCancel}
                    >
                      <Button
                        type="primary"
                        icon={<SaveFilled />}
                        disabled={clustersToMerge.length < 2}
                        onClick={showPopconfirm}
                      >
                        Save Merge
                      </Button>
                    </Popconfirm>
                    <Button
                      type="primary"
                      icon={<CloseOutlined />}
                      onClick={() => setClustersToMerge([])}
                      disabled={clustersToMerge.length < 2}
                    >
                      Unselect all
                    </Button>
                  </Space>
                  {clustersToMerge.length < 2 && (
                    <div>
                      <Typography.Text type="danger">
                        Select at least two clusters to merge
                      </Typography.Text>
                    </div>
                  )}
                </>
              )}
            </div>
            <Row justify={"space-between"}>
              <Col>
                <div>{clusters.length} clusters</div>
                <div>{totalInScope.toLocaleString()} conversations in latest 7 days</div>
              </Col>
              <Col span={2}>
                <Space>
                  Sort:
                  <Switch
                    checkedChildren="Volume"
                    unCheckedChildren="Date"
                    defaultChecked={false}
                    onChange={e => setSortMode(e ? "Volume" : "Date")}
                  />
                </Space>
              </Col>
            </Row>
            <Collapse
              accordion
              onChange={key =>
                Array.isArray(key) ? setActiveCluster(key[0]) : setActiveCluster(key)
              }
            >
              {clusters
                .sort((a, b) => {
                  if (sortMode === "Volume") {
{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                    return (counts[b.id] ?? 0) - (counts[a.id] ?? 0);
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  } else if (sortMode === "Date") {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                  }
                  return 0;
                })
                .map(c => (
                  <>
                    <CollapsePanel
                      key={c.id}
                      header={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <Space>
                            {isMerging && (
                              <Checkbox
                                checked={clustersToMerge.map(cluster => cluster.id).includes(c.id)}
                                onChange={e => {
                                  if (e.target.checked) {
                                    setClustersToMerge([
                                      ...clustersToMerge,
                                      { id: c.id, text: c.title ?? c.defaultTitle },
                                    ]);
                                  } else {
                                    setClustersToMerge(
                                      clustersToMerge.filter(cluster => cluster.id !== c.id)
                                    );
                                  }
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              />
                            )}
                            <Tag color={"geekblue"}>
                              <Typography.Text copyable strong>
                                {c.id}
                              </Typography.Text>
                            </Tag>
                            <Tag color={"geekblue"}>
                              <Typography.Text strong>
                                {Math.round((counts[c.id] / totalInScope) * 1000) / 10}%
                              </Typography.Text>
                            </Tag>
                            <Typography.Text>{c.title ?? c.defaultTitle}</Typography.Text>
                          </Space>
                          <Typography.Text type="secondary">
                            {new Date(c.createdAt + "Z").toLocaleString()}
                          </Typography.Text>
                        </div>
                      }
                    >
                      <Space direction="vertical">
                        <Row justify="space-between" gutter={16}>
                          <Col>
                            <Typography.Text strong>Training Data:</Typography.Text>
                          </Col>
                          <Col>
                            <Row gutter={16}>
                              <Col>
                                <Button
                                  icon={<EditOutlined />}
                                  onClick={() => {
                                    setClusterIdToEdit(c.id);
                                    setEditMode("edit");
                                    setIsModalVisible(true);
                                  }}
                                  type="primary"
                                >
                                  Edit
                                </Button>
                              </Col>
                              <Col>
                                <ClusterDeleteButton
                                  cluster={c}
                                  onConfirm={async () => {
                                    invokeDeleteCluster(c.id)
                                      .then(() => {
                                        notification.open({
                                          message: "Cluster successfully deleted",
                                          duration: 5,
                                        });
                                      })
                                      .catch(() => {
                                        notification.open({
                                          message: "Error deleting clusters",
                                          duration: 5,
                                        });
                                      })
                                      .finally(() => {
                                        setConfirmLoading(false);
                                        setShowConfirm(false);
                                      });
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row gutter={8}>
                          <Col span={11}>
                            <Typography.Text strong>Positives</Typography.Text>
                            <div style={{ overflowY: "scroll", height: "20em" }}>
                              <ul>
                                {c.id in training_data ? (
                                  training_data[c.id].positive.map(td => (
                                    <li key={td.id}>{td.text}</li>
                                  ))
                                ) : (
                                  <li>NO DATA</li>
                                )}
                              </ul>
                            </div>
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{ height: "100%" }} />
                          </Col>
                          <Col span={11}>
                            <Typography.Text strong>Negatives</Typography.Text>
                            <div style={{ overflowY: "scroll", height: "20em" }}>
                              <ul>
                                {c.id in training_data &&
                                training_data[c.id].negative.length > 0 ? (
                                  training_data[c.id].negative.map(td => (
                                    <li key={td.id}>{td.text}</li>
                                  ))
                                ) : (
                                  <li>NO DATA</li>
                                )}
                              </ul>
                            </div>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col>
                            <Row justify={"space-between"} gutter={16}>
                              <Col span={2}>
                                <Typography.Text strong>Live Data</Typography.Text>
                              </Col>
                              <Col span={2}>
                                <Switch
                                  title="Toggle high/low probability examples"
                                  checkedChildren="High Prob"
                                  unCheckedChildren="Low Prob"
                                  defaultChecked={showHighProbability}
                                  onChange={() => setShowHighProbability(!showHighProbability)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Spin spinning={isLoadingExamples}>
                                  <Table
                                    dataSource={activeClusterExamples.map(td => ({
                                      id: td.id,
                                      text: td.text,
                                      probability: td.probability,
                                      clustered_text_id: td.clustered_text_id,
                                    }))}
                                    pagination={false}
                                    scroll={{ y: 640 }}
                                    size="small"
                                  >
                                    <Table.Column
                                      title="ID"
                                      dataIndex="id"
                                      key="id"
                                      width="150px"
                                    />
                                    <Table.Column title="Text" dataIndex="text" key="text" />
                                    <Table.Column
                                      title="Probability"
                                      dataIndex="probability"
                                      key="probability"
                                      width={"100px"}
                                      render={probability => (probability * 100).toFixed(1) + "%"}
                                    />
                                    <Table.Column
                                      title="Annotate"
                                      dataIndex="annotate"
                                      key="annotate"
                                      width={"200px"}
                                      render={(_, record: { clustered_text_id: string }) => (
                                        <AnnotationWidget
                                          key={record.clustered_text_id}
                                          clusteredTextId={record.clustered_text_id}
                                          clusterId={c.id}
                                        />
                                      )}
                                    />
                                  </Table>
                                </Spin>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Space>
                    </CollapsePanel>
                  </>
                ))}
            </Collapse>
          </Col>
        </Row>
      </Spin>
      <Modal
        title={
          editMode == "create"
            ? "Create a New Cluster"
            : `Edit Cluster Training Data – ${
                clusters.find(c => c.id === clusterIdToEdit)?.title ?? ""
              }`
        }
        width={"80%"}
        style={{ marginLeft: "18%" }}
        cancelText="Cancel"
        okText="Save"
        onOk={() => {
          if (editMode == "create") {
            invokeCreateCluster()
              .then((cluster_id: string) => {
                notification.open({
                  message: `Cluster '${cluster_id}' successfully created`,
                  duration: 5,
                });
              })
              .catch(() => {
                notification.open({ message: "Error creating cluster", duration: 5 });
              });
            setIsModalVisible(false);
            setNewTrainingDataPos([]);
            setNewTrainingDataNeg([]);
          } else if (editMode == "edit") {
            invokeEditCluster(
              clusterIdToEdit,
              training_data[clusterIdToEdit].positive,
              training_data[clusterIdToEdit].negative
            )
              .then(() => {
                notification.open({ message: "Cluster successfully edited", duration: 5 });
              })
              .catch(() => {
                notification.open({ message: "Error editing cluster", duration: 5 });
              });
            setIsModalVisible(false);
            setClusterIdToEdit("");
          }
        }}
        onCancel={() => {
          if (changesMade) {
            Modal.confirm({
              title: "Unsaved changes",
              content: "Are you sure you want to close this modal without saving?",
              okText: "Yes",
              cancelText: "No",
              onOk: () => {
                setIsModalVisible(false);
                setChangesMade(false);
              },
            });
          } else {
            setIsModalVisible(false);
            setChangesMade(false);
          }
        }}
        closable={!changesMade}
        maskClosable={!changesMade}
        open={isModalVisible}
      >
        <>
          <Row gutter={[0, 12]}>
            <Col span={24}>
              <Typography.Title level={5}>Cluster Title:</Typography.Title>
              <Input
                id="cluster-title"
                key={clusterIdToEdit}
                defaultValue={clusters.find(c => c.id === clusterIdToEdit)?.title}
                onChange={e => setLocalClusterTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ margin: "16px 0" }}>
            <Col span={24}>
              <Typography.Title level={5}>Training Data</Typography.Title>
            </Col>
            <Col>
              <Typography.Text type="secondary">
                Put the text of each piece of training data on a new line
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Typography.Text strong>Positives</Typography.Text>
              <Input.TextArea
                disabled={clusterIdToEdit === "_is_interesting"}
                rows={10}
                value={
                  editMode == "create"
                    ? newTrainingDataPos.join("\n")
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    : training_data[clusterIdToEdit]
                    ? training_data[clusterIdToEdit].positive.map(td => td.text).join("\n")
                    : ""
                }
                onChange={e => {
                  setChangesMade(true);
                  if (editMode == "create") {
                    setNewTrainingDataPos(e.target.value.split("\n"));
                  } else if (editMode == "edit") {
                    const newTrainingData = e.target.value
                      .split("\n")
                      .map(td => ({ id: "", text: td }));
                    setTrainingData({
                      ...training_data,
                      [clusterIdToEdit]: {
                        ...training_data[clusterIdToEdit],
                        ...{
                          positive: newTrainingData,
                        },
                      },
                    });
                  }
                }}
              />
            </Col>
            <Col span={12}>
              <Typography.Text strong>Negatives</Typography.Text>
              <Input.TextArea
                rows={10}
                value={
                  editMode == "create"
                    ? newTrainingDataNeg.join("\n")
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    : training_data[clusterIdToEdit]
                    ? training_data[clusterIdToEdit].negative.map(td => td.text).join("\n")
                    : ""
                }
                onChange={e => {
                  setChangesMade(true);
                  if (editMode == "create") {
                    setNewTrainingDataNeg(e.target.value.split("\n"));
                  } else if (editMode == "edit") {
                    const newTrainingData = e.target.value
                      .split("\n")
                      .map(td => ({ id: "", text: td }));
                    setTrainingData({
                      ...training_data,
                      [clusterIdToEdit]: {
                        ...training_data[clusterIdToEdit],
                        ...{
                          negative: newTrainingData,
                        },
                      },
                    });
                  }
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ExploreSimilarData
                key={clusterIdToEdit}
                cluster_id={clusterIdToEdit}
                trainingData={training_data[clusterIdToEdit]}
                setJudgement={({ text, judgement }: { text: string; judgement?: Judgement }) => {
                  setChangesMade(true);
                  const previousData = training_data[clusterIdToEdit];
                  const newTrainingData = (() => {
                    if (judgement === undefined) {
                      return {
                        positive: previousData.positive.filter(td => td.text !== text),
                        negative: previousData.negative.filter(td => td.text !== text),
                      };
                    } else {
                      return {
                        ...previousData,
                        [judgement]: [
                          ...previousData[judgement],
                          {
                            id: "",
                            text,
                          },
                        ],
                      };
                    }
                  })();
                  setTrainingData({
                    ...training_data,
                    [clusterIdToEdit]: {
                      ...newTrainingData,
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </>
      </Modal>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ConversationQueue {
  queue: number[];
  total: number;
  untagged: number;
}

interface Message {
  message: string;
  type: string;
  user_type: string;
  id?: string;
  timestamp?: string;
  title?: string;
  author?: string;
  is_agent?: boolean;
  relative_time?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Conversation {
  conversationMetadata: object;
  messages: Message[];
  customer: string;
  language: string;
  datasetId: number;
  createdAt: string;
  updatedAt: string;
  sourceFile: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CDEvidence {
  id: number;
  conversation_id: string;
  created_at: string;
  text: string;
  sentiment: number;
  category?: string;
  cluster_id: number;
  cluster_title: string;
  cluster_default_title: string;
}

export const CustomerManagerPage = ({
  tabDefault,
  unsetCustomer,
}: {
  tabDefault?:
    | "customer-editor"
    | "report-editor"
    | "titles-editor"
    | "conversation-queue"
    | "clusters-editor"
    | "cluster-proposal-editor"
    | "cluster-training-data-search"
    | "cluster-training-data-explorer"
    | "integrations";
  unsetCustomer: () => void;
}) => {
  const { customer: authCustomer } = useCustomer();
  const { customerId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>();
  const [selectedTier, setSelectedTier] = useState<Tier | "all">("all");
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [customer, setCustomer] = useState<Customer>();
  const [reports, setReports] = useState<ReportAPIResp>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const updateCustomerState = (id: string, index: CustomerIndex) => {
    if (!customers) {
      return;
    }
    const newCustomers = [...customers];
    const toUpdate = newCustomers.find((c: Customer) => c.id == id);
    if (!toUpdate) {
      console.error(`cannot find customer ${id} to update!`);
      return;
    }
    toUpdate.customerIndexJson = index;
    setCustomers(newCustomers);
  };

  const invokeDeleteCustomer = async (customer: string) => {
    setIsLoading(true);
    try {
      const resp = await deleteCustomer(customer);
      if (isAPIError(resp)) {
        console.error(`Error deleting customer ${customer}: ${resp.description}`);
        return resp;
      }
      unsetCustomer();
    } finally {
      setIsLoading(false);
    }
  };

  // "initial"/first useEffect to fetch reports for a customer
  useEffect(() => {
    if (!customer) {
      return;
    }

    const getReportsAsync = async () => {
      const resp = await getReports(customer.id);
      setReports(resp);
    };
    getReportsAsync();
  }, [customer]);

  // Customer selection && navigation logic
  useEffect(() => {
    if (!customerId && authCustomer.id) {
      navigate(authCustomer.id);
    }
    if (customers && customerId) {
      setCustomer(customers.find(c => c.id.toLowerCase() === customerId.toLowerCase()));
    }
  }, [customerId, customers, authCustomer.id, navigate]);

  useEffect(() => {
    const f = async () => {
      getCustomers()
        .then(resp => {
          if (isAPIError(resp)) {
            console.error(`fetching customers: ${resp.description}`);
            return;
          }
          setCustomers(resp.customers);
        })
        .catch(err => {
          console.error(`fetching customers: ${err}`);
          return;
        });
    };

    f();
  }, [customerId]);

  if (!customer) return <div>Loading...</div>;

  const tabItems = [
    {
      label: "Customer",
      key: "customer-editor",
      children: (
        <CustomerEditor
          customer={customer}
          updateCustomerState={updateCustomerState}
          reports={reports}
        ></CustomerEditor>
      ),
    },
    {
      label: "Reports",
      key: "report-editor",
      children: (
        <ReportEditor customer={customer} reports={reports} setReports={setReports}></ReportEditor>
      ),
    },
    {
      label: "Titles",
      key: "titles-editor",
      children: <TitlesFileTab customer={customer} reports={reports} />,
    },
    {
      label: "Clusters",
      key: "clusters-editor",
      children: <ClustersTab customer={customer} />,
    },
    {
      label: "Integrations",
      key: "integrations",
      children: <IntegrationsPage customer={customer} />,
    },
  ];

  const handleTabChange = (activeKey: string) => {
    if (activeKey == "titles-editor" && !location.pathname.endsWith("titles")) {
      navigate(`/admin/customers/${customerId}/titles`);
    }
    if (activeKey == "report-editor" && !location.pathname.endsWith("reports")) {
      navigate(`/admin/customers/${customerId}/reports`);
    }
    if (activeKey == "clusters-editor" && !location.pathname.endsWith("clusters")) {
      navigate(`/admin/customers/${customerId}/clusters`);
    }
    if (activeKey == "cluster-proposal-editor" && !location.pathname.endsWith("cluster-proposal")) {
      navigate(`/admin/customers/${customerId}/cluster-proposal`, {
        state: { customerId: customerId },
      });
    }
    if (
      activeKey == "cluster-training-data-search" &&
      !location.pathname.endsWith("cluster-training-data-search")
    ) {
      navigate(`/admin/customers/${customerId}/cluster-training-data-search`, {
        state: { customerId: customerId },
      });
    }
    if (activeKey == "customer-editor") {
      navigate(`../${customerId}/customer`, { state: { customerId: customerId } });
    }

    if (activeKey == "conversation-queue") {
      navigate(`../${customerId}/conversation-queue`, { state: { customerId: customerId } });
    }
    if (activeKey == "cluster-training-data-explorer") {
      navigate(`../${customerId}/cluster-training-data-explorer`, {
        state: { customerId: customerId },
      });
    }
    if (activeKey == "integrations") {
      navigate(`../${customerId}/integrations`, {
        state: { customerId: customerId },
      });
    }
  };

  return (
    <>
      <Row key="cm-spacer" style={{ margin: "0 24px 24px" }}>
        <Col />
      </Row>
      <Row key="cm-title" style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <h3>Customer Management</h3>
        </Col>
      </Row>
      <Row key="cm-picker" style={{ margin: "0 24px 24px" }}>
        <Spin spinning={isLoading || !customers}>
          <Row style={{ width: 800 }}>
            <Col span={5}>
              <Typography.Text strong>Filter by tier:</Typography.Text>
            </Col>
            <Col span={5}>
              <Typography.Text strong>Customer:</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Select
                style={{ width: 150 }}
                defaultValue={"all"}
                options={[
                  { value: "all", label: "all" },
                  ...tiers.map(t => ({ value: t, label: t })),
                ]}
                onChange={tier => {
                  setSelectedTier(tier);
                  setSelectedCustomer(undefined);
                }}
              />
            </Col>
            <Col span={5}>
              <Select
                showSearch
                style={{ width: 150 }}
                value={selectedCustomer?.id}
                defaultValue={customer.id}
                options={customers
                  ?.filter(c => selectedTier == "all" || c.tier == selectedTier)
                  .map(c => ({ value: c.id, label: c.customerIndexJson.displayName }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                onChange={value => {
                  const cust = customers?.filter(c => c.id == value)[0];
                  setSelectedCustomer(cust);
                  setCustomer(cust);
                  navigate(`../${value}/customer`);
                }}
              />
            </Col>
            <Col span={5}>
              <Popconfirm
                title={`Are you sure you want to permanently delete all data for the customer "${customer.customerIndexJson.displayName}"?`}
                zIndex={10000}
                placement="bottom"
                onCancel={() => setIsDeleteConfirmVisible(false)}
                onConfirm={() => {
                  invokeDeleteCustomer(customer.id)
                    .then(() => {
                      notification.success({
                        message: `Customer "${customer.customerIndexJson.displayName}" deleted`,
                        duration: 5,
                      });
                    })
                    .catch(() => {
                      notification.error({
                        message: `Error deleting customer "${customer.customerIndexJson.displayName}"`,
                        duration: 5,
                      });
                    })
                    .finally(() => setIsDeleteConfirmVisible(false));
                }}
              >
                <Tooltip
                  title={
                    selectedCustomer?.tier != "demo" ? "Only demo customers can be deleted" : ""
                  }
                >
                  <Button
                    icon={<DeleteOutlined />}
                    disabled={selectedCustomer?.tier != "demo"}
                    className="delete-button"
                    // type="primary"
                    onClick={() => {
                      if (!selectedCustomer) {
                        return;
                      }
                      setIsDeleteConfirmVisible(true);
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </Spin>
      </Row>
      <Divider />
      <Row style={{ margin: "0 24px 24px" }}>
        <Col span={24}>
          <Tabs
            items={tabItems}
            onChange={handleTabChange}
            defaultActiveKey={tabDefault}
            size="large"
          />
        </Col>
      </Row>
    </>
  );
};
