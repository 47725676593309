import React, { Dispatch, SetStateAction } from "react";
import ReactMarkdown from "react-markdown";

import {
  Alert,
  Tooltip as AntTooltip,
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import Linkify from "linkify-react";
import { useCustomer, useDataLoader } from "../hooks";
import { ConversationMetadata, Message } from "../indexTypes";
import { getConversation, getConversationSummary } from "../reportApi";
import { stripPii } from "../utils";
import { CopyButton } from "./CopyButton";
import Card from "antd/lib/card/Card";
import { ExperimentOutlined } from "@ant-design/icons";
import "./ConversationModal.less";

export const ConversationModal = ({
  visible,
  setVisible,
  modalType,
  conversationMetadata,
}: {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  modalType?: string; // TODO: Use to add stars for reviews or remove
  conversationMetadata: ConversationMetadata;
}) => {
  const { customer } = useCustomer();
  const { optional_metadata = {} } = conversationMetadata;
  const {
    loading: conversationLoading,
    data: conversation,
    error: conversationErr,
  } = useDataLoader(
    async controller => {
      const conversationResp = await getConversation(
        customer.id,
        conversationMetadata.uuid,
        controller.signal
      );
      return conversationResp;
    },
    [conversationMetadata, customer.id]
  );

  const hasSummaryRequirements =
    conversation &&
    conversation.messages &&
    (conversation.messages.length > 1 || conversation.messages[0].message.length >= 300);

  const {
    loading: summaryLoading,
    data: conversationSummary,
    error: summaryError,
  } = useDataLoader(
    async controller => {
      if (!hasSummaryRequirements) {
        return;
      }
      const resp = await getConversationSummary(
        customer.id,
        conversationMetadata.uuid,
        controller.signal
      );
      return resp;
    },
    [conversationMetadata, customer.id, conversation]
  );

  const conversationSummaryCard = hasSummaryRequirements && (
    <Card
      loading={summaryLoading}
      className="rounded summary-content"
      style={{
        wordBreak: "break-word",
      }}
    >
      <h1 style={{ fontSize: "15px" }}>
        <Space>
          <ExperimentOutlined />
          Spiral AI Summary:
        </Space>
      </h1>
      {summaryError ? (
        <Alert message="Summary could not be generated." type="error" className="rounded" />
      ) : (
        <ReactMarkdown className="list">{conversationSummary?.summary}</ReactMarkdown>
      )}
    </Card>
  );

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      onOk={() => setVisible(false)}
      footer={[
        <Button key="close" type="primary" onClick={() => setVisible(false)}>
          Close
        </Button>,
      ]}
      bodyStyle={{ height: modalType === "review" ? "40vh" : "80vh", overflowY: "scroll" }}
      width={"65%"}
      zIndex={9999}
      className="modal"
    >
      <Row style={{ height: "100%" }}>
        <Col span="7" style={{ overflow: "auto", maxHeight: "100%", wordBreak: "break-all" }}>
          <Space direction="vertical">
            {conversation && (
              <>
                <div key="conversationKey" className="selectable">
                  <b>Conversation Key:</b>
                  <br />
                  <Space>
                    {conversation.key}
                    <CopyButton text={conversation.key} />
                  </Space>
                </div>
                {conversationSummaryCard}
                <div className="list">
                  <b>User Stories:</b>
                  <ul>
                    {conversation.userStories.map((item: string, index: number) => (
                      <li key={`user_story_${index}`}>
                        <Typography.Text className="selectable">{item}</Typography.Text>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {Object.entries(optional_metadata)
              .filter(([, v]) => v !== "unknown") // Remove this if we stop filling NA with "unknown"
              .map(([k, v]) => (
                <div key={k} className="selectable">
                  <AntTooltip title={k} zIndex={10001}>
                    <b>{k}:</b>
                  </AntTooltip>
                  <br />
                  <Linkify options={{ target: "_blank" }}>
                    {JSON.stringify(v).replace(/^"?(.*?)"?$/, "$1")}
                  </Linkify>
                </div>
              ))}
          </Space>
        </Col>
        <Divider type="vertical" style={{ height: "100%" }} />
        <Col span="16" style={{ overflow: "auto", maxHeight: "100%", padding: "0 15px" }}>
          <Spin spinning={conversationLoading} style={{ width: "100%", paddingTop: "20px" }}>
            {conversationErr && (
              <Alert message="Failed to load conversation" type="error" className="rounded" />
            )}
            {conversation &&
              conversation.messages
                .filter((msg: Message) => msg.message.length > 0)
                .map((msg: Message, i: number) => {
                  const userType =
                    msg.user_type == "customer"
                      ? "customer"
                      : msg.is_agent
                      ? "agent"
                      : msg.user_type == "system" && msg.message.length > 60
                      ? "agent"
                      : msg.user_type == "system"
                      ? "system"
                      : "agent";
                  // TODO: Add stars for reviews
                  const bubble = (
                    <div id={`modal-msg-${i}`} className={`message ${userType} selectable`}>
                      {stripPii(msg.message)}
                    </div>
                  );
                  return (
                    <div key={`modal-msg-${i}`} className="message-container">
                      {bubble}
                    </div>
                  );
                })}
          </Spin>
        </Col>
      </Row>
    </Modal>
  );
};
