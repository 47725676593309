import React, { useState, useEffect } from 'react';
import { getHighlighter } from 'shiki';
import ReactMarkdown from 'react-markdown';
import { Message, AgentResponseBlock, AgentMessage, DataPoint, ComparisonDataPoint } from '@/agentChatApi';
import { 
  PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip,
  BarChart, Bar, XAxis, YAxis, CartesianGrid,
  LineChart, Line, Area
} from 'recharts';
import { ConversationMetadataTable } from '@/components/ConversationMetadataTable';
import { getConversationsMetadata } from '@/reportApi';
import { BarChart2, Database, Search, ClipboardList, Wrench, Code } from 'lucide-react';
import {
  Tooltip as UITooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {useCustomer} from '@/hooks';

const COLORS = ["#223065", "#4558A5", "#7080C2", "#B3BDE7", "#DBE0F5"];

// Split into separate components
const MarkdownBlock: React.FC<{ markdown: string }> = ({ markdown }: { markdown: string }) => (
  <div className="markdown-content select-text">
    <ReactMarkdown>{markdown}</ReactMarkdown>
  </div>
);

const TaxonomyNodeLink: React.FC<{ node_id: string; display_text: string }> = ({ 
  node_id, 
  display_text 
}: { node_id: string; display_text: string }) => (
  <button 
    className="text-blue-500 hover:underline" 
    onClick={() => console.log('Navigate to node:', node_id)}
  >
    {display_text}
  </button>
);

const LineChartBlock: React.FC<{
  data: DataPoint[];
  title: string;
  query: string;
}> = ({ data, title, query }: { data: DataPoint[]; title: string; query: string }) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [highlightedCode, setHighlightedCode] = useState<string>("");
  // Colors for single line chart
  const lineColors = {
    primary: "#4558A5",  // Blue line
    areaFill: "rgba(69, 88, 165, 0.1)" // Light blue area
  };

  useEffect(() => {
    if (showQuery) {
      getHighlighter({
        theme: 'github-light'
      }).then(highlighter => {
        const highlighted = highlighter.codeToHtml(query, { lang: 'sql' });
        setHighlightedCode(highlighted);
      }).catch(err => {
        console.error('Error highlighting code:', err);
        setHighlightedCode(`<pre>${query}</pre>`);
      });
    }
  }, [showQuery, query]);

  return (
    <div className="p-4 rounded bg-white relative">
      <button 
        onClick={() => setShowQuery(!showQuery)}
        className="absolute top-2 right-2 p-1 rounded hover:bg-gray-100"
        title="Toggle SQL Query"
      >
        <Code size={16} />
      </button>
      <h3 className="font-bold mb-4">{title}</h3>
      {showQuery && (
        <div className="mb-4 p-2 bg-gray-100 rounded border border-gray-200 overflow-auto max-h-[200px]">
          {highlightedCode ? (
            <div className="text-xs" dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          ) : (
            <pre className="text-xs">{query}</pre>
          )}
        </div>
      )}
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid 
              stroke="rgba(165,170,191,0.5)" 
              strokeDasharray="2 7" 
              vertical={false} 
            />
            <XAxis 
              dataKey="x" 
              axisLine={false} 
              tickLine={false}
              dy={10}
            />
            <YAxis 
              axisLine={false} 
              tickLine={false}
              dx={-10}
              tickFormatter={(value: number) => value.toLocaleString()}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="y"
              fill={lineColors.areaFill}
              stroke="none"
            />
            <Line
              type="monotone"
              dataKey="y"
              stroke={lineColors.primary}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const LineChartComparisonBlock: React.FC<{
  data: ComparisonDataPoint[];
  title: string;
  query: string;
}> = ({ data, title, query }: { data: ComparisonDataPoint[]; title: string; query: string }) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [highlightedCode, setHighlightedCode] = useState<string>("");
  // Colors for comparison lines
  const lineColors = {
    line1: "#4558A5",  // Blue line
    line2: "#7AB55C",  // Green line
  };

  useEffect(() => {
    if (showQuery) {
      getHighlighter({
        theme: 'github-light'
      }).then(highlighter => {
        const highlighted = highlighter.codeToHtml(query, { lang: 'sql' });
        setHighlightedCode(highlighted);
      }).catch(err => {
        console.error('Error highlighting code:', err);
        setHighlightedCode(`<pre>${query}</pre>`);
      });
    }
  }, [showQuery, query]);

  return (
    <div className="p-4 rounded bg-white relative">
      <button 
        onClick={() => setShowQuery(!showQuery)}
        className="absolute top-2 right-2 p-1 rounded hover:bg-gray-100"
        title="Toggle SQL Query"
      >
        <Code size={16} />
      </button>
      <h3 className="font-bold mb-4">{title}</h3>
      {showQuery && (
        <div className="mb-4 p-2 bg-gray-100 rounded border border-gray-200 overflow-auto max-h-[200px]">
          {highlightedCode ? (
            <div className="text-xs" dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          ) : (
            <pre className="text-xs">{query}</pre>
          )}
        </div>
      )}
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid 
              stroke="rgba(165,170,191,0.5)" 
              strokeDasharray="2 7" 
              vertical={false} 
            />
            <XAxis 
              dataKey="x" 
              axisLine={false} 
              tickLine={false}
              dy={10}
            />
            <YAxis 
              axisLine={false} 
              tickLine={false}
              dx={-10}
              tickFormatter={(value: number) => value.toLocaleString()}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="y1"
              name="First Line"
              stroke={lineColors.line1}
              strokeWidth={2}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="y2"
              name="Second Line"
              stroke={lineColors.line2}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const PieChartBlock: React.FC<{ 
  data: {x: string, y: number}[]; 
  title: string;
  query: string;
}> = ({ data, title, query }: { data: {x: string, y: number}[]; title: string; query: string }) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [highlightedCode, setHighlightedCode] = useState<string>("");
  // Sort data by value in descending order
  const sortedEntries = data
    .map(item => ({
      name: item.x,
      value: Number(item.y)
    }))
    .sort((a, b) => b.value - a.value);
    
  useEffect(() => {
    if (showQuery) {
      getHighlighter({
        theme: 'github-light'
      }).then(highlighter => {
        const highlighted = highlighter.codeToHtml(query, { lang: 'sql' });
        setHighlightedCode(highlighted);
      }).catch(err => {
        console.error('Error highlighting code:', err);
        setHighlightedCode(`<pre>${query}</pre>`);
      });
    }
  }, [showQuery, query]);

  // Only create "Other" bucket if there are at least 3 items to group
  const leftoverCount = sortedEntries.length - 7;
  const sortedData = leftoverCount < 3
    ? sortedEntries
    : [
        ...sortedEntries.slice(0, 7),
        {
          name: "Other",
          value: sortedEntries
            .slice(7)
            .reduce((sum, item) => sum + item.value, 0)
        }
      ];

  return (
    <div className="p-4 rounded bg-white relative">
      <button 
        onClick={() => setShowQuery(!showQuery)}
        className="absolute top-2 right-2 p-1 rounded hover:bg-gray-100"
        title="Toggle SQL Query"
      >
        <Code size={16} />
      </button>
      <h3 className="font-bold mb-4">{title}</h3>
      {showQuery && (
        <div className="mb-4 p-2 bg-gray-100 rounded border border-gray-200 overflow-auto max-h-[200px]">
          {highlightedCode ? (
            <div className="text-xs" dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          ) : (
            <pre className="text-xs">{query}</pre>
          )}
        </div>
      )}
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={sortedData}
              dataKey="value"
              nameKey="name"
              animationBegin={0}
              animationDuration={250}
              labelLine={false}
              label={(entry: {name: string, value: number}) => `${entry.name} (${entry.value.toFixed(1)})`}
            >
              {sortedData.map((_, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip formatter={(value: number) => value.toFixed(1)} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const BarChartBlock: React.FC<{
  data: {x: string, y: number}[];
  title: string;
  query: string;
}> = ({ data, title, query }: { data: {x: string, y: number}[]; title: string; query: string }) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [highlightedCode, setHighlightedCode] = useState<string>("");
  // Sort data by value in descending order
  const sortedData = data
    .map(item => ({
      name: item.x,
      value: Number(item.y)
    }))
    .sort((a, b) => b.value - a.value);
    
  useEffect(() => {
    if (showQuery) {
      getHighlighter({
        theme: 'github-light'
      }).then(highlighter => {
        const highlighted = highlighter.codeToHtml(query, { lang: 'sql' });
        setHighlightedCode(highlighted);
      }).catch(err => {
        console.error('Error highlighting code:', err);
        setHighlightedCode(`<pre>${query}</pre>`);
      });
    }
  }, [showQuery, query]);

  return (
    <div className="p-4 rounded bg-white relative">
      <button 
        onClick={() => setShowQuery(!showQuery)}
        className="absolute top-2 right-2 p-1 rounded hover:bg-gray-100"
        title="Toggle SQL Query"
      >
        <Code size={16} />
      </button>
      <h3 className="font-bold mb-4">{title}</h3>
      {showQuery && (
        <div className="mb-4 p-2 bg-gray-100 rounded border border-gray-200 overflow-auto max-h-[200px]">
          {highlightedCode ? (
            <div className="text-xs" dangerouslySetInnerHTML={{ __html: highlightedCode }} />
          ) : (
            <pre className="text-xs">{query}</pre>
          )}
        </div>
      )}
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={sortedData}>
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} tickFormatter={value => value.toFixed(1)} />
            <CartesianGrid vertical={false} stroke="rgba(165,170,191,0.5)" strokeDasharray="2 7" />
            <Tooltip formatter={(value: number) => value.toFixed(1)} />
            <Bar dataKey="value" fill={COLORS[0]} radius={10} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const BlockRenderer: React.FC<{ block: AgentResponseBlock }> = ({ block }: { block: AgentResponseBlock }) => {
  const customer = useCustomer();
  switch (block.type) {
    case 'markdown':
      return <MarkdownBlock markdown={block.markdown} />;
      
    case 'taxonomy_node_link':
      return (
        <TaxonomyNodeLink 
          node_id={block.node_id} 
          display_text={block.display_text} 
        />
      );
      
    case 'pie_chart':
      return <PieChartBlock data={block.data} title={block.title} query={block.query} />;
      
    case 'bar_chart':
      return <BarChartBlock data={block.data} title={block.title} query={block.query} />;
      
    case 'line_chart':
      return <LineChartBlock data={block.data} title={block.title} query={block.query} />;
      
    case 'line_chart_comparison':
      return <LineChartComparisonBlock data={block.data} title={block.title} query={block.query} />;

    case 'example_conversations':
      return (
        <ConversationMetadataTable
          fetchConversations={async () => {
            const response = await getConversationsMetadata(
              customer.customer.id,
              block.conversation_ids
            );
            return response.conversations;
          }}
        />
      );
      
    default:
      console.warn('Unknown block type:', block);
      return null;
  }
};

const ToolIcon: React.FC<{ tool_name: string }> = ({ tool_name }) => {
  const getIcon = () => {
    switch (tool_name) {
      case 'run_sql':
        return <Database className="w-4 h-4" />;
      case 'deep_conversation_analysis':
        return <BarChart2 className="w-4 h-4" />;
      case 'vector_search':
        return <Search className="w-4 h-4" />;
      case 'planning':
        return <ClipboardList className="w-4 h-4" />;
      default:
        return <Wrench className="w-4 h-4" />;
    }
  };

  const getPrettyName = () => {
    switch (tool_name) {
      case 'run_sql':
        return 'SQL Query';
      case 'deep_conversation_analysis':
        return 'Deep Conversation Analysis';
      case 'vector_search':
        return 'Vector Search';
      default:
        return 'Tool Operation';
    }
  };

  return (
    <TooltipProvider>
      <UITooltip>
        <TooltipTrigger>
          <div className="text-gray-600">
            {getIcon()}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{getPrettyName()}</p>
        </TooltipContent>
      </UITooltip>
    </TooltipProvider>
  );
};



const MessageContent: React.FC<{ message: Message }> = ({ message }) => {
  if (message.role === 'user') {
    return <p>{message.content}</p>;
  }
  
  if (message.role === 'tool_use_event') {
    const toolEvent = message.content;
    return (
      <div className="text-sm flex items-center gap-2 select-text">
        <ToolIcon tool_name={toolEvent.tool_name} />
        <span>{toolEvent.reasoning}</span>
      </div>
    );
  }

  return (
    <div className="space-y-4 select-text">
      {(message as AgentMessage).content.map((block: AgentResponseBlock, index: number) => (
        <BlockRenderer key={index} block={block} />
      ))}
    </div>
  );
};

export const MessageComponent: React.FC<{ message: Message }> = ({ message }) => {
  if (message.role === 'tool_use_event') {
    return (
      <div className="flex justify-center mb-2">
        <div className="text-gray-500 px-4 py-2">
          <MessageContent message={message} />
        </div>
      </div>
    );
  }

  return (
    <div className={`flex justify-center mb-2`}>
    <div style={{ width: '60rem', display: 'flex', justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start'}}>
       <div
          className={`rounded-lg px-6 py-4 shadow-md select-text ${
            message.role === 'user' ? 
              'bg-blue-500 text-white' :  // User message
              'bg-white text-gray-900' // Agent message
          }`}
          style={message.role === 'user' ? 
            {} : // User message
            { 
              width: '50rem',
              alignSelf: 'flex-start',
            } // Agent message
          }
        >
        <MessageContent message={message} />
      </div>
    </div>
     </div>
  );
};
