import { TaxonomyNodeFilters } from "@/indexTypes";
import { ParsedExpression } from "@/types/expressionsDslTypes";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { createSelector } from "reselect";
import { getMetricsView } from "../../reportApi";
import { MetricsView } from "../../types/dashboardTypes";
import { Loadable } from "../../types/util";

export interface MetricsViewPageSettings {
  startDate: string;
  endDate: string;
  filters?: ParsedExpression;
  taxonomyNodeFilters?: TaxonomyNodeFilters;
}

export interface MetricsViewData {
  metricsView: MetricsView;
  settings: MetricsViewPageSettings;
}

export type MetricsViewId = string;

export interface MetricsViewState {
  metricsViewId: MetricsViewId;
  metricsViewState: Loadable<MetricsViewData>;
}

const initialState: MetricsViewState = {
  metricsViewId: "",
  metricsViewState: {
    loading: true,
  },
};

export const selectMetricsView = (state: { metricsView: MetricsViewState }) =>
  state.metricsView.metricsViewState.data?.metricsView;

export const selectWidgetsData = createSelector([selectMetricsView], metricsViewData => {
  return metricsViewData ? metricsViewData.settings.widgets : [];
});

export const metricsViewSlice = createSlice({
  name: "metricsView",
  initialState,
  reducers: {
    setMetricsViewId(state, action: PayloadAction<string>) {
      state.metricsViewId = action.payload;
      state.metricsViewState.data = undefined;
    },
    setMetricsViewLoading(state, action: PayloadAction<boolean>) {
      state.metricsViewState.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | undefined>) {
      state.metricsViewState.error = action.payload;
    },
    setMetricsView(state, action: PayloadAction<MetricsViewData>) {
      state.metricsViewState.data = {
        metricsView: action.payload.metricsView,
        settings: action.payload.settings,
      };
    },
    updateSettings(state, action: PayloadAction<Partial<MetricsViewPageSettings>>) {
      const newSettings = action.payload;
      if (!state.metricsViewState.data) return;
      const current = state.metricsViewState.data.settings;
      Object.assign(current, newSettings);
    },
  },
});

/**
 * updateMetricsViewPageSettingsThunk - merges new settings into the store's current settings.
 */
export const updateMetricsViewPageSettingsThunk = createAsyncThunk<
  void, // Nothing returned
  { newSettings: Partial<MetricsViewPageSettings> }, // Param
  { rejectValue: string }
>("metricsView/updatePageSettings", async ({ newSettings }, { dispatch, rejectWithValue }) => {
  try {
    dispatch(metricsViewSlice.actions.updateSettings(newSettings));
  } catch (err) {
    console.error("updateViewSettingsThunk error:", err);
    return rejectWithValue("Error updating settings");
  }
});

/**
 * initMetricsViewThunk - fetches a MetricsView by ID, sets it in state, then triggers API requests to populate the
 * data for each of the widgets in the view.
 */
export const initMetricsViewThunk = createAsyncThunk<
  { metricsViewResp: MetricsView }, // Return type on success
  { metricsViewId: string; customerId: string }, // Param
  { rejectValue: string } // Rejected action payload
>("metricsView/initMetricsView", async (params, { dispatch, rejectWithValue }) => {
  const { metricsViewId, customerId } = params;

  dispatch(metricsViewSlice.actions.setMetricsViewId(metricsViewId));
  dispatch(metricsViewSlice.actions.setMetricsViewLoading(true));

  // load the metrics view
  try {
    const metricsViewResp = await getMetricsView(customerId, metricsViewId);

    const endDate = moment();
    const defaultPeriodLength = metricsViewResp.settings.defaultTimeWindow;
    let startDate;
    switch (defaultPeriodLength) {
      case "quarter":
        startDate = moment().subtract(3, "months");
        break;
      case "year":
        startDate = moment().subtract(1, "years");
        break;
      case "month":
      default:
        startDate = moment().subtract(1, "months");
        break;
    }

    dispatch(
      metricsViewSlice.actions.setMetricsView({
        metricsView: metricsViewResp,
        settings: {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
      })
    );
    dispatch(metricsViewSlice.actions.setMetricsViewLoading(false));
    return { metricsViewResp };
  } catch (err) {
    console.error("initMetricsViewThunk error:", err);
    return rejectWithValue("Error fetching metrics view");
  }
});

/* ------------------------------------------------------------------
 * Export the slice's actions and reducer
 * ------------------------------------------------------------------ */
export const { setError } = metricsViewSlice.actions;

export default metricsViewSlice.reducer;
