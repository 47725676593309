import { Button, Modal, Select, Tooltip } from "antd";
import React, { useState } from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { useAppSelector, useCustomer } from "../../../hooks";
import { isAPIError } from "../../../indexTypes";
import { getTaxonomyExport } from "../../../reportApi";
import {
  getDatasetIdsFromParsedExpression,
  groupConversationMetadataFieldsByDisplayName,
} from "@/utils";

interface ExportTaxonomyModalProps {
  taxonomyNodeId: string;
  viewId: string;
}
export const ExportTaxonomyModal: React.FC<ExportTaxonomyModalProps> = ({
  taxonomyNodeId,
  viewId,
}) => {
  const state = useAppSelector(state => state.dashboard);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const { customer } = useCustomer();

  if (!state.viewState.data || !state.viewState.data.taxonomy.data) {
    return <></>;
  }
  const taxonomyId = state.viewState.data.taxonomy.data.id;
  const { startDate, endDate, filters } = state.viewState.data.settings;
  const viewExpression = state.viewState.data.view.expression;
  const sentimentFilter = state.viewState.data.settings.taxonomyNodeFilters?.sentiment;

  const viewDatasets = getDatasetIdsFromParsedExpression(viewExpression);
  const viewMetadataFields = customer.conversationMetadataFields.filter(field =>
    viewDatasets.includes(field.datasetId)
  );

  // Combine fields with the same displayName; set the selector's value as a JSON array of fieldNames
  const fieldsByDisplayName = groupConversationMetadataFieldsByDisplayName(viewMetadataFields);
  const options = Object.keys(fieldsByDisplayName)
    .map(displayName => {
      const valuesStr = JSON.stringify(fieldsByDisplayName[displayName].map(f => f.fieldName));
      return { label: displayName, value: valuesStr };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <Tooltip title="Download example conversations from this issue category" placement="left">
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          disabled={downloadIsLoading}
          loading={downloadIsLoading}
          onClick={e => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        />
      </Tooltip>
      <Modal
        title="Export Examples"
        open={isModalOpen}
        onOk={async e => {
          e.stopPropagation();
          setDownloadIsLoading(true);
          const metadataFields = selectedFields.map(value => JSON.parse(value)).flat();
          try {
            const response = await getTaxonomyExport(
              taxonomyId,
              taxonomyNodeId,
              viewId,
              customer.id,
              startDate,
              endDate,
              metadataFields,
              filters,
              sentimentFilter
            );
            if (response.body) {
              const outputFilename = `spiral_examples_${startDate}_to_${endDate}.csv`;
              // I know this looks gross, but apparently this is the typical way to
              // download a file returned from an API call
              const blob = await response.blob();
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", outputFilename);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }
          } catch (e) {
            if (isAPIError(e)) {
              console.error(e);
            }
          } finally {
            setDownloadIsLoading(false);
            setIsModalOpen(false);
          }
        }}
        onCancel={e => {
          e.stopPropagation();
          setIsModalOpen(false);
        }}
        confirmLoading={downloadIsLoading}
        mask={false}
        wrapProps={{
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
          },
        }}
      >
        <p>Select metadata fields to include in your export:</p>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="None"
          onChange={(item: string[]) => setSelectedFields(item)}
          options={options}
        />
      </Modal>
    </div>
  );
};
