import { useCustomer, useDataLoader } from "@/hooks";
import { IssueList } from "@/pages/MetricsView/components/IssueList";
import { getMeterWidgetData } from "@/reportApi";
import { MeterWidgetSettings } from "@/types/dashboardTypes";
import { ParsedExpression } from "@/types/expressionsDslTypes";
import { Spin } from "antd";
import React from "react";
import { calculateRotationAlongArc, drawArcSegment } from "../utils";
import "./MetricMeter.less";

interface MetricMeterWidgetProps {
  metricsViewId: string;
  startDate: string;
  endDate: string;
  widgetSettings: MeterWidgetSettings;
  filters?: ParsedExpression;
  numDays?: number;
}

const MetricMeterWidget: React.FC<MetricMeterWidgetProps> = ({
  metricsViewId,
  startDate,
  endDate,
  widgetSettings,
  filters,
  numDays,
}) => {
  const { customer } = useCustomer();
  const loadable = useDataLoader(
    async abortController => {
      const widgetData = await getMeterWidgetData(
        customer.id,
        metricsViewId,
        widgetSettings.id,
        startDate,
        endDate,
        filters,
        { sentiment: ["negative"] }, // we only ever want negative sentiment issues here
        abortController.signal
      );
      return widgetData;
    },
    [metricsViewId, widgetSettings, startDate, endDate, filters]
  );

  const meterMinimumValue = widgetSettings.minimumValue;
  const meterMaximumValue = widgetSettings.maximumValue;
  // convert to percentage for display
  const value =
    parseFloat((loadable.data?.overallData.currentValue ?? 0).toFixed(2)) *
    (widgetSettings.valueMapping ? 100.0 : 1.0);
  const change = parseFloat(
    (
      value -
      (loadable.data?.overallData.trailingValue ?? 0) * (widgetSettings.valueMapping ? 100.0 : 1.0)
    ).toFixed(2)
  );

  /* ***** Constants for the meter widget ***** */
  const METER_RADIUS = 90;
  const VIEWPORT_MAX_X = 250;
  const VIEWPORT_MAX_Y = 200;
  const ARC_LENGTH_IN_DEGREES = 225;
  const ARC_START_OFFSET_IN_DEGREES = -202.5;
  const ARC_SEGMENT_LENGTH_OFFSET = 0.05; // radians
  const METER_TICK_STEP_SIZE = widgetSettings.maximumValue / 10;

  // Calculate needle position
  const needleRotation = calculateRotationAlongArc(
    value,
    meterMinimumValue,
    meterMaximumValue,
    ARC_LENGTH_IN_DEGREES,
    ARC_START_OFFSET_IN_DEGREES
  );
  const needleAngleInRadians = (needleRotation * Math.PI) / 180;
  const needleStart = {
    x: VIEWPORT_MAX_X / 2 + (METER_RADIUS / 2) * Math.cos(needleAngleInRadians),
    y: VIEWPORT_MAX_Y / 2 + 25 + 45 * Math.sin(needleAngleInRadians),
  };
  const needleEnd = {
    x: VIEWPORT_MAX_X / 2 + METER_RADIUS * Math.cos(needleAngleInRadians),
    y: VIEWPORT_MAX_Y / 2 + 25 + METER_RADIUS * Math.sin(needleAngleInRadians),
  };

  return (
    <Spin spinning={loadable.loading}>
      <div className="meter-widget-container">
        <div className="meter">
          <svg viewBox={`0 0 ${VIEWPORT_MAX_X} ${VIEWPORT_MAX_Y}`} className="w-full">
            {/* Background circle */}
            <circle cx="50%" cy="125" r="70" fill="#FFFACD" className="opacity-50" />

            {/* Background circle segments */}
            {widgetSettings.buckets.map((bucket, index) => (
              <path
                key={index}
                d={drawArcSegment(
                  bucket.bucketMinimum,
                  bucket.bucketMaximum,
                  meterMinimumValue,
                  meterMaximumValue,
                  ARC_LENGTH_IN_DEGREES,
                  ARC_START_OFFSET_IN_DEGREES,
                  VIEWPORT_MAX_X,
                  VIEWPORT_MAX_Y,
                  METER_RADIUS,
                  ARC_SEGMENT_LENGTH_OFFSET
                )}
                fill="none"
                stroke={bucket.bucketColor}
                strokeWidth="10"
                strokeLinecap="round"
                className="transition-all duration-300"
              />
            ))}

            {/* Value display */}
            <text
              x="50%"
              y="120"
              textAnchor="middle"
              className="text-4xl font-bold"
              style={{ fontSize: "28px", transition: "all 0.5s" }}
            >
              {value}
            </text>

            {/* Change indicator */}
            <rect
              x="50%"
              y="130"
              width="auto"
              height="20"
              rx="4"
              ry="4"
              className={`change-text-background-${change >= 0 ? "positive" : "negative"}`}
              ref={rect => {
                if (rect) {
                  const textElement = rect.nextSibling as SVGTextElement;
                  const bbox = textElement.getBBox();
                  rect.setAttribute("x", `${VIEWPORT_MAX_X / 2 - bbox.width / 2 - 5}`);
                  rect.setAttribute("width", `${bbox.width + 10}`);
                }
              }}
            />
            <text x="50%" y="145" textAnchor="middle" className="change-text-positive">
              {change >= 0 ? "+" : "-"}
              {Math.abs(change)} points
            </text>

            {numDays && (
              <text x="50%" y="165" textAnchor="middle" className="time-period-text">
                over {numDays} day{numDays > 1 ? "s" : ""}{" "}
              </text>
            )}

            {/* Scale markers */}
            {Array.from(
              {
                length: Math.floor(
                  (meterMaximumValue - meterMinimumValue) / METER_TICK_STEP_SIZE + 1
                ),
              },
              (_, i) => i * METER_TICK_STEP_SIZE
            ).map(mark => {
              const angle = calculateRotationAlongArc(
                mark,
                meterMinimumValue,
                meterMaximumValue,
                ARC_LENGTH_IN_DEGREES,
                ARC_START_OFFSET_IN_DEGREES
              );
              const rad = (angle * Math.PI) / 180;
              const x = VIEWPORT_MAX_X / 2 + (METER_RADIUS + 20) * Math.cos(rad);
              const y = VIEWPORT_MAX_Y / 2 + 25 + (METER_RADIUS + 15) * Math.sin(rad);

              return (
                <text
                  key={mark}
                  x={x}
                  y={y}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  className="tick-text"
                >
                  {mark}
                </text>
              );
            })}

            {/* Needle that starts from outer edge */}
            <circle
              cx={needleStart.x}
              cy={needleStart.y}
              r="3"
              fill="black"
              style={{ transition: "all 0.5s" }}
            />
            <path
              d={`M ${needleStart.x} ${needleStart.y} L ${needleEnd.x} ${needleEnd.y}`}
              stroke="black"
              strokeWidth="3"
              strokeLinecap="round"
              style={{ transition: "all 0.5s" }}
            />
          </svg>
        </div>
        <IssueList
          issueList={loadable.data?.issueData || []}
          numIssuesToShow={3}
          isSortedByImpact
        />
      </div>
    </Spin>
  );
};

export default MetricMeterWidget;
